import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getProgramDesignationsUpdateFailure: ['getProgramDesignationsUpdateError'],
  getProgramDesignationsUpdateSuccess: ['getProgramDesignationsUpdateResponse'],
  addProgramDesignationsUpdateTemplateRow: ['row'],
  clearProgramDesignationsUpdateTemplateData: null,
  toggleLoadingProgramDesignations: ['isLoading'],
  updateProgramDesignationsUpdateTemplate: ['programDesignationsUpdateTemplateData'],
});

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  getProgramDesignationsUpdateError: {},
  getProgramDesignationsUpdateResponse: [],
  loadingProgramDesignations: false,
  programDesignationsUpdateTemplateData: [],
  programDesignationsUpdateTemplateRow: {
    transactionId: '',
    programDesignationName: '',
  },
};

/* ------------- Reducers ------------- */

const getProgramDesignationsUpdateFailure = (state, { getProgramDesignationsUpdateError }) => ({
  ...state,
  getProgramDesignationsUpdateError,
  getProgramDesignationsUpdateResponse: [],
  loadingProgramDesignations: false,
});

const getProgramDesignationsUpdateSuccess = (state, { getProgramDesignationsUpdateResponse }) => ({
  ...state,
  getProgramDesignationsUpdateResponse,
  getProgramDesignationsError: {},
  loadingProgramDesignations: false,
});

const addProgramDesignationsUpdateTemplateRow = (state, { row }) => ({
  ...state,
  programDesignationsUpdateTemplateData: [...state.programDesignationsUpdateTemplateData, row],
});

const clearProgramDesignationsUpdateTemplateData = (state) => ({
  ...state,
  programDesignationsUpdateTemplateData: [],
  loadingProgramDesignations: false,
});

const updateProgramDesignationsUpdateTemplate = (state, { programDesignationsUpdateTemplateData }) => ({
  ...state,
  programDesignationsUpdateTemplateData,
});

const toggleLoadingProgramDesignations = (state, { isLoading = true }) => ({
  ...state,
  loadingProgramDesignations: isLoading,
});

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROGRAM_DESIGNATIONS_UPDATE_FAILURE]: getProgramDesignationsUpdateFailure,
  [Types.GET_PROGRAM_DESIGNATIONS_UPDATE_SUCCESS]: getProgramDesignationsUpdateSuccess,
  [Types.CLEAR_PROGRAM_DESIGNATIONS_UPDATE_TEMPLATE_DATA]: clearProgramDesignationsUpdateTemplateData,
  [Types.ADD_PROGRAM_DESIGNATIONS_UPDATE_TEMPLATE_ROW]: addProgramDesignationsUpdateTemplateRow,
  [Types.UPDATE_PROGRAM_DESIGNATIONS_UPDATE_TEMPLATE]: updateProgramDesignationsUpdateTemplate,
  [Types.TOGGLE_LOADING_PROGRAM_DESIGNATIONS]: toggleLoadingProgramDesignations,
});
