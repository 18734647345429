import httpStatusCodes from 'http-status-codes';
import localization from './localization';

import joi, { ValidationError } from 'joi';
import constants from './constants';

export type Invalid<E> = {
  error: E;
  validObj?: never;
};

export type Valid<T> = {
  error?: never;
  validObj: T;
};

export type ValidationResult<T, E> = NonNullable<Invalid<E> | Valid<T>>;

export const isInvalid = <T, E>(x: ValidationResult<T, E>): x is Invalid<E> => x.error !== undefined;

export const isValid = <T, E>(x: ValidationResult<T, E>): x is Valid<T> => x.validObj !== undefined;

export const joiValidate: <T>(obj: any, schema: joi.AnySchema) => ValidationResult<T, joi.ValidationError> = <T>(
  obj: T,
  schema: joi.AnySchema,
): ValidationResult<T, ValidationError> => {
  const { error } = schema.validate(obj, { abortEarly: false });
  return error ? { error } : { validObj: obj as T };
};

const passwordRegex =
  /(?=(.*[A-Z]{1,}.*))(?=(.*[a-z]{1,}.*))(?=(.*[\d]{1,}.*))(?=(.*[~!@#$%^&()_+\-={}\[\];\\',.]{1,}.*))/; // eslint-disable-line

// TODO: can't seem to import these---does the module need to be commonjs?
// export type LegacyValidationResponse =
//   constants.VALIDATION_STATES.ERROR |
//   constants.VALIDATION_STATES.SUCCESS |
//   constants.VALIDATION_STATES.WARNING;

const isBadRequest = (response: any) => {
  let isBad = false;
  if (
    response &&
    response.status === httpStatusCodes.BAD_REQUEST &&
    response.data &&
    response.data.status === 'fail' &&
    response.data.data
  ) {
    isBad = true;
  }
  return isBad;
};

const isUnauthorizedRequest = (response: any) => {
  let isUnauthorized = false;
  if (
    response &&
    response.status === httpStatusCodes.UNAUTHORIZED &&
    response.data &&
    response.data.status === 'fail' &&
    response.data.data
  ) {
    isUnauthorized = true;
  }
  return isUnauthorized;
};

/**
 * Validates a country
 * @param {string} country The country to validate
 * @returns {boolean}
 */
const isValidCountry = (country: string) => {
  const schema = joi.string().max(2).required();
  return isValid(joiValidate(country, schema));
};

/**
 * Validates an email address
 * @param {string} email The email to validate
 * @returns {boolean}
 */
const isValidEmail = (email: any) => {
  const schema = joi
    .string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required();
  return isValid(joiValidate(email, schema));
};

/**
 * Validates an id and make sure it fits in with the schema
 * @param {number} id The id we need to validate
 * @returns {boolean}
 */
const isValidId = (id: any) => {
  const schema = joi.number().integer().min(1).required();
  return isValid(joiValidate(id, schema));
};

/**
 * Validates that a string is a valid name
 * @param {string} name The name to validate
 * @returns {boolean}
 */
const isValidName = (name: any) => {
  const schema = joi.string().required();
  return isValid(joiValidate(name, schema));
};

/**
 * Validates a password
 * @param {string} password The password to validate
 * @returns {boolean}
 */
const isValidPassword = (password: any) => {
  const schema = joi
    .string()
    .regex(passwordRegex, 'Password must be mixed case with at least 1 number, and at least 1 special character')
    .min(8)
    .max(50)
    .required();
  return isValid(joiValidate(password, schema));
};

/**
 * Validates a phone number
 * @param {string} phoneNumber The phone number to validate
 * @param {string} countryCode [country=US] The two-letter ISO-3166 country code used to determine the appropriate phone number length
 * @returns {boolean}
 */
const isValidPhoneNumber = (phoneNumber: any, countryCode: any) =>
  phoneNumber.length === localization.getPhoneNumberLengthByCountry(countryCode);

/*
 * determine if the response is a "standard" one.
 * note that it is possible to return a 200 code but with a "fail" status,
 * to support streaming responses that mail fail after they've already
 * sent a header.
 */
const isValidResponse = (response: any) => {
  let isValid = false;
  if (
    response &&
    response.status === httpStatusCodes.OK &&
    response.data &&
    (response.data.status === 'success' || response.data.status === 'error') &&
    response.data.data
  ) {
    isValid = true;
  }
  return isValid;
};

/**
 * Validates a routing number. Cannot be > 9 digits
 * @param {string} routingNumber The routing number to validate
 * @returns {boolean}
 */
const isValidRoutingNumber = (routingNumber: any): boolean => {
  const schema = joi
    .string()
    .regex(/^[0-9]+$/)
    .max(9)
    .required();
  return isValid(joiValidate(routingNumber, schema));
};

/**
 * Validates a routing number and returns and returns the appropriate state's message for the UI. Cannot be > 9 digits
 * @param {string} routingNumber The routing number to validate
 * @returns {string}  A nullable message stating success or error
 */
const getValidStateRoutingNumber = (routingNumber: any): string | null => {
  if (!routingNumber) return null;
  const isValid = isValidRoutingNumber(routingNumber);
  return isValid ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
};

/**
 * Validates the credentials and returns the appropriate state's message for the UI.  Must have a credentialTypeId > 0, and if either a clientId or clientSecret is provided, then they both must be
 * @param {object} credentials The credentials object we need to validate for credentialTypeId, clientId, and clientSecret
 * @returns {string} A nullable message stating success or error
 */
const getValidStateCredentials = (credentials: any) => {
  let state = null;

  if (credentials && (credentials.clientId || credentials.clientSecret)) {
    let isValid = true;

    if (
      credentials.credentialTypeId <= 0 ||
      (credentials.clientId && !credentials.clientSecret) ||
      (!credentials.clientId && credentials.clientSecret)
    ) {
      isValid = false;
    }
    state = isValid ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Validates the email and returns the appropriate state's message for the UI
 * @param {string} email The email we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStateEmail = (email: any): string | null => {
  let state = null;
  if (email) {
    state = isValidEmail(email) ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }
  return state;
};

/**
 * Validates the id and returns the appropriate state's message for the UI
 * @param {number} id The id we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStateId = (id: any) => {
  let state = null;

  if (id) {
    state = isValidId(id) ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Minimum donation value input validator.
 * Empty values are valid but should not give any feedback.
 * Numbers higher or equal than zero are valid and successful, returns the error state otherwise.
 * @param {string | null} value donation value.
 * @returns {string | null} input validation state.
 */
const getIsValidMinDonationAllowed = (value: any) => {
  if (value === undefined || value === null || value === '') return null;

  const parsed = Number(value);

  return !isNaN(parsed) && parsed > 0 ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
};

/**
 * Maximum donation value input validator.
 * Empty values are valid but should not give any feedback.
 * Numbers higher or equal than zero or the minimum value are valid and successful, returns the error state otherwise.
 * @param {string | null} min donation value.
 * @param {string | null} max donation value.
 * @returns {string | null} input validation state.
 */
const getIsValidMaxDonationAllowed = (min: string, max: string | null) => {
  if (max === undefined || max === null || max === '') return null;

  const parsedMin = parseFloat(min);
  const parsedMax = Number(max);

  const valid = !isNaN(parsedMax) && parsedMax > 0;

  return (isNaN(parsedMin) && valid) || (!isNaN(parsedMin) && valid && parsedMax >= parsedMin)
    ? constants.VALIDATION_STATES.SUCCESS
    : constants.VALIDATION_STATES.ERROR;
};

const getValidEmailDomain = (str: any) => {
  if (str) {
    return /^[a-zA-Z0-9](?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](.*[a-zA-Z0-9])?$/.test(
      str,
    )
      ? constants.VALIDATION_STATES.SUCCESS
      : constants.VALIDATION_STATES.ERROR;
  }
  return null;
};

/**
 * Validates each id in the array and returns the appropriate state's message for the UI
 * @param {array} ids The array of ids we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStateIdArray = (ids: Array<any>): string | null => {
  let state = null;

  if (ids && ids.length) {
    let isValid = true;

    for (let i = 0; i < ids.length && isValid; i += 1) {
      isValid = isValid && isValidId(ids[i]);
    }

    state = isValid ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Only organizations with the Company Name "The Salvation Army" can set the RLCMapping feature.
 * @param {number} selectedCompany ID of the company selected while editing/adding an organization.
 * @param {object[]} companies all the companies with their IDs and names.
 * @param {number[]} selectedFeatures IDs of the features added to the organization.
 * @param {object[]} features all the features with their IDs and names.
 * @returns {string | null} validation state.
 */
const getValidAccessibleFeatures = (
  selectedCompany: number,
  companies: Array<any>,
  selectedFeatures: Array<number>,
  features: Array<any>,
): string | null => {
  if (!selectedFeatures || selectedFeatures?.length === 0) return null;

  const hasRLCMapping = features
    .filter((feature) => selectedFeatures.some((selectedFeature) => selectedFeature === feature.value))
    .some((feature) => feature.label === constants.FEATURES.RLC_MAPPING);

  if (hasRLCMapping && process.env.REACT_APP_NODE_ENV === constants.ENVIRONMENTS.PRODUCTION) {
    if (!selectedCompany) return constants.VALIDATION_STATES.ERROR;

    const selectedCompanyName = companies.find((company) => company.value === selectedCompany)?.label;

    return new RegExp(constants.TSA_COMPANY_NAME).test(selectedCompanyName)
      ? constants.VALIDATION_STATES.SUCCESS
      : constants.VALIDATION_STATES.ERROR;
  } else {
    return getValidStateIdArray(selectedFeatures);
  }
};

/**
 * Validates the name and returns the appropriate state's message for the
 * react-bootstrap form validator
 * @param {string} name The name we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStateName = (name: string) => {
  let state = null;

  if (name) {
    state = isValidName(name) ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

const getValidStateObject = (obj: any) => {
  let state = null;

  if (obj) {
    state = !!obj ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Validates the password and returns the appropriate state's message for the UI
 * @param {string} password The password we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStatePassword = (password: any) => {
  let state = null;

  if (password) {
    state = isValidPassword(password) ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Validates the phone number and returns the appropriate state's message for the UI
 * @param {string} phoneNumber The phone number we need to validate
 * @param {string} countryCode [country=US] The two-letter ISO-3166 country code used to determine the appropriate phone number length
 * @returns {string} A nullable message stating success or error
 */
const getValidStatePhoneNumber = (phoneNumber: any, countryCode: any) => {
  let state = null;

  if (phoneNumber) {
    state =
      !Number.isNaN(phoneNumber) && isValidPhoneNumber(phoneNumber, countryCode)
        ? constants.VALIDATION_STATES.SUCCESS
        : constants.VALIDATION_STATES.ERROR;
  }

  return state;
};

/**
 * Validates the zip code and returns the appropriate state's message for the UI
 * @param {string} zipCode The zip code we need to validate
 * @returns {string} A nullable message stating success or error
 */
const getValidStateZip = (zipCode: any) => {
  let state = null;
  if (zipCode) {
    state = zipCode.length ? constants.VALIDATION_STATES.SUCCESS : constants.VALIDATION_STATES.ERROR;
  }
  return state;
};

/**
 * Validates .csv file and return boolean works on windows and mac
 * @param {string} type The type of file uploaded
 * @returns {boolean} true if file uploaded is .csv
 */
const isValidCSVFile = (type: string) => ['text/csv', 'application/vnd.ms-excel'].includes(type);

const submissionTypeRequiresTokenFile = (type: string) => {
  const submissionTypesWithoutTokenFile = [
    constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.STRIPE_TO_STRIPE,
    constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.PAYPAL,
  ];

  return !submissionTypesWithoutTokenFile.includes(type);
};

const validateRecurringDonationMigrationJob = (job: any): joi.ValidationResult => {
  const validationSchema = joi.object().keys({
    id: joi.string().uuid().optional(),
    organizationId: joi.number().required().allow(null),
    label: joi.string().required(),
    submissionType: joi
      .string()
      .valid(...Object.values(constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES))
      .required(),
    isEditMode: joi.boolean().optional(),
    createdAt: joi.optional(),
    donorFile: joi.object().required(),
    tokenFile: submissionTypeRequiresTokenFile(job.submissionType)
      ? joi.object().required()
      : joi.object().allow(null).optional(),
    isDryRun: joi.boolean().required(),
  });

  return validationSchema.validate(job, { abortEarly: false });
};

const validateRecurringGiftJob = (job: any) => {
  const orgOrFileSchema =
    job?.submissionType === constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.ORG
      ? { suppressEmail: joi.boolean().required() }
      : { jobLabel: joi.string().required() };

  const validationSchema = joi.object().keys({
    submissionType: joi
      .string()
      .valid(...Object.values(constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES))
      .required(),
    organizationId: joi.number().min(1).required(),
    jobLabel: joi.string().required(),
    ...orgOrFileSchema,
  });

  return validationSchema.validate(job, { abortEarly: false, stripUnknown: true });
};

const getJoiErrorArrayForField = (joiError: joi.ValidationError | undefined | null, key: string) => {
  const details = joiError?.details || [];
  return Array.isArray(details) ? details.filter((x) => x?.context?.key === key).map((x) => x.message) : [];
};

const getValidationStateFromJoiError = (joiError: joi.ValidationError | undefined, key: string) =>
  getJoiErrorArrayForField(joiError, key).length > 0
    ? constants.VALIDATION_STATES.ERROR
    : constants.VALIDATION_STATES.SUCCESS;

const getJoiErrorsAsArray = (joiError: joi.ValidationError | undefined | null) => {
  const details = joiError?.details || [];
  return Array.isArray(details) ? details.map((x) => x.message) : [];
};

export type SimpleValidationResult = Record<string, string>;

/**
 * convert the joi validation to an object where the keys have one
 * error (the other errors are overwritten).
 * @param validationResult
 */
export const convertJoiToSimpleValidationResult = (validationResult: joi.ValidationError): SimpleValidationResult => {
  const errors: Record<string, string> = {};
  for (const v of validationResult.details) {
    if (v.context?.key) {
      errors[v.context.key] = v.message;
    }
  }
  return errors;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  convertJoiToSimpleValidationResult,
  getIsValidMaxDonationAllowed,
  getIsValidMinDonationAllowed,
  getJoiErrorArrayForField,
  getJoiErrorsAsArray,
  getValidAccessibleFeatures,
  getValidationStateFromJoiError,
  getValidEmailDomain,
  getValidStateCredentials,
  getValidStateEmail,
  getValidStateId,
  getValidStateIdArray,
  getValidStateName,
  getValidStateObject,
  getValidStatePassword,
  getValidStatePhoneNumber,
  getValidStateRoutingNumber,
  getValidStateZip,
  isBadRequest,
  isUnauthorizedRequest,
  isValidCountry,
  isValidCSVFile,
  isValidEmail,
  isValidId,
  isValidName,
  isValidPassword,
  isValidResponse,
  isValidRoutingNumber,
  validateRecurringDonationMigrationJob,
  submissionTypeRequiresTokenFile,
  validateRecurringGiftJob,
};
