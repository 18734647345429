import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ClassyButton from '../../ClassyButton/ClassyButton';

import './styles.scss';

class HelpComponent extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  renderModal = () => {
    const { title, content, fields } = this.props;
    const { show } = this.state;

    return (
      <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>In order to upload {title}, please follow these steps:</p>
          <ol>
            <li>
              Download the example template by clicking the &apos;Download Template&apos; button. This will allow you to
              fill in the {content} data into a spreadsheet. Using this spreadsheet will guarantee that your team will
              be successfully created.
            </li>
            <li>
              Fill csv with all the data Note: Required fields for {content} are {fields}.
            </li>
            <li>
              Upload the spreadsheet as a <strong>CSV</strong> by clicking the &apos;Browse Files&apos; link or drag
              file in the box.
            </li>
            <li>Click the &apos;Upload File&apos; button to begin process of {content} creation.</li>
            <li>If any data validation error occurred then correct the csv and upload again.</li>
            <li>
              After successful upload an email is sent to you with the CSV having successfully created {content} records
              and other having failed records (if failed).
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <ClassyButton title={'Close'} onClick={this.handleClose} />
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return (
      <div className="help-container">
        <div onClick={this.handleShow} className=" help-container__link">
          <i className="fa fa-question-circle fa-2" label="help" />
          <span>help</span>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

HelpComponent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  fields: PropTypes.string,
};

export default HelpComponent;
