import { CellInfo } from 'react-table';

export type Column = {
  Header: string;
  accessor: string;
  width: number;
  Cell: (row: CellInfo) => JSX.Element;
};

export const columnDefaults = {
  width: 220,
  Cell: (row: CellInfo) => <span>{row.value ?? '-'}</span>,
};

export type Columns = Column[];
