import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import papaParse from 'papaparse';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { get, keys, pickBy } from 'lodash';
import httpStatusCodes from 'http-status-codes';
import constants from '../../Helpers/constants';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import FileInput from '../FileInput/FileInput';
import ClassyTable from '../ClassyTable/ClassyTable';
import Throbber from '../Throbber/Throbber';
import ProgramDesignationsUpdatesActions from '../../Redux/ProgramDesignationsUpdate.redux';
import LoginActions from '../../Redux/Login.redux';
import ClassyTableCell from '../ClassyTableCell/ClassyTableCell';
import './ProgramDesignationsUpdate.scss';
import HelpComponent from './HelpComponent/HelpComponent';
import validators from '../../Helpers/validators';
import api from '../../Services/Api';

const {
  FILE_IMPORT: { COLUMNS_MISMATCH },
  CELL_TYPES: { TEXTBOX },
  PROGRAM_DESIGNATIONS: { REQUIRED_FIELDS },
  FILE_FORMAT_ERROR,
} = constants;

const INITIAL_STATE = {
  errors: {},
  showAlert: false,
  alertMessage: '',
  isParsingTemplate: false,
  isOrgDataLoading: false,
  usePrompt: true,
};

class ProgramDesignationsUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    if (props.usePrompt === false) {
      this.state.usePrompt = false;
    }
    this.columns = [
      {
        Cell: (row) =>
          typeof row.index !== 'undefined' ? (
            <div className="non-editable-cell" onClick={() => this.deleteUpdateTemplateRow(row.index)}>
              <i className="fa fa-minus-circle" />
            </div>
          ) : null,
        width: 50,
        resizable: false,
      },
      {
        Header: 'Index',
        Cell: (row) => (
          <div className="non-editable-cell">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>
        ),
        width: 100,
        resizable: false,
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
        Cell: (row) => this.renderCell(row, TEXTBOX, true, null, null, true),
        width: 500,
      },
      {
        Header: 'Program Designation Name',
        accessor: 'programDesignationName',
        Cell: (row) => this.renderCell(row, TEXTBOX, false, null, 127, true),
        width: 500,
      },
    ];
  }

  componentDidUpdate(prevProps) {
    const {
      selectOrganization,
      selectedOrganization,
      updateProgramDesignationsUpdateTemplate,
      programDesignationsUpdateTemplateData,
    } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      if (prevProps.programDesignationsUpdateTemplateData.length && !this.confirmOrganizationChange()) {
        selectOrganization(prevProps.selectedOrganization);
        updateProgramDesignationsUpdateTemplate(prevProps.programDesignationsUpdateTemplateData);
      }
    }
    if (!programDesignationsUpdateTemplateData.length) {
      this.addProgramDesignationsUpdateTemplateRow();
    }
  }

  componentDidMount() {
    if (!this.props.programDesignationsUpdateTemplateData.length) {
      this.addProgramDesignationsUpdateTemplateRow();
    }
  }

  componentWillUnmount() {
    this.clearTable();
  }

  filterProgramDesignationsData = () => {
    const data = this.props.programDesignationsUpdateTemplateData;
    let filteredData = [];
    if (data) {
      filteredData = data.map((programDesignationUpdates) => ({
        transactionId: programDesignationUpdates.transactionId,
        programDesignationName: programDesignationUpdates.programDesignationName,
      }));
    }
    return filteredData;
  };

  confirmOrganizationChange = () =>
    window.confirm('Are you sure you want to change organizations? Your work will not be saved.');

  clearTable = () => {
    const { clearProgramDesignationsUpdateTemplateData } = this.props;
    this.setState({ errors: {} });
    clearProgramDesignationsUpdateTemplateData();
  };

  /**
   * Check the state of the table to determine if it's been updated and currently has data
   */
  hasData = () => {
    const { programDesignationsUpdateTemplateData } = this.props;

    let hasData = false;

    // We only need to check for modified values of each column if we have a single row, otherwise having multiple rows means the table has been touched
    if (programDesignationsUpdateTemplateData && programDesignationsUpdateTemplateData.length === 1) {
      const singleRecord = programDesignationsUpdateTemplateData[0];

      // For each column, check if the single record has a value specified
      const columnsWithData = Object.keys(singleRecord).filter((key) => singleRecord[key]);

      // If any columns have data, then the single record has data and therefore the table does
      hasData = columnsWithData.length > 0;
    } else if (programDesignationsUpdateTemplateData && programDesignationsUpdateTemplateData.length > 1) {
      hasData = true;
    }

    return hasData;
  };

  renderThrobber = (message) => (
    <React.Fragment>
      <Throbber loading={true} />
      {message ? <span> {message} </span> : null}
    </React.Fragment>
  );

  importProgramDesignationsUpdate = async () => {
    const {
      selectedOrganization: { id: organizationId },
      programDesignationsUpdateTemplateData: programDesignationsUpdate,
      toggleLoadingProgramDesignations,
    } = this.props;

    toggleLoadingProgramDesignations();

    try {
      const importDesignationsResponse = await api.importProgramDesignationsUpdates({
        programDesignationsUpdate,
        organizationId,
      });
      if (importDesignationsResponse.success) {
        const { data } = importDesignationsResponse;
        const { validationErrors } = data;

        if (validationErrors && validationErrors.length) {
          toggleLoadingProgramDesignations(false);
          const errorList = validationErrors.map((errorRow) => {
            const errorItems = errorRow.issues.map((errorItem) => errorItem);
            return `Row ${errorRow.index + 1}: Invalid ${errorItems}`;
          });
          this.setAlert(errorList);
        } else {
          if (typeof data === 'object') {
            this.setAlert(data.text);
            toggleLoadingProgramDesignations(false);
          }
          this.clearTable();
        }
      } else {
        this.setAlert(importDesignationsResponse.error);
      }
    } catch (error) {
      this.setAlert(error.errors[0]);
      toggleLoadingProgramDesignations(false);
      if (error.statusCode === httpStatusCodes.GATEWAY_TIMEOUT) {
        this.setAlert(
          "It looks like you may have uploaded a large file, please check your email for a report when we're done processing.",
        );
        this.clearTable();
      }
    }
  };

  onDownloadTemplate = () => {
    const { programDesignationsUpdateTemplateData, programDesignationsUpdateTemplateRow } = this.props;
    const csv = papaParse.unparse(
      programDesignationsUpdateTemplateData.length
        ? this.filterProgramDesignationsData()
        : [programDesignationsUpdateTemplateRow],
    );

    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'programDesignationsUpdate.csv';
    saveAs(csvBlob, csvName);
  };

  setErrorState = (row, isValid) => {
    const { errors } = this.state;
    const key = `${row.index}-${row.column.id}`;
    errors[key] = !isValid;
    this.setState({ errors });
  };

  // TODO: Fix this---someone copied-and-pasted it from ProgramDesignations.js
  // which has a different signature:
  // renderCell = (row, cellType, isRequired, isNumeric, isDisabled, charLimit, isNonEmpty) => {
  renderCell = (row, isRequired, isNumeric, isDisabled, charLimit, isNonEmpty) => {
    const { programDesignationsUpdateTemplateData } = this.props;
    const value = get(programDesignationsUpdateTemplateData, [[row.index], [row.column.id]]);
    return (
      <ClassyTableCell
        // todo: get rid of the double bang when the call is fixed.
        isRequired={!!isRequired}
        isNumeric={isNumeric}
        charLimit={charLimit}
        row={row}
        isNonEmpty={isNonEmpty}
        onChange={this.onChangeTableCell}
        isDisabled={isDisabled}
        value={value}
      />
    );
  };

  deleteUpdateTemplateRow = (rowIndex) => {
    const { programDesignationsUpdateTemplateData, updateProgramDesignationsUpdateTemplate } = this.props;
    const newData = [
      ...programDesignationsUpdateTemplateData.slice(0, rowIndex),
      ...programDesignationsUpdateTemplateData.slice(rowIndex + 1),
    ];
    const { errors } = this.state;

    let issues = {};

    Object.keys(errors).map((error) => {
      if (errors[error] === false) {
        return null;
      }
      issues = { ...issues, [error]: true };
      return issues;
    });

    if (Object.keys(issues).length && keys(pickBy(issues)).length > 0) {
      Object.keys(issues).map((error) => {
        if (error.startsWith(rowIndex)) {
          delete issues[error];
          this.setState({ errors: issues });
        }
        if (Number(error.charAt(0)) > rowIndex) {
          const err = error.replace(error.charAt(0), Number(error.charAt(0)) - 1);
          delete issues[error];
          this.setState({ errors: { ...issues, [err]: true } });
        }
        return null;
      });
    }

    updateProgramDesignationsUpdateTemplate(newData);
  };

  onChangeTableCell = (row, value, isValid) => {
    const { programDesignationsUpdateTemplateData } = this.props;
    const data = [...programDesignationsUpdateTemplateData];
    data[row.index][row.column.id] = value;
    this.setErrorState(row, isValid);
  };

  addProgramDesignationsUpdateTemplateRow = () => {
    const {
      addProgramDesignationsUpdateTemplateRow,
      programDesignationsUpdateTemplateRow,
      programDesignationsUpdateTemplateData,
    } = this.props;
    const row = Object.assign({}, programDesignationsUpdateTemplateRow);
    if (programDesignationsUpdateTemplateData.length > 3000) {
      this.setState({ errors: 'Can not upload more than 3000' });
    } else {
      addProgramDesignationsUpdateTemplateRow(row);
    }
  };

  onCheck = (row, checked) => {
    const { programDesignationsUpdateTemplateData, updateProgramDesignationsUpdateTemplate } = this.props;
    const data = [...programDesignationsUpdateTemplateData];
    data[row.index][row.column.id] = checked;
    updateProgramDesignationsUpdateTemplate(data);
  };

  validateFileImportUpdate(data) {
    let errors = [];

    if (data && Array.isArray(data) && data.length > 0) {
      if (data.length) {
        // Grab a sample record so we can make sure the columns are correct
        const firstRecord = data[0];

        // Construct an array of the complete list of column names
        const masterColumnList = Object.keys(this.props.programDesignationsUpdateTemplateRow);

        // Construct an array of the columns in the file
        const fileColumnList = Object.keys(firstRecord);

        if (fileColumnList.length !== masterColumnList.length) {
          return { errors: [COLUMNS_MISMATCH] };
        }

        fileColumnList.forEach((column) => {
          if (!masterColumnList.includes(column)) {
            errors = [...errors, `The column "${column}" is not recognized`];
          }
        });

        if (errors && errors.length) {
          return { errors };
        }

        let errorList = [];

        data.map((designation, index) => {
          const { programDesignationName, transactionId } = designation;

          const validations = {
            transactionId: transactionId.length === 0 || Number.isNaN(Number(transactionId)),
            programDesignationName: programDesignationName.length === 0 || programDesignationName.length > 127,
          };

          const issues = keys(pickBy(validations));

          if (issues.length > 0) {
            this.setState({
              errors: {
                ...this.state.errors,
                ...Object.assign({}, ...issues.map((issue) => ({ [`${index}-${issue}`]: true }))),
              },
            });

            errorList = [
              ...errorList,
              {
                index: index + 1,
                issues,
              },
            ];
          }

          return null;
        });
        if (get(errors, errors.length > 0)) {
          this.setState({ errors: {} });
        } else if (errorList.length > 0) {
          errors = { ...errors, errorList };
        }
      }
    }
    return errors;
  }

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  onUploadUpdateTemplate = (file) => {
    if (file) {
      const { updateProgramDesignationsUpdateTemplate } = this.props;
      this.setState({ isParsingTemplate: true });

      papaParse.parse(file, {
        dynamicTyping: false,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results && results.data) {
            const parsedTemplate = results.data;

            if (results.data.length > 3000) {
              this.setState({ isParsingTemplate: false });
              this.setAlert('Template should not contain more than 3000 records.');
              return;
            }

            const validationErrors = this.validateFileImportUpdate(parsedTemplate);

            if (get(validationErrors, 'errors.length') > 0) {
              let errorList = [FILE_FORMAT_ERROR];
              if (typeof validationErrors.errors[0] === 'object') {
                errorList = errorList.concat(
                  validationErrors.errors.map((errorRow) => {
                    const errorItems = errorRow.inValidFormat.map((errorItem) => ` ${errorItem}`);
                    return `Row ${errorRow.index}: Invalid ${errorItems}`;
                  }),
                );
              } else {
                errorList = errorList.concat(validationErrors.errors.map((error) => error));
              }
              this.setAlert(errorList);
            } else {
              if (get(validationErrors, 'errorList.length') > 0) {
                const errorList = validationErrors.errorList.map((errorRow) => {
                  const errorItems = errorRow.issues.map((errorItem) => ` ${errorItem}`);
                  return `Row ${errorRow.index}: Invalid ${errorItems}`;
                });
                this.setAlert(errorList);
              }

              const templateP = parsedTemplate.map((template) => {
                const templateData = {};
                Object.keys(template).map((temp) => {
                  if (['isUpdate', 'displayStatus'].includes(temp)) {
                    templateData[temp] =
                      (!!template[temp] &&
                        ['true', 'false'].includes(template[temp].toLowerCase()) &&
                        JSON.parse(template[temp].toLowerCase())) ||
                      false;
                  } else {
                    templateData[temp] = template[temp];
                  }
                  return templateData;
                });
                return templateData;
              });
              updateProgramDesignationsUpdateTemplate(templateP);
            }
          }
          this.setState({ isParsingTemplate: false });
        },
      });
    }
  };

  onOpenFileBrowserUpdate = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const { type } = file;
    if (!validators.isValidCSVFile(type)) {
      return this.setAlert('Invalid file format, please upload .csv file.');
    }
    this.onUploadUpdateTemplate(file);
    e.target.value = null;
    return null;
  };

  renderTemplate = () => {
    const { errors } = this.state;
    const { programDesignationsUpdateTemplateData } = this.props;
    const hasErrors = Object.keys(errors).filter((key) => errors[key]).length > 0;

    const incompleteRows = programDesignationsUpdateTemplateData.filter(
      (row) => REQUIRED_FIELDS.filter((key) => !row[key]).length,
    );

    let isComplete;

    incompleteRows.map((val, ind) => {
      isComplete =
        incompleteRows[ind].transactionId.length !== 0 && incompleteRows[ind].programDesignationName.length !== 0;
      return isComplete;
    });

    return (
      <div className="program-designations__table-container">
        {programDesignationsUpdateTemplateData && this.state.usePrompt ? (
          <Prompt
            when={!!programDesignationsUpdateTemplateData.length}
            message="Are you sure you want to leave? Your work will not be saved."
          />
        ) : null}

        {this.state.isParsingTemplate || this.props.loadingProgramDesignations ? this.renderThrobber() : null}

        <span>*Required fields are highlighted red when left blank</span>

        <ClassyTable
          className="program-designations__table"
          showWhenEmpty
          data={programDesignationsUpdateTemplateData || []}
          columns={this.columns || []}
          getTdProps={() => ({
            style: { padding: 5, display: 'block', marginBottom: 5 },
          })}
          FooterLeftComponent={
            <span className="padding-large" onClick={this.addProgramDesignationsUpdateTemplateRow}>
              <i className="fa fa-plus-circle" />
              <button className="padding-medium btn-link">Add Row</button>
            </span>
          }
          clearTable={this.clearTable}
        />

        <div className="flexRow">
          <ClassyButton
            className="program-designations__submit-button"
            disabled={!programDesignationsUpdateTemplateData.length || hasErrors || !isComplete}
            title="Submit"
            onClick={this.importProgramDesignationsUpdate}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">Program Designations Update</h2>
        <div className="program-designations__table-header-button-group">
          <div className="flexRow">
            <ClassyButton
              className="secondary-button program-designations__table-header-button"
              title={this.hasData() ? 'Save for Later' : 'Download Template'}
              onClick={this.onDownloadTemplate}
            />

            <FileInput
              className="program-designations__table-header-button"
              hideInput
              inputId="uploadedTemplate"
              buttonLabel="Upload Template"
              onOpenFileBrowser={this.onOpenFileBrowserUpdate}
              accept=".csv"
            />
          </div>
          <HelpComponent />
        </div>

        {!this.state.isOrgDataLoading ? this.renderTemplate() : this.renderThrobber()}
      </div>
    );
  }
}

ProgramDesignationsUpdate.propTypes = {
  loadingProgramDesignations: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  addProgramDesignationsUpdateTemplateRow: PropTypes.func.isRequired,
  programDesignationsUpdateTemplateRow: PropTypes.object.isRequired,
  clearProgramDesignationsUpdateTemplateData: PropTypes.func.isRequired,
  programDesignationsUpdateTemplateData: PropTypes.array.isRequired,
  updateProgramDesignationsUpdateTemplate: PropTypes.func.isRequired,
  selectOrganization: PropTypes.func.isRequired,
  toggleLoadingProgramDesignations: PropTypes.func.isRequired,
  usePrompt: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const {
    loadingProgramDesignations,
    programDesignations,
    programDesignationsUpdateTemplateData,
    programDesignationsUpdateTemplateRow,
  } = state.programDesignationsUpdate;

  return {
    loadingProgramDesignations,
    programDesignations,
    selectedOrganization,
    programDesignationsUpdateTemplateData,
    programDesignationsUpdateTemplateRow,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    addProgramDesignationsUpdateTemplateRow,
    clearProgramDesignationsUpdateTemplateData,
    updateProgramDesignationsUpdateTemplate,
    toggleLoadingProgramDesignations,
  } = ProgramDesignationsUpdatesActions;
  const { selectOrganization } = LoginActions;

  return {
    addProgramDesignationsUpdateTemplateRow: (row) => dispatch(addProgramDesignationsUpdateTemplateRow(row)),
    clearProgramDesignationsUpdateTemplateData: () => dispatch(clearProgramDesignationsUpdateTemplateData()),
    selectOrganization: (organization) => dispatch(selectOrganization(organization)),
    updateProgramDesignationsUpdateTemplate: (data) => dispatch(updateProgramDesignationsUpdateTemplate(data)),
    toggleLoadingProgramDesignations: (isLoading) => dispatch(toggleLoadingProgramDesignations(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDesignationsUpdate);
