import React, { useCallback, useMemo, useState, useRef } from 'react';
import { Checkbox, ControlLabel, FormControl, FormGroup, Form, HelpBlock } from 'react-bootstrap';
import Select from 'react-select';

import './EditorForm.scss';
import OrganizationIdInput from '../../OrganizationIdInput/OrganizationIdInput.jsx';
import ClassyButton from '../../ClassyButton/ClassyButton';
import validators from '../../../Helpers/validators';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';

const INITIAL_VALUES = {
  submissionType: constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.ORG,
  jobLabel: '',
  suppressEmail: true,
  file: null,
};

const EditorForm = ({ showAlert, onCancel, onJobAdded }) => {
  const [formValues, setFormValues] = useState(INITIAL_VALUES);
  const [formErrors, setFormErrors] = useState({});

  const [organizationIdError] = useState(null);
  const [organizationIdLoading, setOrganizationIdLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const firstDisableCheckRef = useRef(false);

  const handleSubmissionTypeChange = useCallback((type) => {
    setFormValues((values) => ({ ...values, submissionType: type.value }));
  }, []);

  const handleSetOrganizationId = useCallback((value) => {
    setFormValues((current) => ({ ...current, organizationId: value }));
    const result = validators.validateRecurringGiftJob({
      organizationId: value,
    });
    setFormErrors((errors) => ({
      ...errors,
      organizationId: validators.getValidationStateFromJoiError(result.error, 'organizationId'),
    }));
  }, []);
  const handleInputChange = useCallback((event) => {
    const info = event?.target?.value || '';
    const name = event?.target?.name || '';

    if (name) {
      setFormValues((current) => ({ ...current, [name]: info }));
      const result = validators.validateRecurringGiftJob({ [name]: info });
      setFormErrors((errors) => ({
        ...errors,
        [name]: validators.getValidationStateFromJoiError(result.error, name),
      }));
    }
  }, []);

  const handleCheckboxChange = useCallback((event) => {
    const info = event?.target?.checked;
    const name = event?.target?.name;

    if (name) {
      setFormValues((current) => ({ ...current, [name]: info }));

      const result = validators.validateRecurringGiftJob({ [name]: info });
      setFormErrors((errors) => ({
        ...errors,
        [name]: validators.getValidationStateFromJoiError(result.error, name),
      }));
    }
  }, []);

  const handleFileChange = useCallback((event) => {
    const info = event?.target?.files;
    const name = event?.target?.name;

    if (name && info?.length) {
      setFormValues((current) => ({ ...current, [name]: info[0] }));

      const result = validators.validateRecurringGiftJob({ [name]: info[0] });
      setFormErrors((errors) => ({
        ...errors,
        [name]: validators.getValidationStateFromJoiError(result.error, name),
      }));
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      const formData = new FormData();
      formData.append('submissionType', formValues.submissionType);
      formData.append('jobLabel', formValues.jobLabel);
      formData.append('suppressEmail', formValues.suppressEmail);
      formData.append('organizationId', formValues.organizationId);

      if (formValues?.file?.name) {
        formData.append('file', formValues.file, formValues.file.name);
      }

      setIsSaving(true);
      try {
        const createRecurringDonationMigrationJobResponse = await api.createRecurringGiftCancellationJob(formData);
        setIsSaving(false);

        if (createRecurringDonationMigrationJobResponse.success) {
          onJobAdded?.(createRecurringDonationMigrationJobResponse.data);
        } else {
          showAlert('Failed to create the job');
        }
      } catch (error) {
        showAlert(error?.errors?.[0] || 'Failed to create the job');
        setIsSaving(false);
      }
    },
    [showAlert, formValues, onJobAdded],
  );

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      onCancel?.();
    },
    [onCancel],
  );

  const submissionTypeOption = useMemo(
    () => constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPE_OPTIONS.find((it) => it.value === formValues.submissionType),
    [formValues.submissionType],
  );

  const isDisabled = useMemo(() => {
    if (!firstDisableCheckRef.current) {
      firstDisableCheckRef.current = true;
      return true;
    }

    if (organizationIdError || organizationIdLoading) {
      return true;
    }

    const result = validators.validateRecurringGiftJob(formValues);
    return !!result?.error;
  }, [formValues, organizationIdError, organizationIdLoading]);

  return (
    <div className="cancel-recurring-form-wrapper">
      <Form noValidate validated="false">
        <FormControl name="submissionType" componentClass="react-select">
          <ControlLabel>Submission Type</ControlLabel>
          <Select
            name="submissionType"
            options={constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPE_OPTIONS}
            value={submissionTypeOption}
            onChange={handleSubmissionTypeChange}
          />
          <FormControl.Feedback />
          <HelpBlock>Select the type of cancellation you want to make.</HelpBlock>
        </FormControl>
        <br />

        <OrganizationIdInput
          organizationIdError={organizationIdError}
          setOrganizationId={handleSetOrganizationId}
          setOrganizationIdLoading={setOrganizationIdLoading}
        />
        <FormGroup controlId="jobLabel" validationState={formErrors?.jobLabel}>
          <ControlLabel>Job Label</ControlLabel>
          <FormControl
            required
            name="jobLabel"
            onChange={handleInputChange}
            placeholder="Job Label"
            type="text"
            value={formValues.jobLabel}
          />
          <HelpBlock>A label is required to identify this job, e.g. "Organization X"</HelpBlock>
          <FormControl.Feedback />
        </FormGroup>

        {formValues.submissionType === constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.ORG && (
          <FormGroup controlId="suppressEmail">
            <Checkbox checked={formValues.suppressEmail} name="suppressEmail" onChange={handleCheckboxChange}>
              Suppress email
            </Checkbox>
            <HelpBlock>Indicating whether the Pay API should send an email to the member</HelpBlock>
          </FormGroup>
        )}

        {formValues.submissionType === constants.CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.FILE && (
          <FormGroup validationState={formErrors?.file}>
            <ControlLabel>Cancellations file (CSV)</ControlLabel>
            <input name="file" type="file" required accept=".csv" onChange={handleFileChange} />
            <FormControl.Feedback />
            <HelpBlock>
              A CSV file containing the recurring profiles to cancel&nbsp;
              <a href="/cancel-recurring-gifts/cancel-recurring-gifts-template.csv" className="template-file" download>
                Link to template Token File.
              </a>
            </HelpBlock>
          </FormGroup>
        )}

        <br />
        <div className="submit-button">
          <ClassyButton title="Submit" disabled={isDisabled || isSaving} onClick={handleSubmit} />
          <ClassyButton title="Cancel" disabled={isSaving} onClick={handleCancel} />
        </div>

        {!!isSaving && (
          <div className="message-box">
            We're gathering the necessary information to cancel the recurring gifts.
            <br />
            Please be patient, this could take a bit of time.
          </div>
        )}
      </Form>
    </div>
  );
};

export default EditorForm;
