import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ClassyButton from '../../ClassyButton/ClassyButton';
import './HelpComponent.scss';

class HelpComponent extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  renderModal() {
    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Program Designation Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>In order to upload your program designations, please follow these steps:</p>
          <ol>
            <li>
              Download the example template by clicking the &apos;Download Template&apos; button. This will allow you to
              fill in the designation data into a spreadsheet. Using this spreadsheet will guarantee that your
              designation will successfully process. If you need to get designation Id, click on &apos;Download
              Designations&apos; button. This will download all the organization&apos;s designations and it&apos;s
              details.
            </li>
            <li>
              Upload the spreadsheet as a <strong>CSV</strong> by clicking the &apos;Upload Template&apos; button. The
              application will validate that you have filled out the required fields.
              <strong> Note: any missing required cells are colored red.</strong>
            </li>
            <li>
              If you need to modify any of the records, you can do so inline. The table allows you to easily edit any
              cell.
            </li>
            <li>
              In order to add more rows, click on the &apos;Add row&apos; button. This will create a new row in the
              table where you can enter the data you need.
            </li>
            <li>
              Remove any rows you do not want by clicking on the <i className="fa fa-minus-circle" /> icon on the left
              of the row.
            </li>
            <li>
              If you need to save your current state, click on &apos;Download Template&apos;. This will download the
              template with all the data currently on the table.
            </li>
            <li>
              After you are satisfied with the data in the table, click the &apos;Submit&apos; button to begin
              processing the designations. If the file passes an initial check to verify that all required fields are
              filled out, you will get a success response and the current table will be cleared. If there are any errors
              in the initial check, the errors will be displayed and no program designations will be imported. If there
              are any errors after the initial check, they will be detailed in an email that is sent to you once all
              designations have been processed.
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <ClassyButton title={'Close'} onClick={this.handleClose} />
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div className="help-container">
        <button onClick={this.handleShow} className="help-container__link btn-link">
          <i className="fa fa-question-circle fa-2" />
          <span>help</span>
        </button>
        {this.renderModal()}
      </div>
    );
  }
}

export default HelpComponent;
