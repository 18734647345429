import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import ClassyTable from '../ClassyTable/ClassyTable';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import api from '../../Services/Api';
import validators from '../../Helpers/validators';
import constants from '../../Helpers/constants';

const HISTORICAL_DATA_COLUMNS = [
  {
    Header: 'Year',
    accessor: 'year',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];

const BADGE_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'achievement_name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Asset',
    accessor: 'asset',
  },
];

const FundraiserInformation = ({ history, info, onUpdate, onBadgeDeleted }) => {
  const [newEmail, setNewEmail] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    setNewEmail('');
  }, [info]);

  const newEmailState = useMemo(() => validators.getValidStateEmail(newEmail), [newEmail]);
  const isNewEmailValid = useMemo(() => newEmailState === constants.VALIDATION_STATES.SUCCESS, [newEmailState]);

  const handleNewEmailChange = useCallback((event) => setNewEmail(event?.target?.value || ''), []);

  const handleUpdateEmail = useCallback(
    async (event) => {
      event?.preventDefault();

      if (!isNewEmailValid) {
        return;
      }

      setIsSaving(true);

      try {
        const result = await api.updateHistoricalFundraiser({
          email: info?.data?.supporter?.email,
          newEmail,
        });

        setAlertMessage('Fundraiser email updated successfully!');
        onUpdate?.(result);
      } catch (error) {
        setAlertMessage(error?.errors?.[0] || "We couldn't update the fundraiser email at this time.");
      } finally {
        setIsSaving(false);
      }
    },
    [info, newEmail, isNewEmailValid, onUpdate],
  );

  const handleAlertClose = useCallback(() => {
    setTimeout(() => {
      setAlertMessage('');
    }, 200);
  }, []);

  const handleDeleteBadge = useCallback(
    async (badgeId, badgeName) => {
      try {
        const confirmDelete = () =>
          typeof window !== 'undefined' &&
          window.confirm(
            `Are you sure you would like to delete ${badgeName} from ${info?.data?.supporter?.email || ''}?`,
          );

        if (confirmDelete()) {
          await api.deleteFundraiserBadgeById(badgeId);

          setAlertMessage('Fundraiser badge deleted successfully!');
          onBadgeDeleted?.(badgeId);
        }
      } catch (error) {
        setAlertMessage(error?.errors?.[0] || "We couldn't delete the fundraiser badge at this time.");
      }
    },
    [onBadgeDeleted, info],
  );

  const badgeColumns = useMemo(
    () => [
      {
        width: 110,
        Cell: (row) => (
          <>
            <button
              className="btn-link"
              onClick={() => {
                const email = info?.data?.supporter?.email;
                const badgeId = row?.original?.id;

                history.push(`${constants.ROUTES.HISTORICAL_FUNDRAISING_BADGE_EDITOR}/${badgeId}/edit?email=${email}`);
              }}
            >
              Edit
            </button>
            {` | `}
            <button
              className="btn-link"
              onClick={() => handleDeleteBadge(row?.original?.id, row?.original?.achievement_name)}
            >
              Delete
            </button>
          </>
        ),
        filterable: false,
      },
      ...BADGE_COLUMNS,
    ],
    [handleDeleteBadge, history, info?.data?.supporter],
  );

  if (!info) {
    return null;
  }

  if (info.errors?.length) {
    return (
      <div>
        <h3>Fundraiser Information</h3>
        <p>{info?.errors?.[0] || ''}</p>
      </div>
    );
  }

  return (
    <div>
      <ClassyAlert show={!!alertMessage} alertMessage={alertMessage} onHide={handleAlertClose} />
      <h3>Fundraiser Information</h3>
      <p>Information for the fundraiser with email {info?.data?.supporter?.email || '-'}:</p>
      <br />
      {!!info?.data?.badges?.length && (
        <>
          <h4>Badges</h4>
          <p>Does not include the ones dynamically created for the current, 2022 campaign.</p>
          <ClassyTable
            data={info?.data?.badges || []}
            columns={badgeColumns}
            defaultPageSize={15}
            HeaderLeftComponent={
              <LinkContainer
                to={`${constants.ROUTES.HISTORICAL_FUNDRAISING_BADGE_EDITOR}/create?email=${info?.data?.supporter?.email}`}
              >
                <ClassyButton title="Add Badge" className="margin-vertical-large" />
              </LinkContainer>
            }
          />
        </>
      )}
      {!!info?.data?.historicalData?.length && (
        <>
          <h4>Historical data</h4>
          <ClassyTable data={info?.data?.historicalData || []} columns={HISTORICAL_DATA_COLUMNS} defaultPageSize={15} />
        </>
      )}
      <br />
      <h3>Update fundraiser email</h3>
      <Form onSubmit={handleUpdateEmail}>
        <FormGroup controlId="email" validationState={newEmailState}>
          <ControlLabel>Enter the new email to update this fundraiser</ControlLabel>
          <FormControl
            name="email"
            type="email"
            value={newEmail}
            placeholder="example@classy.org"
            onChange={handleNewEmailChange}
          />
        </FormGroup>
        <ClassyButton title="Update" disabled={!isNewEmailValid || isSaving} onClick={handleUpdateEmail} />
      </Form>
      <br />
    </div>
  );
};

export default FundraiserInformation;
