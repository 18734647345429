import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';
import validators from '../../Helpers/validators';

const BadgeEditor = ({ match, history, location }) => {
  const [badge, setBadge] = useState({ name: '', description: '', asset: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const editBadgeId = useMemo(() => match?.params?.id, [match?.params]);

  useEffect(() => {
    setIsEditing(!!editBadgeId);

    const fetch = async () => {
      try {
        const info = await api.getFundraiserBadgeById(editBadgeId);
        setBadge({
          name: info?.data?.achievement_name || '',
          description: info?.data?.description || '',
          asset: info?.data?.asset || '',
        });
      } catch {}
    };

    fetch();
  }, [editBadgeId]);

  const handleInputChange = useCallback((event) => {
    const field = event?.target?.name || '';
    const value = event?.target?.value || '';
    if (!field) {
      return;
    }

    setBadge((info) => ({
      ...info,
      [field]: value,
    }));
  }, []);

  const email = useMemo(() => {
    const fromIndex = location?.search?.indexOf('=') + 1;
    return location?.search?.substring(fromIndex) || '';
  }, [location]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const payload = {
        ...badge,
        email,
      };

      let response;
      try {
        let result;
        if (isEditing) {
          result = await api.editFundraiserBadge(editBadgeId, payload);
        } else {
          result = await api.creteFundraiserBadge(payload);
        }

        if (result?.data) {
          response = isEditing ? 'Badge was successfully updated.' : 'Badge was successfully created.';
        } else {
          response = constants.MESSAGES.ERROR_DEFAULT;
        }
      } catch (exception) {
        response = exception.errors;
      }

      setAlertMessage(response);
      setShowAlert(true);
    },
    [isEditing, editBadgeId, badge, email],
  );

  const handleCloseAlert = useCallback(() => {
    setShowAlert(false);
    history.push(`${constants.ROUTES.HISTORICAL_FUNDRAISING}?email=${email}`);
  }, [history, email]);

  const handleCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(`${constants.ROUTES.HISTORICAL_FUNDRAISING}?email=${email}`);
    },
    [history, email],
  );

  const isFormValid = useMemo(
    () =>
      validators.getValidStateName(badge.name) === constants.VALIDATION_STATES.SUCCESS &&
      validators.getValidStateName(badge.asset) === constants.VALIDATION_STATES.SUCCESS,
    [badge],
  );

  return (
    <div>
      <ClassyAlert show={showAlert} alertMessage={alertMessage} onHide={handleCloseAlert} />
      <div className="flexRow contentSpaced">
        <h2 className="title-text">{isEditing ? 'Edit Badge' : 'Add Badge'}</h2>
      </div>
      <form>
        <FormGroup controlId="name" validationState={validators.getValidStateName(badge.name)}>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            name="name"
            type="text"
            value={badge.name}
            placeholder="Badge name"
            onChange={handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>

        <FormGroup controlId="description">
          <ControlLabel>Description</ControlLabel>
          <FormControl
            name="description"
            type="text"
            value={badge.description}
            placeholder="Badge description"
            onChange={handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>

        <FormGroup controlId="asset" validationState={validators.getValidStateName(badge.asset)}>
          <ControlLabel>Asset</ControlLabel>
          <FormControl
            name="asset"
            type="text"
            value={badge.asset}
            placeholder="Asset URL"
            onChange={handleInputChange}
          />
          <FormControl.Feedback />
        </FormGroup>

        <br />
        <div>
          <ClassyButton title="Cancel" onClick={handleCancel} />
          &nbsp;
          <ClassyButton title="Submit" disabled={!isFormValid} onClick={handleSubmit} />
        </div>
      </form>
    </div>
  );
};

export default BadgeEditor;
