import moment from 'moment';

// Enums
const CELL_TYPES = {
  CALENDAR: 'calendar',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  MULTI_SELECT: 'multiSelect',
  SELECT: 'select',
  TEXTBOX: 'text',
  READONLY: 'readonly',
};

const DATES = {
  DATE_FORMAT: 'MM/DD/YYYY',
  FORM_DATE_FORMAT: 'MMM DD YYYY',
  TABLE_DATE_FORMAT: 'YYYY MMM DD h:mm:ss A',
  GET_FUNDRAISING_MINIMUMS_END_DATE: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  GET_FUNDRAISING_MINIMUMS_START_DATE: moment()
    .utc()
    .subtract(process.env.REACT_APP_FUNDRAISING_MINIMUMS_DASHBOARD_DAYS_BACK_FOR_START_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  GET_OFFLINE_DONATIONS_END_DATE: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  GET_OFFLINE_DONATIONS_START_DATE: moment()
    .utc()
    .subtract(process.env.REACT_APP_OFFLINE_DONATIONS_DAYS_BACK_FOR_START_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  GET_PROGRAM_DESIGNATIONS_END_DATE: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  GET_PROGRAM_DESIGNATIONS_START_DATE: moment()
    .utc()
    .subtract(process.env.REACT_APP_PROGRAM_DESIGNATIONS_DAYS_BACK_FOR_START_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  GET_RECURRING_DONATIONS_END_DATE: moment()
    .utc()
    .add(process.env.REACT_APP_RECURRING_DONATIONS_DAYS_FORWARD_FOR_END_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  GET_RECURRING_DONATIONS_START_DATE: moment()
    .utc()
    .subtract(process.env.REACT_APP_RECURRING_DONATIONS_DAYS_BACK_FOR_START_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  GET_PROGRAM_DESIGNATIONS_UPDATE_END_DATE: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  GET_PROGRAM_DESIGNATIONS_UPDATE_START_DATE: moment()
    .utc()
    .subtract(process.env.REACT_APP_PROGRAM_DESIGNATIONS_UPDATE_DAYS_BACK_FOR_START_DATE, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  OFFLINE_DONATIONS_DATE_FORMAT: 'YYYY-MM-DD',
  REPORT_EXPORT_CONTENT_DATE_FORMAT: 'MM/DD/YYYY hh:mm A',
  REPORT_EXPORT_FILENAME_DATE_FORMAT: 'YYYYMMDDHHmm',
  TRANSACTION_SEARCH_DATE_FORMAT: 'ddd MMM DD YYYY HH:mm:ss [UTC]ZZ',
  MONTHS: [
    { label: 'January', mmValue: '01', value: 1 },
    { label: 'February', mmValue: '02', value: 2 },
    { label: 'March', mmValue: '03', value: 3 },
    { label: 'April', mmValue: '04', value: 4 },
    { label: 'May', mmValue: '05', value: 5 },
    { label: 'June', mmValue: '06', value: 6 },
    { label: 'July', mmValue: '07', value: 7 },
    { label: 'August', mmValue: '08', value: 8 },
    { label: 'September', mmValue: '09', value: 9 },
    { label: 'October', mmValue: '10', value: 10 },
    { label: 'November', mmValue: '11', value: 11 },
    { label: 'December', mmValue: '12', value: 12 },
  ],
  CC_EXPIRATION_YEARS: () => {
    const currentYear = moment().year();
    const expirationYears = [];
    for (let i = 0; i < 10; i += 1) {
      const year = currentYear + i;
      expirationYears.push({ value: year, label: year });
    }
    return expirationYears;
  },
};

const DONATION_STATUSES = [
  { id: 1, friendlyName: 'Active' },
  { id: 3, friendlyName: 'Failed' },
  { id: 2, friendlyName: 'Retry' },
];

const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEV: 'dev',
};

const FEATURES = {
  ADMIN: 'admin',
  CANCEL_RECURRING_GIFTS_DASHBOARD: 'cancelRecurringGiftsDashboard',
  EDIT_DONOR: 'editClient',
  FUNDRAISING_TEAMS_PAGES: 'fundraisingTeamPage',
  HISTORICAL_FUNDRAISING: 'historicalFundraising',
  OFFLINE_DONATIONS_DASHBOARD: 'offlineDonationsDashboard',
  OFFLINE_DONATIONS: 'offlineDonations',
  PROGRAM_DESIGNATIONS_DASHBOARD: 'programDesignationsDashboard',
  PROGRAM_DESIGNATIONS_UPDATE_DASHBOARD: 'programDesignationsUpdateDashboard',
  PROGRAM_DESIGNATIONS_UPDATE: 'programDesignationsUpdate',
  PROGRAM_DESIGNATIONS: 'programDesignations',
  RECURRING_DONATION_PROFILE_EDIT: 'recurringDonationProfileEdit',
  RECURRING_DONATIONS_MIGRATION: 'recurringDonationMigration',
  REGISTRATION_IMPORT: 'registrationImport',
  RLC_MAPPING: 'RLCMapping',
  SCHEDULED_REPORTS: 'scheduledReports',
  VIRTUAL_TERMINAL_LOCATION: 'VTLocation',
  VIRTUAL_TERMINAL: 'virtualTerminal',
};

const MESSAGES = {
  ERROR_DEFAULT:
    'Unfortunately, we ran into a problem while processing this request - please contact the system administrator',
};

const PROGRAM_DESIGNATIONS = {
  REQUIRED_FIELDS: ['programDesignation', 'goal'],
  REQUIRED_FIELDS_PD: ['programDesignationId', 'programDesignation', 'goal'],
};
const PAYMENT_METHODS = {
  CC: 'CC',
  ACH: 'ACH',
};
const OFFLINE_DONATIONS = {
  ACCEPTED_CURRENCIES: [
    { value: 'AUD', label: 'AUD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'USD', label: 'USD' },
  ],
  DONATION_SOURCE: [
    { value: 'individual', label: 'Individual' },
    { value: 'company', label: 'Company' },
  ],
  PAYMENT_TYPES: [
    { value: 'cash', label: 'Cash' },
    { value: 'cc', label: 'Credit Card' },
    { value: 'pledge', label: 'Pledge' },
    { value: 'sponsor', label: 'Sponsor' },
    { value: 'check', label: 'Check' },
    { value: 'other', label: 'Other' },
  ],
  REQUIRED_FIELDS_COMPANY: ['amount', 'campaignId', 'currency', 'companyName'],
  REQUIRED_FIELDS_INDIVIDUAL: ['amount', 'campaignId', 'currency', 'firstName', 'lastName'],
  FUNDRAISER_TYPES: {
    PAGE: 'fundraiserPageId',
    TEAM: 'fundraiserTeamId',
  },
};

const ORGANIZATION_DROPDOWN_NAME_MAX_LENGTH = 26;

const ROUTES = {
  ADMIN_CREATE_ORGANIZATIONS: '/admin/organizations/create',
  ADMIN_CREATE_USERS: '/admin/users/create',
  ADMIN_DONORS: '/admin/donors',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_RECURRING_PROFILES: '/admin/recurring-profiles',
  ADMIN_SETTINGS: '/admin/settings',
  ADMIN_USERS: '/admin/users',
  CACHES: '/caches',
  CANCEL_RECURRING_GIFTS: '/cancel-recurring-gifts/dashboard',
  FUNDRAISING_PAGES: '/fundraising/pages',
  FUNDRAISING_STORIES: '/fundraising/stories',
  FUNDRAISING_TEAMS: '/fundraising/teams',
  HISTORICAL_FUNDRAISING: '/historical-fundraising',
  HISTORICAL_FUNDRAISING_BADGE_EDITOR: '/historical-fundraising/badges',
  MY_PROFILE: '/user/my-profile',
  NEW_PASSWORD: '/user/new-password',
  OFFLINE_DONATIONS: '/offline-donations',
  OFFLINE_DONATIONS_DASHBOARD: '/offline-donations/dashboard',
  PROGRAM_DESIGNATIONS: '/program-designations',
  PROGRAM_DESIGNATIONS_DASHBOARD: '/program-designations/dashboard',
  PROGRAM_DESIGNATIONS_UPDATE: '/program-designations-update',
  PROGRAM_DESIGNATIONS_UPDATE_DASHBOARD: '/program-designations-update/dashboard',
  QUEUES: '/queues',
  RLC_DESIGNATIONS: '/rlc/designations',
  RLC_DESIGNATIONS_CREATE: '/rlc/designations/create',
  RLC_ACCOUNT_HOME: '/rlc/account',
  RLC_CAMPAIGNS: '/campaigns',
  RLC_LOGS: '/rlc/logs',
  RECURRING_DONATIONS_MIGRATE: '/recurring/migrate',
  REGISTRATION_IMPORT_DASHBOARD: '/registration-import',
  REGISTRATION_IMPORT: '/registration-import/import',
  REGISTRATION_IMPORT_TEAMS: '/registration-import/teams-import',
  REGISTRATION_IMPORT_ATTENDEES: '/registration-import/attendee-import',
  RESET_PASSWORD: '/user/password-reset',
  SCHEDULED_REPORTS: '/scheduled-reports',
  SCHEDULED_REPORTS_CREATE: '/scheduled-reports/create',
  VIRTUAL_TERMINAL: '/virtual-terminal',
  VIRTUAL_TERMINAL_CAMPAIGN_LOCATION: '/virtual-terminal/campaign-location',
  VIRTUAL_TERMINAL_CAMPAIGN_LOCATION_CREATE: '/virtual-terminal/campaign-location/create',
  VIRTUAL_TERMINAL_LOCATION: '/virtual-terminal/location',
  VIRTUAL_TERMINAL_LOCATION_CREATE: '/virtual-terminal/location/create',
  VIRTUAL_TERMINAL_USER_LOCATION: '/virtual-terminal/user-location',
  VIRTUAL_TERMINAL_USER_LOCATION_CREATE: '/virtual-terminal/user-location/create',
};

const ROLES = {
  SYSTEM: 1,
  ADMIN: 2,
  USER: 3,
  OPERATOR: 4,
  getRoleIdByName: function getRoleIdByName(role: string) {
    let roleId;

    if (role && typeof role === 'string') {
      switch (role.toUpperCase()) {
        case 'SYSTEM':
          roleId = this.SYSTEM;
          break;
        case 'ADMIN':
          roleId = this.ADMIN;
          break;
        case 'USER':
          roleId = this.USER;
          break;
        default:
          break;
      }
    }

    return roleId;
  },
};

const LOGICAL_OPERATORS = {
  AND: 'and',
  OR: 'or',
};

const ACTIVE_STATUS = {
  value: 1,
  label: 'Active',
  toggleActionLabel: 'Deactivate',
};
const DEACTIVATED_STATUS = {
  value: 0,
  label: 'Deactivated',
  toggleActionLabel: 'Activate',
};
const PENDING_STATUS = { value: 2, label: 'pending' };

const USER_STATUS = {
  getUserStatusOptions: function getUserStatusOptions(isOneTimePassword: boolean) {
    const userStatusOptions = [
      { value: ACTIVE_STATUS.value, label: ACTIVE_STATUS.label },
      { value: DEACTIVATED_STATUS.value, label: DEACTIVATED_STATUS.label },
    ];
    if (isOneTimePassword) {
      userStatusOptions.push({
        value: PENDING_STATUS.value,
        label: PENDING_STATUS.label,
      });
    }
    return userStatusOptions;
  },

  getUserStatusLabel: function getUserStatusLabel(isOneTimePassword: boolean, isEnabled: boolean) {
    if (isOneTimePassword) {
      return PENDING_STATUS.label;
    }
    return isEnabled ? ACTIVE_STATUS.label : DEACTIVATED_STATUS.label;
  },
};

export const VALIDATION_STATES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

// Formatters
const getChunkedOrganizationName = (organizationName: string | null) => {
  let chunkedOrganizationName = '';
  if (organizationName) {
    const matchedOrganizationName = organizationName.replace(/[^a-zA-Z\d- ]/g, '').match(/\b[\w|\d]+/g);
    chunkedOrganizationName = matchedOrganizationName ? matchedOrganizationName.join('_') : '';
  }
  return chunkedOrganizationName;
};

const CLASSY_PAY_API_CREDENTIAL_ID = 1;

const CLASSY_DBS = {
  PORTAL: 'portal',
  API_V2: 'apiv2',
};

const FILE_IMPORT = {
  AMOUNT_ERROR: 'Please do not include a "$" in the amount',
  COLUMNS_MISMATCH: 'The number of columns in this file do not match the template',
};

const FILE_FORMAT_ERROR = 'Oops, it looks like this file may not be formatted properly.';

const ADMIN_ORG_PATH = '/admin/organizations';
const SYSTEM_ORGANIZATION_ID = 1;
const TSA_COMPANY_NAME = 'Salvation Army';

const ONE_FILE_ERROR = 'One file at a time.';
const INVALID_TYPE_ERROR = 'Only csv file format is allowed.';
const UNAUTHORIZED_ERROR = 'Unauthorized';
const SERVER_ERROR = 'XHR returned response code 0';
const FILE_PROCESSING_MSG =
  "It looks like you may have uploaded a large file, please check your email for a report when we're done processing.";

const REPORT_FIELDS = [
  {
    label: 'Saved Report URL',
    name: 'reportUrl',
    type: 'url',
  },
  {
    label: 'File Name Prefix',
    name: 'fileNamePrefix',
    type: 'text',
  },
  {
    label: 'Report Frequency',
    name: 'reportFrequency',
    type: 'text',
    options: [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
    ],
  },
  {
    label: 'Report Generation Start Date',
    name: 'reportStartDate',
    type: 'date',
  },
  {
    label: 'Time Range',
    name: 'timeRange',
    type: 'text',
    options: [],
  },
  {
    label: 'Delimited Format',
    name: 'delimitedFormat',
    type: 'text',
    options: [
      { value: 'PIPE', label: 'Pipe Delimited' },
      { value: 'COMMA', label: 'Comma Delimited' },
    ],
  },
];

const REPORT_FTP_FIELDS = [
  {
    label: 'FTP Location',
    name: 'ftpLocation',
    type: 'text',
  },
  {
    label: 'FTP User Name',
    name: 'ftpUserName',
    type: 'text',
  },
  {
    label: 'FTP Password',
    name: 'ftpPassword',
    type: 'password',
  },
];

const WEEKLY_OPTIONS = [
  {
    value: 'thisWeek',
    label: 'This Week',
  },
  {
    value: 'lastWeek',
    label: 'Last Week',
  },
];

const DAILY_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
];

const RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES = {
  STRIPE: 'stripe',
  STRIPE_TO_STRIPE: 'stripe_to_stripe',
  TOKENEX: 'tokenex',
  PAYPAL: 'paypal',
};

const RECURRING_DONATION_MIGRATION_OPTIONS = [
  {
    value: RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.STRIPE,
    label: 'Stripe',
  },
  {
    value: RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.STRIPE_TO_STRIPE,
    label: 'Stripe to Stripe',
  },
  {
    value: RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.TOKENEX,
    label: 'TokenEx',
  },
  {
    value: RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.PAYPAL,
    label: 'PayPal',
  },
];

const CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES = {
  ORG: 'org',
  FILE: 'file',
};

const CANCEL_RECURRING_GIFTS_SUBMISSION_TYPE_OPTIONS = [
  {
    value: CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.ORG,
    label: 'Cancel recurring donations on an entire organization',
  },
  {
    value: CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES.FILE,
    label: 'Upload a file to cancel recurring donations',
  },
];

const PAGINATION = {
  PAGE_DEFAULT: 0,
  SIZE_DEFAULT: 10,
  SIZE_OPTIONS: [10, 25, 50, 100],
};

const RLC_GROUP_DEFAULT = 1;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ACTIVE_STATUS,
  ADMIN_ORG_PATH,
  CANCEL_RECURRING_GIFTS_SUBMISSION_TYPE_OPTIONS,
  CANCEL_RECURRING_GIFTS_SUBMISSION_TYPES,
  CELL_TYPES,
  CLASSY_DBS,
  CLASSY_PAY_API_CREDENTIAL_ID,
  DAILY_OPTIONS,
  DATES,
  DEACTIVATED_STATUS,
  DONATION_STATUSES,
  ENVIRONMENTS,
  FEATURES,
  FILE_FORMAT_ERROR,
  FILE_IMPORT,
  FILE_PROCESSING_MSG,
  getChunkedOrganizationName,
  INVALID_TYPE_ERROR,
  LOGICAL_OPERATORS,
  MESSAGES,
  OFFLINE_DONATIONS,
  ONE_FILE_ERROR,
  ORGANIZATION_DROPDOWN_NAME_MAX_LENGTH,
  PAGINATION,
  PAYMENT_METHODS,
  PENDING_STATUS,
  PROGRAM_DESIGNATIONS,
  RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES,
  RECURRING_DONATION_MIGRATION_OPTIONS,
  REPORT_FIELDS,
  REPORT_FTP_FIELDS,
  RLC_GROUP_DEFAULT,
  ROLES,
  ROUTES,
  SERVER_ERROR,
  SYSTEM_ORGANIZATION_ID,
  TSA_COMPANY_NAME,
  UNAUTHORIZED_ERROR,
  USER_STATUS,
  VALIDATION_STATES,
  WEEKLY_OPTIONS,
};
