import { columnDefaults, Columns } from '../ClassyTable/Types';

export const attendeeColumns: Columns = [
  {
    Header: 'Attendee Id',
    accessor: 'attendee_id',
    ...columnDefaults,
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    ...columnDefaults,
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
    ...columnDefaults,
  },
  {
    Header: 'Campaign ID',
    accessor: 'campaign_id',
    ...columnDefaults,
  },
  {
    Header: 'Address 1',
    accessor: 'address1',
    ...columnDefaults,
  },
  {
    Header: 'Address 2',
    accessor: 'address2',
    ...columnDefaults,
  },
  {
    Header: 'City',
    accessor: 'city',
    ...columnDefaults,
  },
  {
    Header: 'Country',
    accessor: 'country',
    ...columnDefaults,
  },
  {
    Header: 'Blog',
    accessor: 'blog',
    ...columnDefaults,
  },

  {
    Header: 'Cell',
    accessor: 'cell',
    ...columnDefaults,
  },
  {
    Header: 'Updated At',
    accessor: 'updated_at',
    ...columnDefaults,
  },
  {
    Header: 'Ticket ID',
    accessor: 'ticket_id',
    ...columnDefaults,
  },
  {
    Header: 'Ticket type',
    accessor: 'ticket_type',
    ...columnDefaults,
  },
  {
    Header: 'Member ID',
    accessor: 'member_id',
    ...columnDefaults,
  },
  {
    Header: 'Fundraiser Page  ID',
    accessor: 'fundraising_page_id',
    ...columnDefaults,
  },
  {
    Header: 'Transaction  ID',
    accessor: 'transaction_id',
    ...columnDefaults,
  },
  {
    Header: 'Transaction Item ID',
    accessor: 'transaction_item_id',
    ...columnDefaults,
  },
  {
    Header: 'Transaction Last Four',
    accessor: 'transaction_last_four',
    ...columnDefaults,
  },
  {
    Header: 'Transaction Payment Type',
    accessor: 'transaction_payment_type',
    ...columnDefaults,
  },
  {
    Header: 'Product Name',
    accessor: 'product_name',
    ...columnDefaults,
  },
  {
    Header: 'Gender',
    accessor: 'gender',
    ...columnDefaults,
  },
  {
    Header: 'Company',
    accessor: 'company',
    ...columnDefaults,
  },
  {
    Header: 'Email',
    accessor: 'email',
    ...columnDefaults,
  },
  {
    Header: 'T-Shirt Size',
    accessor: 'tshirt_size',
    ...columnDefaults,
  },
  {
    Header: 'Status',
    accessor: 'status',
    ...columnDefaults,
  },
  {
    Header: 'Question',
    accessor: 'label',
    ...columnDefaults,
  },
  {
    Header: 'Answer 1',
    accessor: 'answer',
    ...columnDefaults,
  },
  {
    Header: 'Question 2',
    accessor: 'label2',
    ...columnDefaults,
  },
  {
    Header: 'Answer 2',
    accessor: 'answer2',
    ...columnDefaults,
  },
  {
    Header: 'Question 3',
    accessor: 'label3',
    ...columnDefaults,
  },
  {
    Header: 'Answer 3',
    accessor: 'answer3',
    ...columnDefaults,
  },
  {
    Header: 'Question 4',
    accessor: 'label4',
    ...columnDefaults,
  },
  {
    Header: 'Answer 4',
    accessor: 'answer4',
    ...columnDefaults,
  },
  {
    Header: 'Question 5',
    accessor: 'label5',
    ...columnDefaults,
  },
  {
    Header: 'Answer 5',
    accessor: 'answer5',
    ...columnDefaults,
  },
  {
    Header: 'Donation Share',
    accessor: 'donation_share',
    ...columnDefaults,
  },
];
