import React, { useCallback } from 'react';
import Badge from 'react-bootstrap/lib/Badge';
import type { RowInfo } from 'react-table';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';
import constants from '../../Helpers/constants';
import history from '../../Router/history';

const ValidCellComponent = ({ rowOriginal }: { rowOriginal: RowInfo['original'] }) => (
  <Badge data-testid="rdm-row-valid-cell" className="total-success" title={`${rowOriginal.validRowCount} valid rows`}>
    {rowOriginal.validRowCount}
  </Badge>
);

const InvalidCellComponent = ({ rowOriginal }: { rowOriginal: RowInfo['original'] }) => (
  <Badge
    data-testid="rdm-row-invalid-cell"
    className="total-error"
    title={`${rowOriginal.invalidRowCount} invalid rows`}
  >
    {rowOriginal.invalidRowCount}
  </Badge>
);

const SubmissionSuccessCellComponent = ({ rowOriginal }: { rowOriginal: RowInfo['original'] }) => (
  <Badge
    data-testid="rdm-row-submission-success-cell"
    className="total-success"
    title={`${rowOriginal.successfullySubmittedRowCount} successfully submitted rows`}
  >
    {rowOriginal.successfullySubmittedRowCount}
  </Badge>
);

const SubmissionUnsuccessfulCellComponent = ({ rowOriginal }: { rowOriginal: RowInfo['original'] }) => (
  <Badge
    data-testid="rdm-row-submission-error-cell"
    className="total-error"
    title={`${rowOriginal.unsuccessfullySubmittedRowCount} unsuccessfully submitted rows`}
  >
    {rowOriginal.unsuccessfullySubmittedRowCount}
  </Badge>
);

const columnAttendee = [
  {
    Header: 'Org ID',
    accessor: 'organizationId',
    width: 80,
    Cell: (row: RowInfo) => <span data-testid="rdm-row-organization-id">{row.rowValues ?? '-'}</span>,
  },
  {
    Header: 'Campaign Id',
    accessor: 'campaignId',
    width: 120,
    Cell: (row: RowInfo) => <span data-testid="rdm-row-organization-id">{row.rowValues ?? '-'}</span>,
  },
  {
    Header: 'Tickets/Registrations',
    accessor: 'registrations',
    width: 170,
    Cell: (row: RowInfo) => <span data-testid="rdm-row-organization-id">{row.rowValues ?? '-'}</span>,
  },
  {
    Header: 'Attendees',
    accessor: 'attendees',
    width: 100,
    Cell: (row: RowInfo) => <span data-testid="rdm-row-organization-id">{row.rowValues ?? '-'}</span>,
  },
  {
    Header: 'Valid',
    accessor: 'valid',
    width: 112,
    Cell: (row: RowInfo) => <ValidCellComponent rowOriginal={row.original} />,
    getProps: () => ({ className: 'center-column-data' }),
  },
  {
    Header: 'Invalid',
    accessor: 'invalid',
    width: 80,
    Cell: (row: RowInfo) => <InvalidCellComponent rowOriginal={row.original} />,
    getProps: () => ({ className: 'center-column-data' }),
  },
  {
    Header: 'Submitted',
    accessor: 'submitted',
    width: 112,
    Cell: (row: RowInfo) => <SubmissionSuccessCellComponent rowOriginal={row.original} />,
    getProps: () => ({ className: 'center-column-data' }),
  },
  {
    Header: 'Error',
    accessor: 'error',
    width: 80,
    Cell: (row: RowInfo) => <SubmissionUnsuccessfulCellComponent rowOriginal={row.original} />,
    getProps: () => ({ className: 'center-column-data' }),
  },
  {
    Header: 'Action',
    Cell: () => (
      <div style={{ display: 'flex' }}>
        <ClassyButton
          data-testid="rdm-row-action-cell"
          className="secondary-button"
          style={{ fontWeight: 'bold' }}
          title={'Download results'}
          onClick={() => {}}
        />
      </div>
    ),
  },
];

const RegistrationImportDashboard = () => {
  const data = new Array(10).fill(0).map((_, index) => ({
    organizationId: 1000 + index * 10,
    campaignId: 5678,
    registrations: 500,
    attendees: 1000,
    validRowCount: 800,
    invalidRowCount: 200,
    successfullySubmittedRowCount: 700,
    unsuccessfullySubmittedRowCount: 100,
  }));

  const handleNewAttendeeImport = useCallback(() => {
    history.push({
      pathname: '/registration-import/import',
    });
  }, []);

  return (
    <>
      <h2 className="title-text">Registration Bulk Import</h2>
      <ClassyTable
        showWhenEmpty
        columns={columnAttendee}
        data={data}
        defaultPageSize={constants.PAGINATION.SIZE_DEFAULT}
        exportFilename="RegistrationImport"
        pageSizeOptions={constants.PAGINATION.SIZE_OPTIONS}
        resizable={false}
      />
      <div className="flexRow">
        <ClassyButton title="New Import" onClick={handleNewAttendeeImport} />
      </div>
    </>
  );
};

export default RegistrationImportDashboard;
