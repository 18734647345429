import React, { useCallback, useEffect, useState } from 'react';

import api from '../../Services/Api';
import TableView from './TableView';
import EditorForm from './EditorForm/EditorForm';
import ClassyAlert from '../ClassyAlert/ClassyAlert';

import './CancelRecurringGiftsDashboard.scss';

const REFRESH_INTERVAL = 4000;

const CancelRecurringGiftsDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const fetchJobs = useCallback(async () => {
    try {
      const result = await api.getAllRecurringGiftCancellationJob();
      if (result?.success) {
        setJobs((info) => result?.data || info);
      }
    } catch {}
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    const refresh = setInterval(() => {
      fetchJobs();
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(refresh);
    };
  }, [isEditing, fetchJobs]);

  const handleShowEditor = useCallback(() => {
    setIsEditing(true);
  }, []);

  const hideAlert = useCallback(() => setAlertMessage(''), []);
  const handleCancel = useCallback(() => setIsEditing(false), []);
  const handleJobAdded = useCallback((job) => {
    setJobs((current) => [job, ...current]);
    setIsEditing(false);
    setAlertMessage('Job scheduled successfully!');
  }, []);

  return (
    <div className="cancel-recurring-gifts">
      <h2 className="title-text">Cancel Recurring Gifts</h2>
      <ClassyAlert show={!!alertMessage} alertMessage={alertMessage} onHide={hideAlert} />
      {!isEditing && <TableView jobs={jobs} onNewJob={handleShowEditor} />}
      {!!isEditing && <EditorForm showAlert={setAlertMessage} onCancel={handleCancel} onJobAdded={handleJobAdded} />}
    </div>
  );
};

export default CancelRecurringGiftsDashboard;
