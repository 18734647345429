import React from 'react';
import propTypes from 'prop-types';
import './Card.scss';

const Card = ({ messageTop, messageBottom }) => (
  <div className="card__container">
    <div className="card__message-top">
      <span className="card__message--text-success">{messageTop}</span>
    </div>
    {messageTop && messageBottom ? <div className="card__divider" /> : null}
    {messageBottom ? (
      <div>
        <div className="card__message-bottom">
          <span className="card__message--text-error">{messageBottom}</span>
        </div>
      </div>
    ) : null}
  </div>
);

Card.propTypes = {
  messageTop: propTypes.string.isRequired,
  messageBottom: propTypes.string,
};

export default Card;
