import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import papaParse from 'papaparse';
import { get } from 'lodash';
import { saveAs } from 'file-saver';

import AppActions from '../../../Redux/App.redux';
import history from '../../../Router/history';
import FileUploader from '../../FileUploader/FileUploader';
import ClassyTable from '../../ClassyTable/ClassyTable';
import Throbber from '../../Throbber/Throbber';
import Card from '../../Card/Card';
import constants from '../../../Helpers/constants';

import ClassyButton from '../../ClassyButton/ClassyButton';
import './style.scss';
import HelpComponent from '../HelpComponent';
import ErrorPopup from '../ErrorPopup';

export class Pages extends Component {
  componentWillUnmount() {
    const { clearPageResponse } = this.props;
    clearPageResponse();
  }

  filterPagesData = (data) => {
    let filteredData = [];
    if (data && Array.isArray(data)) {
      filteredData = data.map((page, index) => ({
        index: index + 1,
        firstName: page.firstName,
        lastName: page.lastName,
        email: page.emailAddress,
        campaignId: page.campaignId,
        teamId: page.teamId,
        title: page.title,
        rawCurrencyCode: page.rawCurrencyCode,
        rawGoal: page.rawGoal,
        fundraisingPageId: page.fundraisingPageId,
        status: page.isSuccessful ? 'Success' : (Array.isArray(page.result) && page.result.join(', ')) || page.result,
      }));
    }
    return filteredData;
  };

  renderThrobber = () => {
    const { loading } = this.props;

    return <Throbber loading={loading} />;
  };

  renderTransactionReport = () => {
    const { pageResponse, selectedOrganization } = this.props;
    let report;

    if (pageResponse) {
      const { data } = pageResponse;
      const pageReportColumns = [
        {
          Header: 'Row #',
          Cell: (row) => <div id="rowIndex">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
          width: 75,
        },
        {
          Header: 'Email',
          accessor: 'emailAddress',
        },
        {
          Header: 'Campaign Id',
          accessor: 'campaignId',
        },
        {
          Header: 'Team Id',
          accessor: 'teamId',
        },
        {
          Header: 'Title',
          accessor: 'title',
        },
        {
          Header: 'Raw Currency Code',
          accessor: 'rawCurrencyCode',
        },
        {
          Header: 'Raw Goal',
          accessor: 'rawGoal',
        },
        {
          Header: 'Fundraising Page Id',
          accessor: 'fundraisingPageId',
        },
        {
          Header: 'Status',
          accessor: 'isSuccessful',
          Cell: (row) => {
            return row.value ? (
              <i className="fa fa-check status-icon--success" />
            ) : get(row, 'original.isValid') && !get(row, 'row._original.result') ? (
              <i className="fa fa-exclamation-circle status-icon--success" title="Valid Record" />
            ) : (
              <div id="errorMessage">
                <ErrorPopup
                  error={
                    Array.isArray(get(row, 'row._original.result'))
                      ? get(row, 'row._original.result').join(', ')
                      : get(row, 'row._original.result')
                  }
                />
              </div>
            );
          },
        },
      ];

      if (data && Array.isArray(data)) {
        const failedUploads = data.filter((el) => !el.isSuccessful);
        const successfulUploads = data.filter((el) => el.isSuccessful);
        const validUploads = data.filter((el) => el.isValid);
        const inValidUploads = data.filter((el) => !el.isValid);

        const exportOrganizationName = constants.getChunkedOrganizationName(selectedOrganization.name);

        report = (
          <div>
            {data ? (
              <div>
                {inValidUploads.length ? (
                  <Card
                    messageTop={validUploads.length ? `${validUploads.length} record(s) valid` : null}
                    messageBottom={inValidUploads.length ? `${inValidUploads.length} record(s) invalid` : null}
                  />
                ) : (
                  <Card
                    messageTop={successfulUploads.length ? `${successfulUploads.length} record(s) processed` : null}
                    messageBottom={failedUploads.length ? `${failedUploads.length} record(s) failed` : null}
                  />
                )}
                <Prompt
                  when={!!data.length}
                  message="Are you sure you want to leave? Your results will not be retained."
                />
              </div>
            ) : null}
            {this.renderThrobber()}

            <ClassyTable
              data={data || []}
              exportFilename={`${exportOrganizationName}_Fundraising_Pages`}
              formattedExportData={this.filterPagesData(data)}
              columns={pageReportColumns}
            />
          </div>
        );
      }
    }
    return report;
  };

  onDownloadTemplate = () => {
    const csv = papaParse.unparse([
      {
        firstName: '',
        lastName: '',
        emailAddress: '',
        gender: '',
        dateOfBirth: '',
        campaignId: '',
        teamId: '',
        alias: '',
        designationId: '',
        endedAt: '',
        introText: '',
        memberEmailText: '',
        rawCurrencyCode: '',
        rawGoal: '',
        startedAt: '',
        thankyouEmailText: '',
        thankYouText: '',
        title: '',
        q1Id: '',
        answer1: '',
        q2Id: '',
        answer2: '',
        q3Id: '',
        answer3: '',
        q4Id: '',
        answer4: '',
        q5Id: '',
        answer5: '',
      },
    ]);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'FundraisingPage.csv';
    saveAs(csvBlob, csvName);
  };

  render() {
    const { selectedOrganization, onPageResponse } = this.props;

    return (
      <div id="fundraisingPages">
        <HelpComponent
          title="Fundraising Pages"
          content="fundraising pages"
          fields="firstName, lastName, emailAddress, campaignId or teamId , startedAt, title"
        />

        <div className="flexRow space-between">
          <h2 className="title-text">Fundraising Pages</h2>
          <ClassyButton
            className="secondary-button fundraising-pages__table-header-button"
            title={'Download Template'}
            onClick={this.onDownloadTemplate}
          />
        </div>

        <FileUploader
          route={`fundraising/${selectedOrganization.id}/${process.env.REACT_APP_CLASSY_SERVICES_API_FUNDRAISING_PAGES}`}
          onParentComplete={onPageResponse}
        />

        <ClassyButton title="Back" onClick={() => history.push('/fundraising/teams')} />

        <ClassyButton className="float-right" title="Next" onClick={() => history.push('/fundraising/stories')} />

        {this.renderTransactionReport()}
      </div>
    );
  }
}

Pages.propTypes = {
  clearPageResponse: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pageResponse: PropTypes.object.isRequired,
  onPageResponse: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loading, pageResponse } = state.app;
  const { selectedOrganization } = state.login;

  return {
    loading,
    pageResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { clearPageResponse, onPageResponse } = AppActions;

  return {
    clearPageResponse: () => dispatch(clearPageResponse()),
    onPageResponse: (response) => dispatch(onPageResponse(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
