import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';
import api from '../../../Services/Api';
import programDesignationsUpdateActions from '../../../Redux/ProgramDesignationsUpdate.redux';
import constants from '../../../Helpers/constants';

class ProgramDesignationsUpdateDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
    };
  }

  componentDidMount() {
    this.getProgramDesignationsUpdate();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization.id !== selectedOrganization.id) {
      this.getProgramDesignationsUpdate();
    }
  }

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  filterProgramDesignationsUpdateData = () => {
    const data = this.props.getProgramDesignationsUpdateResponse;
    let filteredData = [];
    if (data) {
      filteredData = data.map((programDesignationUpdate, index) => ({
        index: index + 1,
        organizationId: programDesignationUpdate.organizationId,
        organizationName: programDesignationUpdate.organizationName,
        transactionId: programDesignationUpdate.transactionId,
        programDesignationName: programDesignationUpdate.programDesignationName,
        createdBy: programDesignationUpdate.createdBy,
        createdAt: moment.utc(programDesignationUpdate.createdAt).local().format('YYYY-MM-DD HH:mm') || null,
      }));
    }
    return filteredData;
  };

  getProgramDesignationsUpdate = async () => {
    const organizationId = this.props.selectedOrganization.id;
    const startDate = constants.DATES.GET_PROGRAM_DESIGNATIONS_UPDATE_START_DATE;
    const endDate = constants.DATES.GET_PROGRAM_DESIGNATIONS_UPDATE_END_DATE;
    let response;
    try {
      const programDesignationsResponse = await api.getProgramDesignationsUpdate({
        organizationId,
        startDate,
        endDate,
      });
      /* istanbul ignore next */
      if (programDesignationsResponse.success) {
        const programDesignationsData = programDesignationsResponse.data;
        this.props.getProgramDesignationsUpdateSuccess(programDesignationsData);
      } else {
        this.props.getProgramDesignationsUpdateFailure(programDesignationsResponse);
      }
    } catch (exception) {
      this.props.getProgramDesignationsUpdateFailure(exception);
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  };

  renderProgramDesignationsTable = () => {
    const data = this.filterProgramDesignationsUpdateData();
    const columns = [
      {
        Header: 'Index',
        accessor: 'index',
        width: 90,
        sortable: false,
      },
      {
        Header: 'Org ID',
        accessor: 'organizationId',
        width: 90,
        sortable: false,
      },
      {
        Header: 'Org Name',
        accessor: 'organizationName',
        sortable: false,
      },
      {
        Header: 'Transaction ID',
        accessor: 'transactionId',
        sortable: true,
      },
      {
        Header: 'Program Designation Name',
        accessor: 'programDesignationName',
        sortable: true,
      },
      {
        Header: 'Load Date and Time',
        accessor: 'createdAt',
        sortable: true,
      },
      {
        Header: 'Load User',
        accessor: 'createdBy',
        sortable: true,
      },
    ];

    return (
      <div>
        <ClassyTable
          showWhenEmpty
          data={data || []}
          columns={columns || []}
          exportFilename={'ProgramDesignationsUpdate'}
          formattedExportData={this.filterProgramDesignationsUpdateData()}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {data && !data.length ? (
          <span>{`No program designations in last ${process.env.REACT_APP_PROGRAM_DESIGNATIONS_UPDATE_DAYS_BACK_FOR_START_DATE} days.`}</span>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Program Designations Update Dashboard</h2>
        {this.renderProgramDesignationsTable()}
      </div>
    );
  }
}

ProgramDesignationsUpdateDashboard.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  getProgramDesignationsUpdateResponse: PropTypes.array.isRequired,
  getProgramDesignationsUpdateSuccess: PropTypes.func.isRequired,
  getProgramDesignationsUpdateFailure: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization,
    user: { email: userEmail },
  } = state.login;
  const { getProgramDesignationsUpdateError, getProgramDesignationsUpdateResponse } = state.programDesignationsUpdate;

  return {
    getProgramDesignationsUpdateError,
    getProgramDesignationsUpdateResponse,
    selectedOrganization,
    userEmail,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  const { getProgramDesignationsUpdateFailure, getProgramDesignationsUpdateSuccess } = programDesignationsUpdateActions;
  return {
    getProgramDesignationsUpdateFailure: (getProgramDesignationsUpdateError) =>
      dispatch(getProgramDesignationsUpdateFailure(getProgramDesignationsUpdateError)),
    getProgramDesignationsUpdateSuccess: (getProgramDesignationsUpdateResponse) =>
      dispatch(getProgramDesignationsUpdateSuccess(getProgramDesignationsUpdateResponse)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDesignationsUpdateDashboard);
