import { columnDefaults, Columns } from '../ClassyTable/Types';

export const teamsColumns: Columns = [
  {
    Header: 'Team ID',
    accessor: 'id',
    ...columnDefaults,
    width: 80,
  },
  {
    Header: 'Team name',
    accessor: 'name',
    ...columnDefaults,
  },
  {
    Header: 'Updated At',
    accessor: 'updated_at',
    ...columnDefaults,
  },
  {
    Header: 'Description',
    accessor: 'description',
    ...columnDefaults,
  },
  {
    Header: 'City',
    accessor: 'city',
    ...columnDefaults,
  },
  {
    Header: 'State',
    accessor: 'state',
    ...columnDefaults,
  },
  {
    Header: 'Country',
    accessor: 'country',
    ...columnDefaults,
  },
  {
    Header: 'Postal Code',
    accessor: 'postal_code',
    ...columnDefaults,
  },
  {
    Header: 'Campaign ID',
    accessor: 'campaign_id',
    ...columnDefaults,
  },
  {
    Header: 'Parent ID',
    accessor: 'parent_id',
    ...columnDefaults,
  },
  {
    Header: 'Raw Goal',
    accessor: 'raw_goal',
    ...columnDefaults,
  },
  {
    Header: 'Raw Currency Code',
    accessor: 'raw_currency_code',
    ...columnDefaults,
  },
  {
    Header: 'Team Lead ID',
    accessor: 'team_lead_id',
    ...columnDefaults,
  },
  {
    Header: 'Team Lead Supporter ID',
    accessor: 'team_lead_supporter_id',
    ...columnDefaults,
  },
  {
    Header: 'Thank You Text',
    accessor: 'thank_you_text',
    ...columnDefaults,
  },
  {
    Header: 'Designation ID',
    accessor: 'designation_id',
    ...columnDefaults,
  },
];
