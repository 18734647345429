import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';

import ClassyButton from '../ClassyButton/ClassyButton';
import FundraiserInformation from './FundraiserInformation';
import api from '../../Services/Api';
import validators from '../../Helpers/validators';
import constants from '../../Helpers/constants';

const HistoricalFundraising = ({ history, location }) => {
  const [fundraiserEmail, setFundraiserEmail] = useState('');
  const [fundraiserInfo, setFundraiserInfo] = useState(null);

  const locationRef = useRef(location);

  const fundraiserEmailState = useMemo(() => validators.getValidStateEmail(fundraiserEmail), [fundraiserEmail]);
  const isFundraiserEmailValid = useMemo(
    () => fundraiserEmailState === constants.VALIDATION_STATES.SUCCESS,
    [fundraiserEmailState],
  );

  const fetchFundraiserByEmail = useCallback(async (email) => {
    try {
      const info = await api.searchHistoricalFundraiserByEmail(encodeURIComponent(email));

      setFundraiserInfo(info);
    } catch (error) {
      setFundraiserInfo(error);
    }
  }, []);

  const handleSearchFundraiser = useCallback(
    async (event) => {
      event?.preventDefault();

      if (!isFundraiserEmailValid) {
        return;
      }

      await fetchFundraiserByEmail(fundraiserEmail);
    },
    [fetchFundraiserByEmail, fundraiserEmail, isFundraiserEmailValid],
  );

  useEffect(() => {
    if (!locationRef?.current?.search) {
      return;
    }

    const fromIndex = locationRef?.current?.search?.indexOf('=') + 1;
    const email = locationRef?.current?.search?.substring(fromIndex) || '';

    setFundraiserEmail(email);
    fetchFundraiserByEmail(email);
  }, [fetchFundraiserByEmail]);

  const handleFundraiserEmailChange = useCallback((event) => setFundraiserEmail(event?.target?.value || ''), []);

  const handleFundraiserUpdate = useCallback((newInfo) => {
    setFundraiserInfo(newInfo);
    setFundraiserEmail(newInfo?.data?.supporter?.email || '');
  }, []);

  const handleBadgeDeleted = useCallback((badgeId) => {
    setFundraiserInfo((info) => ({
      ...info,
      data: {
        ...(info?.data || {}),
        badges: (info?.data?.badges || []).filter((item) => item.id !== badgeId),
      },
    }));
  }, []);

  return (
    <div>
      <h2 className="title-text">Historical Fundraising</h2>
      <Form onSubmit={handleSearchFundraiser}>
        <FormGroup controlId="email" validationState={fundraiserEmailState}>
          <ControlLabel>Enter the email of the fundraiser you want to search</ControlLabel>
          <FormControl
            name="email"
            type="email"
            value={fundraiserEmail}
            placeholder="example@classy.org"
            onChange={handleFundraiserEmailChange}
          />
        </FormGroup>
        <ClassyButton title="Search" disabled={!isFundraiserEmailValid} onClick={handleSearchFundraiser} />
      </Form>
      <FundraiserInformation
        history={history}
        info={fundraiserInfo}
        onUpdate={handleFundraiserUpdate}
        onBadgeDeleted={handleBadgeDeleted}
      />
    </div>
  );
};

export default HistoricalFundraising;
