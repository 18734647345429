import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';
import api from '../../../Services/Api';
import utilities from '../../../Helpers/utilities';
import programDesignationsActions from '../../../Redux/ProgramDesignations.redux';
import constants from '../../../Helpers/constants';

class ProgramDesignationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
    };
  }

  componentDidMount() {
    this.getProgramDesignations();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization.id !== selectedOrganization.id) {
      this.getProgramDesignations();
    }
  }

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  filterProgramDesignationsData = () => {
    const { getProgramDesignationsResponse } = this.props;
    const data = getProgramDesignationsResponse.length > 0 ? getProgramDesignationsResponse : [];
    let filteredData = [];
    if (data) {
      filteredData = data.map((programDesignation, index) => ({
        index: index + 1,
        organizationId: programDesignation.organizationId,
        organizationName: programDesignation.organizationName,
        isUpdate: programDesignation.isUpdate ? 'Updated' : 'New',
        programDesignationId: programDesignation.designationId,
        name: programDesignation.name,
        externalReferenceId: programDesignation.externalReferenceId,
        goal: programDesignation.goal,
        isActive: programDesignation.isActive ? 'Enabled' : 'Disabled',
        city: programDesignation.city,
        state: programDesignation.state,
        postalCode: programDesignation.zip ? Number(programDesignation.zip) : null,
        createdAt: moment.utc(programDesignation.createdAt).local().format('YYYY-MM-DD HH:mm') || null,
        createdBy: programDesignation.createdBy,
      }));
    }
    return filteredData;
  };

  getProgramDesignations = async () => {
    const organizationId = this.props.selectedOrganization.id;
    const startDate = constants.DATES.GET_PROGRAM_DESIGNATIONS_START_DATE;
    const endDate = constants.DATES.GET_PROGRAM_DESIGNATIONS_END_DATE;
    let response;
    try {
      const programDesignationsResponse = await api.getProgramDesignations({
        organizationId,
        startDate,
        endDate,
      });
      /* istanbul ignore next */
      if (programDesignationsResponse.success) {
        const programDesignationsData = programDesignationsResponse.data;
        this.props.getProgramDesignationsSuccess(programDesignationsData);
      } else {
        this.props.getProgramDesignationsFailure(programDesignationsResponse);
      }
    } catch (exception) {
      this.props.getProgramDesignationsFailure(exception);
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  };

  renderProgramDesignationsTable = () => {
    const data = this.filterProgramDesignationsData();
    const columns = [
      {
        Header: 'Index',
        accessor: 'index',
        width: 90,
        sortable: false,
      },
      {
        Header: 'Org ID',
        accessor: 'organizationId',
        width: 90,
        sortable: false,
      },
      {
        Header: 'Org Name',
        accessor: 'organizationName',
        sortable: false,
      },
      {
        Header: 'Update',
        accessor: 'isUpdate',
        width: 100,
      },
      {
        Header: 'Program Designation Id',
        accessor: 'programDesignationId',
        width: 150,
      },
      {
        Header: 'Program Designation Name',
        accessor: 'name',
      },
      {
        Header: 'External ID',
        accessor: 'externalReferenceId',
      },
      {
        Header: 'Goal',
        accessor: 'goal',
        Cell: (row) => <div>{row.value ? `$${row.value}` : null}</div>,
        sortMethod: utilities.sortCurrencyHelper,
      },
      {
        Header: 'Display Status',
        accessor: 'isActive',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'state',
        width: 100,
      },
      {
        Header: 'Zip',
        accessor: 'postalCode',
        width: 100,
      },
      {
        Header: 'Load Date and Time',
        accessor: 'createdAt',
      },
      {
        Header: 'Load user',
        accessor: 'createdBy',
      },
    ];

    return (
      <div>
        <ClassyTable
          showWhenEmpty
          data={data || []}
          columns={columns || []}
          exportFilename={'ProgramDesignations'}
          formattedExportData={this.filterProgramDesignationsData()}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {data && !data.length ? (
          <span>{`No program designations in last ${process.env.REACT_APP_PROGRAM_DESIGNATIONS_DAYS_BACK_FOR_START_DATE} days.`}</span>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Program Designations Dashboard</h2>

        {this.renderProgramDesignationsTable()}
      </div>
    );
  }
}

ProgramDesignationsDashboard.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
  getProgramDesignationsResponse: PropTypes.array.isRequired,
  getProgramDesignationsSuccess: PropTypes.func.isRequired,
  getProgramDesignationsFailure: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization,
    user: { email: userEmail },
  } = state.login;
  const { getProgramDesignationsError, getProgramDesignationsResponse } = state.programDesignations;

  return {
    getProgramDesignationsError,
    getProgramDesignationsResponse,
    selectedOrganization,
    userEmail,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  const { getProgramDesignationsFailure, getProgramDesignationsSuccess } = programDesignationsActions;
  return {
    getProgramDesignationsFailure: (getProgramDesignationsError) =>
      dispatch(getProgramDesignationsFailure(getProgramDesignationsError)),
    getProgramDesignationsSuccess: (getProgramDesignationsResponse) =>
      dispatch(getProgramDesignationsSuccess(getProgramDesignationsResponse)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDesignationsDashboard);
