import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import configureStore from './configureStore';

const appReducers = require('./App.redux').reducer;
const loginReducers = require('./Login.redux').reducer;
const transactionReducers = require('./Transaction.redux').reducer;
const offlineDonationReducers = require('./OfflineDonations.redux').reducer;
const programDesignationsReducers = require('./ProgramDesignations.redux').reducer;
const programDesignationsUpdateReducers = require('./ProgramDesignationsUpdate.redux').reducer;
const fundraisingMinimumReducers = require('./FundraisingMinimums.redux').reducer;
const recurringDonationReducers = require('./RecurringDonations.redux').reducer;
const virtualTerminalReducers = require('./VirtualTerminal.redux').reducer;
const recurringDonationMigrationJobReducers = require('./RecurringDonationMigration.redux').reducer;
const organizationIdInputReducers = require('./OrganizationIdInput.redux').reducer;

const loginPersistConfig = {
  key: 'login',
  storage: storage,
  whitelist: ['selectedOrganization'],
};

export const reducers = combineReducers({
  app: appReducers,
  fundraisingMinimums: fundraisingMinimumReducers,
  login: persistReducer(loginPersistConfig, loginReducers),
  offlineDonations: offlineDonationReducers,
  recurringDonations: recurringDonationReducers,
  transactions: transactionReducers,
  virtualTerminal: virtualTerminalReducers,
  programDesignations: programDesignationsReducers,
  programDesignationsUpdate: programDesignationsUpdateReducers,
  recurringDonationMigration: recurringDonationMigrationJobReducers,
  organizationIdInput: organizationIdInputReducers,
});

const STORE_VERSION = 1;
export const persistConfig = {
  key: 'root',
  version: STORE_VERSION,
  storage,
  blacklist: ['login', 'recurringDonationMigration'],
};

const rootReducer = (state, action) => {
  let currentState = state;
  if (action.type === 'LOGOUT') {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    currentState = undefined;
  } else if (action.type === 'RESET_SESSION') {
    const { login } = currentState;
    currentState = { login };
  }

  return reducers(currentState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = () => {
  const { store } = configureStore(persistedReducer);
  const persistor = persistStore(store);

  return {
    persistor,
    store,
  };
};
