import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropdownButton, MenuItem, Navbar, Panel, PanelGroup } from 'react-bootstrap';
import { flatten } from 'lodash';
import api from '../../Services/Api';
import LoginActions from '../../Redux/Login.redux';
import ClassyLogo from '../../Images/classy-services-logo-500px.png';
import NavLink from '../NavLink/NavLink';
import constants from '../../Helpers/constants';
import WithAuthorization from '../WithAuthorization/WithAuthorization';
import history from '../../Router/history';
import './Sidenav.scss';
import { registrationImportEnabled } from '../../Helpers/featureFlag';
export class Sidenav extends Component {
  static defaultProps = {
    selectedOrganization: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentOrganizationName: '',
    };
    this.formatDropdownName = this.formatDropdownName.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onSelectOrganization = this.onSelectOrganization.bind(this);
  }

  componentDidMount() {
    const { selectedOrganization, user } = this.props;
    const { id } = user;
    if (selectedOrganization) {
      this.getOrganizations(selectedOrganization, id);
      this.setState({ currentOrganizationName: selectedOrganization.name });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      this.setState({ currentOrganizationName: selectedOrganization.name });
    }
  }

  async getOrganizations(selectedOrganization, userId) {
    try {
      const organizationsResponse = await api.getOrganizationsByUser({
        userId,
      });
      if (organizationsResponse.success) {
        const organizations = organizationsResponse.data;
        if (organizations && organizations.length) {
          this.props.getOrganizationsByUserSuccess(organizations);
          this.props.selectOrganization(
            organizations.find((it) => it.id === selectedOrganization?.id) || organizations[0],
          );
        }
      }
    } catch (error) {
      this.props.getOrganizationsByUserFailure(error);
    }
  }

  formatDropdownName(name) {
    let formattedName = name;
    if (name.length > constants.ORGANIZATION_DROPDOWN_NAME_MAX_LENGTH) {
      formattedName = `${name.slice(0, constants.ORGANIZATION_DROPDOWN_NAME_MAX_LENGTH)}...`;
    }
    return formattedName;
  }

  async onLogout() {
    const { logout } = this.props;
    await api.logoutUser();
    await logout();
    history.push('/');
  }

  onSelectOrganization(organization) {
    const { resetSession, selectOrganization } = this.props;
    resetSession();
    this.setState({ currentOrganizationName: organization.name });
    selectOrganization(organization);
    if (!organization.isEnabled && window.location.pathname !== constants.ADMIN_ORG_PATH) {
      history.push('/');
    }
  }

  render() {
    const {
      featurePermissions,
      organizations,
      selectedOrganization: { id: selectedOrganizationId, isEnabled },
    } = this.props;
    const { currentOrganizationName } = this.state;
    const featurePermissionsValues = flatten(
      Object.keys(featurePermissions).map((key) =>
        Object.keys(featurePermissions[key]).map((feature) => featurePermissions[key][feature]),
      ),
    );
    const hasAdminAccess = featurePermissionsValues.filter((value) => value === 'admin').length;
    const hasOperatorAccess = featurePermissionsValues.filter((value) => value === 'operator').length;

    const isSystemOrg = selectedOrganizationId === constants.SYSTEM_ORGANIZATION_ID;

    return (
      <Navbar id="sidenav" className="sidenav" fluid fixedTop>
        <Navbar.Header>
          <Navbar.Brand>
            <div className="sidenav__brand">
              <img src={ClassyLogo} className="sidenav__brand-logo" alt="Classy logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {organizations && organizations.length ? (
            <DropdownButton
              id="organizationsDropdown"
              disabled={organizations.length === 1}
              title={this.formatDropdownName(currentOrganizationName || organizations[0].name)}
              value={selectedOrganizationId}
            >
              {organizations.map((organization, key) => (
                <MenuItem
                  key={key}
                  value={organization.id}
                  className={
                    (organization.name === currentOrganizationName && 'active') ||
                    (!organization.isEnabled && 'disabled') ||
                    ''
                  }
                  onSelect={() => this.onSelectOrganization(organization)}
                >
                  {organization.name}
                </MenuItem>
              ))}
            </DropdownButton>
          ) : null}
          <PanelGroup id="sidenav__panel-group">
            {featurePermissions[selectedOrganizationId] && (
              <>
                {featurePermissions[selectedOrganizationId].admin && hasAdminAccess ? (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Administration</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      {(!isEnabled && (
                        <>
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.ADMIN}
                            path={constants.ROUTES.ADMIN_ORGANIZATIONS}
                            title="Organizations"
                          />
                        </>
                      )) || (
                        <>
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.ADMIN}
                            path={constants.ROUTES.ADMIN_ORGANIZATIONS}
                            title="Organizations"
                          />
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.ADMIN}
                            path={constants.ROUTES.ADMIN_USERS}
                            title="Users"
                          />
                          {isSystemOrg && (
                            <WithAuthorization
                              Component={NavLink}
                              feature={constants.FEATURES.ADMIN}
                              path={constants.ROUTES.ADMIN_SETTINGS}
                              title="Settings"
                            />
                          )}
                          {isSystemOrg && (
                            <WithAuthorization
                              Component={NavLink}
                              feature={constants.FEATURES.ADMIN}
                              path={constants.ROUTES.QUEUES}
                              title="Queues"
                            />
                          )}
                          {isSystemOrg && (
                            <WithAuthorization
                              Component={NavLink}
                              feature={constants.FEATURES.ADMIN}
                              path={constants.ROUTES.CACHES}
                              title="Caches"
                            />
                          )}
                        </>
                      )}
                    </Panel.Collapse>
                  </Panel>
                ) : null}
                {Boolean(isEnabled) && (
                  <>
                    {featurePermissions[selectedOrganizationId].offlineDonations ||
                    featurePermissions[selectedOrganizationId].offlineDonationsDashboard ? (
                      <Panel>
                        {featurePermissions[selectedOrganizationId].offlineDonationsDashboard ? (
                          <Panel>
                            <Panel.Heading>
                              <Panel.Title toggle>Offline Donations</Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.OFFLINE_DONATIONS_DASHBOARD}
                                path={constants.ROUTES.OFFLINE_DONATIONS_DASHBOARD}
                                title="Dashboard"
                              />
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.OFFLINE_DONATIONS}
                                path={constants.ROUTES.OFFLINE_DONATIONS}
                                title="Import"
                              />
                            </Panel.Collapse>
                          </Panel>
                        ) : (
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.OFFLINE_DONATIONS}
                            path={constants.ROUTES.OFFLINE_DONATIONS}
                            title="Offline Donations Import"
                          />
                        )}
                      </Panel>
                    ) : null}
                    {featurePermissions[selectedOrganizationId].programDesignations ||
                    featurePermissions[selectedOrganizationId].programDesignationsDashboard ? (
                      <Panel>
                        {featurePermissions[selectedOrganizationId].programDesignationsDashboard ? (
                          <Panel>
                            <Panel.Heading>
                              <Panel.Title toggle>Program Designations Load</Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.PROGRAM_DESIGNATIONS_DASHBOARD}
                                path={constants.ROUTES.PROGRAM_DESIGNATIONS_DASHBOARD}
                                title="Dashboard"
                              />
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.PROGRAM_DESIGNATIONS}
                                path={constants.ROUTES.PROGRAM_DESIGNATIONS}
                                title="Import"
                              />
                            </Panel.Collapse>
                          </Panel>
                        ) : (
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.PROGRAM_DESIGNATIONS}
                            path={constants.ROUTES.PROGRAM_DESIGNATIONS}
                            title="Program Designations Import"
                          />
                        )}
                      </Panel>
                    ) : null}
                    {featurePermissions[selectedOrganizationId].programDesignationsUpdate ||
                    featurePermissions[selectedOrganizationId].programDesignationsUpdateDashboard ? (
                      <Panel>
                        {featurePermissions[selectedOrganizationId].programDesignationsUpdateDashboard ? (
                          <Panel>
                            <Panel.Heading>
                              <Panel.Title toggle>Program Designations Update</Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.PROGRAM_DESIGNATIONS_UPDATE_DASHBOARD}
                                path={constants.ROUTES.PROGRAM_DESIGNATIONS_UPDATE_DASHBOARD}
                                title="Dashboard"
                              />
                              <WithAuthorization
                                Component={NavLink}
                                feature={constants.FEATURES.PROGRAM_DESIGNATIONS_UPDATE}
                                path={constants.ROUTES.PROGRAM_DESIGNATIONS_UPDATE}
                                title="Import"
                              />
                            </Panel.Collapse>
                          </Panel>
                        ) : (
                          <WithAuthorization
                            Component={NavLink}
                            feature={constants.FEATURES.PROGRAM_DESIGNATIONS_UPDATE}
                            path={constants.ROUTES.PROGRAM_DESIGNATIONS_UPDATE}
                            title="Program Designations Update Import"
                          />
                        )}
                      </Panel>
                    ) : null}
                  </>
                )}

                {(hasAdminAccess && featurePermissions[selectedOrganizationId].VTLocation && (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Virtual Terminal</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL}
                        path={constants.ROUTES.VIRTUAL_TERMINAL}
                        title="Virtual Terminal"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                        path={constants.ROUTES.VIRTUAL_TERMINAL_LOCATION}
                        title="Locations"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                        path={constants.ROUTES.VIRTUAL_TERMINAL_CAMPAIGN_LOCATION}
                        title="Campaign Locations"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                        path={constants.ROUTES.VIRTUAL_TERMINAL_USER_LOCATION}
                        title="User Locations"
                      />
                    </Panel.Collapse>
                  </Panel>
                )) ||
                  (hasAdminAccess && (
                    <WithAuthorization
                      Component={NavLink}
                      feature={constants.FEATURES.VIRTUAL_TERMINAL}
                      path={constants.ROUTES.VIRTUAL_TERMINAL}
                      title="Virtual Terminal"
                    />
                  )) ||
                  null}
                {hasAdminAccess && featurePermissions[selectedOrganizationId].fundraisingTeamPage ? (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>P2P Team/Individual Pages</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.FUNDRAISING_TEAMS_PAGES}
                        path={constants.ROUTES.FUNDRAISING_TEAMS}
                        title="Fundraising Teams"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.FUNDRAISING_TEAMS_PAGES}
                        path={constants.ROUTES.FUNDRAISING_PAGES}
                        title="Fundraising Pages"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.FUNDRAISING_TEAMS_PAGES}
                        path={constants.ROUTES.FUNDRAISING_STORIES}
                        title="Stories"
                      />
                    </Panel.Collapse>
                  </Panel>
                ) : null}
                {Boolean(featurePermissions[selectedOrganizationId]?.[constants.FEATURES.HISTORICAL_FUNDRAISING]) && (
                  <WithAuthorization
                    Component={NavLink}
                    feature={constants.FEATURES.HISTORICAL_FUNDRAISING}
                    path={constants.ROUTES.HISTORICAL_FUNDRAISING}
                    title="Historical Fundraising"
                  />
                )}

                <hr />
                {featurePermissions[selectedOrganizationId]?.RLCMapping && (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Revenue Location Code Mapping</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.RLC_MAPPING}
                        path={constants.ROUTES.RLC_DESIGNATIONS}
                        title="Program Designations"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.RLC_MAPPING}
                        path={constants.ROUTES.RLC_ACCOUNT_HOME}
                        title="RLC Accounts / Campaigns"
                      />
                    </Panel.Collapse>
                  </Panel>
                )}
                {(hasAdminAccess || hasOperatorAccess) &&
                featurePermissions[selectedOrganizationId]?.recurringDonationMigration ? (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Recurring Donation Migration</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.RECURRING_DONATIONS_MIGRATION}
                        path={constants.ROUTES.RECURRING_DONATIONS_MIGRATE}
                        title="Jobs"
                      />
                    </Panel.Collapse>
                  </Panel>
                ) : null}
                {/* ^^ We have to return null here otherwise a '0' appears in the Sidenav. */}
                {featurePermissions[selectedOrganizationId] &&
                  featurePermissions[selectedOrganizationId].cancelRecurringGiftsDashboard && (
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title toggle>Cancel Recurring Gifts</Panel.Title>
                      </Panel.Heading>
                      <Panel.Collapse>
                        <WithAuthorization
                          Component={NavLink}
                          feature={constants.FEATURES.CANCEL_RECURRING_GIFTS_DASHBOARD}
                          path={constants.ROUTES.CANCEL_RECURRING_GIFTS}
                          title="Dashboard"
                        />
                      </Panel.Collapse>
                    </Panel>
                  )}
                {registrationImportEnabled && (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Registration Import</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.REGISTRATION_IMPORT}
                        path={constants.ROUTES.REGISTRATION_IMPORT_DASHBOARD}
                        title="Dashboard"
                      />
                      <WithAuthorization
                        Component={NavLink}
                        feature={constants.FEATURES.REGISTRATION_IMPORT}
                        path={constants.ROUTES.REGISTRATION_IMPORT}
                        title="Import"
                      />
                    </Panel.Collapse>
                  </Panel>
                )}
                <hr />
                {process.env.REACT_APP_NODE_ENV !== constants.ENVIRONMENTS.PRODUCTION && hasAdminAccess ? (
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title toggle>Dev Tools</Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                      <Panel.Body className="sidenav__panel-body">
                        <a
                          href={process.env.REACT_APP_CLASSY_SERVICES_API_DOCS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Swagger
                        </a>
                      </Panel.Body>
                    </Panel.Collapse>
                  </Panel>
                ) : null}
              </>
            )}

            <Panel>
              <Panel.Heading>
                <Panel.Title onClick={this.onLogout}>Log Out</Panel.Title>
              </Panel.Heading>
            </Panel>
          </PanelGroup>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.app;
  const { featurePermissions, organizations, selectedOrganization, user } = state.login;

  return {
    featurePermissions,
    loading,
    organizations,
    selectedOrganization,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getOrganizationsByUserSuccess, getOrganizationsByUserFailure, logout, resetSession, selectOrganization } =
    LoginActions;

  return {
    getOrganizationsByUserSuccess: (organizations) => dispatch(getOrganizationsByUserSuccess(organizations)),
    getOrganizationsByUserFailure: (error) => dispatch(getOrganizationsByUserFailure(error)),
    logout: () => dispatch(logout()),
    resetSession: () => dispatch(resetSession()),
    selectOrganization: (organization) => dispatch(selectOrganization(organization)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
