import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import papaParse from 'papaparse';
import { saveAs } from 'file-saver';
import { get } from 'lodash';

import AppActions from '../../../Redux/App.redux';
import history from '../../../Router/history';
import FileUploader from '../../FileUploader/FileUploader';
import ClassyTable from '../../ClassyTable/ClassyTable';
import Throbber from '../../Throbber/Throbber';
import Card from '../../Card/Card';
import constants from '../../../Helpers/constants';
import ClassyButton from '../../ClassyButton/ClassyButton';
import ErrorPopup from '../ErrorPopup';
import HelpComponent from '../HelpComponent';

import './style.scss';

export class Teams extends Component {
  componentWillUnmount() {
    const { clearTeamResponse } = this.props;
    clearTeamResponse();
  }

  filterTeamsData = (data) => {
    let filteredData = [];

    if (data && Array.isArray(data)) {
      filteredData = data.map((team, index) => ({
        index: index + 1,
        email: team.emailAddress,
        campaignId: team.campaignId,
        firstName: team.firstName,
        lastName: team.lastName,
        rawCurrencyCode: team.rawCurrencyCode,
        rawGoal: team.rawGoal,
        fundraisingTeamId: team.fundraisingTeamId,
        fundraisingPageId: team.fundraisingPageId,
        status: team.isSuccessful ? 'Success' : (Array.isArray(team.result) && team.result.join(', ')) || team.result,
      }));
    }

    return filteredData;
  };

  renderThrobber = () => {
    const { loading } = this.props;

    return <Throbber loading={loading} />;
  };

  renderTransactionReport = () => {
    const { teamResponse, selectedOrganization } = this.props;
    let report;

    if (teamResponse) {
      const { data } = teamResponse;

      const teamReportColumns = [
        {
          Header: 'Row #',
          Cell: (row) => <div id="rowIndex">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
          width: 75,
        },
        {
          Header: 'Email',
          accessor: 'emailAddress',
        },
        {
          Header: 'Campaign Id',
          accessor: 'campaignId',
        },
        {
          Header: 'First Name',
          accessor: 'firstName',
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
        },
        {
          Header: 'Raw Currency Code',
          accessor: 'rawCurrencyCode',
        },
        {
          Header: 'Raw Goal',
          accessor: 'rawGoal',
        },
        {
          Header: 'Fundraising Team Id',
          accessor: 'fundraisingTeamId',
        },
        {
          Header: 'Fundraising Page Id',
          accessor: 'fundraisingPageId',
        },
        {
          Header: 'Status',
          accessor: 'isSuccessful',
          Cell: (row) => {
            return row.value ? (
              <i className="fa fa-check status-icon--success" />
            ) : get(row, 'original.isValid') && !get(row, 'row._original.result') ? (
              <i className="fa fa-exclamation-circle status-icon--success" title="Valid Record" />
            ) : (
              <div id="errorMessage">
                <ErrorPopup
                  error={
                    Array.isArray(get(row, 'row._original.result'))
                      ? get(row, 'row._original.result').join(', ')
                      : get(row, 'row._original.result')
                  }
                />
              </div>
            );
          },
        },
      ];

      if (data && Array.isArray(data)) {
        const failedUploads = data.filter((el) => !el.isSuccessful);
        const successfulUploads = data.filter((el) => el.isSuccessful);
        const validUploads = data.filter((el) => el.isValid);
        const inValidUploads = data.filter((el) => !el.isValid);

        const exportOrganizationName = constants.getChunkedOrganizationName(selectedOrganization.name);

        report = (
          <div>
            {data ? (
              <div>
                {inValidUploads.length ? (
                  <Card
                    messageTop={validUploads.length ? `${validUploads.length} record(s) valid` : null}
                    messageBottom={inValidUploads.length ? `${inValidUploads.length} record(s) invalid` : null}
                  />
                ) : (
                  <Card
                    messageTop={successfulUploads.length ? `${successfulUploads.length} record(s) processed` : null}
                    messageBottom={failedUploads.length ? `${failedUploads.length} record(s) failed` : null}
                  />
                )}
                <Prompt
                  when={!!data.length}
                  message="Are you sure you want to leave? Your results will not be retained."
                />
              </div>
            ) : null}

            {this.renderThrobber()}

            <ClassyTable
              data={data || []}
              exportFilename={`${exportOrganizationName}_Fundraising_Teams`}
              formattedExportData={this.filterTeamsData(data)}
              columns={teamReportColumns}
            />
          </div>
        );
      }
    }
    return report;
  };

  onDownloadTemplate = () => {
    const csv = papaParse.unparse([
      {
        firstName: '',
        lastName: '',
        emailAddress: '',
        gender: '',
        dateOfBirth: '',
        campaignId: '',
        city: '',
        country: '',
        description: '',
        designationId: '',
        name: '',
        postalCode: '',
        rawCurrencyCode: '',
        rawGoal: '',
        state: '',
        thankYouText: '',
        q1Id: '',
        answer1: '',
        q2Id: '',
        answer2: '',
        q3Id: '',
        answer3: '',
        q4Id: '',
        answer4: '',
        q5Id: '',
        answer5: '',
        pageQ1Id: '',
        pageAnswer1: '',
        pageQ2Id: '',
        pageAnswer2: '',
        pageQ3Id: '',
        pageAnswer3: '',
        pageQ4Id: '',
        pageAnswer4: '',
        pageQ5Id: '',
        pageAnswer5: '',
      },
    ]);

    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'FundraisingTeam.csv';
    saveAs(csvBlob, csvName);
  };

  render() {
    const { selectedOrganization, onTeamResponse } = this.props;

    return (
      <div id="fundraisingTeams">
        <HelpComponent
          title="Fundraising Teams"
          content="teams"
          fields="firstName, lastName, emailAddress, campaignId, name"
        />

        <div className="flexRow space-between">
          <h2 className="title-text">Fundraising Teams</h2>
          <ClassyButton
            className="secondary-button fundraising-teams__table-header-button"
            title={'Download Template'}
            onClick={this.onDownloadTemplate}
          />
        </div>

        <FileUploader
          route={`fundraising/${selectedOrganization.id}/${process.env.REACT_APP_CLASSY_SERVICES_API_FUNDRAISING_TEAMS}`}
          onParentComplete={onTeamResponse}
        />

        <ClassyButton className="float-right" title="Next" onClick={() => history.push('/fundraising/pages')} />

        {this.renderTransactionReport()}
      </div>
    );
  }
}

Teams.propTypes = {
  clearTeamResponse: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  teamResponse: PropTypes.object.isRequired,
  onTeamResponse: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loading, teamResponse } = state.app;
  const { selectedOrganization } = state.login;

  return {
    loading,
    teamResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { clearTeamResponse, onTeamResponse } = AppActions;

  return {
    clearTeamResponse: () => dispatch(clearTeamResponse()),
    onTeamResponse: (response) => dispatch(onTeamResponse(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
