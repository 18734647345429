import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { Checkbox, Col, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import { get } from 'lodash';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyRadio from '../ClassyRadio/ClassyRadio';
import CampaignFrequency from './CampaignFrequency/CampaignFrequency';
import TokenExCard from '../TokenExCard';
import StripeCard from '../StripeCard';
import OfflineDonationActions from '../../Redux/OfflineDonations.redux';
import Throbber from '../Throbber/Throbber';
import VirtualTerminalActions from '../../Redux/VirtualTerminal.redux';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';
import formatters from '../../Helpers/formatters';
import localization from '../../Helpers/localization';
import validators from '../../Helpers/validators';
import './VirtualTerminal.scss';
import TokenexAch from '../TokenexAch';
import DynamicInput from './DynamicInput';
import ClassyTable from '../ClassyTable/ClassyTable';
import { RequiredField } from './RequiredField/RequiredField';

import './VirtualTerminal.scss';

const DEFAULT_OPT_IN_WORDING = "It's okay to contact me in the future.";
const VALIDATION_LANG = {
  campaign: 'Please select a campaign',
  programDesignation: 'Please select a Program Designation',
  amount: 'Please select an amount',
  firstName: 'Please provide a first name',
  lastName: 'Please provide a last name',
  emailAddress: 'Please provide a valid email address',
  billingFirstName: 'Please provide a first name',
  billingLastName: 'Please provide a last name',
  country: 'Please select a country',
  address: 'Please provide an address',
  city: 'Please provide a city',
  state: 'Please select a State',
  zip: 'Please provide a zip code',
};

const defaultCountry = localization.COUNTRIES.find((country) => country.code === 'US');

export class VirtualTerminal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.apiAbortController = api.createAbortController();
    this.state = {
      usingTokenEx: false,
      accountNumber: '',
      achEnabled: false,
      achTerms: false,
      alertMessage: '',
      amount: '',
      anonymous: false,
      billingAddress: '',
      billingAddress2: '',
      billingCountry: defaultCountry,
      billingFirstName: '',
      billingLastName: '',
      billingState: null,
      billingZip: '',
      billingCity: '',
      blockUntil: null,
      campaign: null,
      campaignFrequencies: [],
      selectedFrequency: null,
      comment: '',
      ccLastFour: '',
      ccType: '',
      currencyType: {
        label: 'USD',
        value: 'USD',
      },
      expirationMonth: '',
      expirationYear: '',
      isExpiryDateValid: false,
      email: '',
      firstName: '',
      fot: false,
      isAccountNumberValid: false,
      isCardValid: false,
      lastName: '',
      mailingListOptIn: false,
      phone: '',
      paymentMethod: constants.PAYMENT_METHODS.CC,
      routingNumber: '',
      showDonationSuccessfulModal: false,
      showAlert: false,
      supporterSearchData: [],
      isSubmittingForm: false,
      tokenizedCreditCard: '',
      processors: [],
      programDesignations: [],
      programDesignation: null,
      customQuestions: [],
      answers: [],
      sameAsPD: false,
      isValidated: false,
      optInWording: '',
    };
    this.baseState = this.state;
    this.currencies = this.formatCurrencies();

    this.handleCampaignChange = this.handleCampaignChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleTokenize = this.handleTokenize.bind(this);
    this.handleCardValidate = this.handleCardValidate.bind(this);
    this.handleAchTermsChange = this.handleAchTermsChange.bind(this);
    this.handleAchTokenize = this.handleAchTokenize.bind(this);
    this.handleAchValidate = this.handleAchValidate.bind(this);
    this.getValidationStateColor = this.getValidationStateColor.bind(this);
    this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
    this.handleCardExpiryValidate = this.handleCardExpiryValidate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleBillingStateChange = this.handleBillingStateChange.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.registerFormSubmitHandler = this.registerFormSubmitHandler.bind(this);
    this.registerFormResetHandler = this.registerFormResetHandler.bind(this);
    this.shouldUseTokenEx = this.shouldUseTokenEx.bind(this);
    this.getProcessors = this.getProcessors.bind(this);
    this.shouldUseAccountRouting = this.shouldUseAccountRouting.bind(this);
    this.handleChangeSubmitStatus = this.handleChangeSubmitStatus.bind(this);
    this.controlValid = this.controlValid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    // TODO: fix these unterminated promises
    this.getCampaigns();
    this.getOptIn();
    this.getProcessors();

    if (!document.getElementById('googlePlacesAutocomplete')) {
      this.loadGooglePlacesAutocompleteApiScript();
    }
  }

  async getProcessors() {
    const organizationId = this.props.selectedOrganization.id;
    try {
      const processorsResponse = await api.getProcessors(organizationId, { signal: this.apiAbortController.signal });
      if (processorsResponse.success) {
        let processors = processorsResponse.data;
        if (processors && Array.isArray(processors)) {
          processors = processors.filter((processor) => processor.status === 'ACTIVE');
          // sort processors by createdDate desc
          processors.sort((a, b) => {
            if (a.createdDate < b.createdDate) {
              return 1; // since descending
            }
            if (a.createdDate > b.createdDate) {
              return -1;
            }
            return 0;
          });
          this.setState({ processors }, this.shouldUseTokenEx);
          this.shouldUseAccountRouting(processors);
        }
      }
    } catch (exception) {
      if (!exception.isCancelled) {
        this.setAlert(
          `Error: ${exception.errors}\nThere was an error retrieving the processors for this organization.`,
        );
      }
    }
  }

  async getOptIn() {
    const classyOrgId = this.props.selectedOrganization.classyOrganizationId;

    try {
      const result = await api.searchOrganizationById({
        organizationId: classyOrgId,
      });
      this.setState({
        optInWording: (result?.success && result?.data?.optInWording) || DEFAULT_OPT_IN_WORDING,
      });
    } catch {
      this.setState({ optInWording: DEFAULT_OPT_IN_WORDING });
    }
  }

  shouldUseAccountRouting(activeProcessors) {
    let achEnabled = false;
    activeProcessors.forEach((processor) => {
      if (processor.name === 'STRIPE' && processor.paymentTypes.includes('ACH')) {
        achEnabled = true;
      }
    });
    this.setState({ achEnabled });
  }

  /**
   * returns the latest processor that is capable of processing credit cards
   * @param {Array} processors array of active processors
   */
  selectProcessor(processors) {
    return processors.find((p) => p.paymentTypes.includes('Stripe') || p.paymentTypes.includes('CC'));
  }

  shouldUseTokenEx() {
    const { processors, currencyType } = this.state;
    const currency = currencyType.value;
    const processorsForCurrency = processors.filter((processor) => processor.currencies.includes(currency));
    let processor = this.selectProcessor(processorsForCurrency);
    if (!processor) {
      // didn't find a processor for specific currency, look through all active processors.
      processor = this.selectProcessor(processors);
    }
    if (!processor) {
      this.setAlert(`Could not find an active processor for org ${this.organizationId} that handles credit cards.`);
      return;
    }
    const usingTokenEx = !processor.paymentTypes.includes('Stripe');
    this.setState({ usingTokenEx });
  }

  async submitForm() {
    let confirm = () => true;
    if (!this.state.email) {
      confirm = () =>
        typeof window !== 'undefined' &&
        window.confirm('Email id will be automatically generated do you want to continue?');
    }
    if (confirm()) {
      if (this.isFormValid() && (this.state.tokenizedCreditCard || this.state.accountNumber)) {
        this.props.setLoadingVirtualTerminal();
        let isSuccessful = false;
        let response;
        try {
          let donation = {
            amount: Number(this.state.amount),
            anonymous: this.state.anonymous,
            billingAddress: this.state.billingAddress,
            billingAddress2: this.state.billingAddress2,
            billingCountry: this.state.billingCountry.code,
            billingFirstName: this.state.billingFirstName,
            billingLastName: this.state.billingLastName,
            billingState: this.state.billingState ? this.state.billingState.value : '',
            billingZip: this.state.billingZip,
            campaignId: this.state.campaign.value,
            billingCity: this.state.billingCity,
            comment: this.state.comment,
            currencyType: this.state.currencyType.value,
            email: this.state.email,
            firstName: this.state.firstName,
            fot: this.state.fot,
            paymentFrequencyType: this.state.selectedFrequency.type,
            paymentMethod: this.state.paymentMethod,
            lastName: this.state.lastName,
            mailingListOptIn: this.state.mailingListOptIn,
            phone: this.state.phone,
            organizationId: this.props.selectedOrganization.id,
            answers: this.state.answers,
            programDesignationId:
              (this.state.programDesignation && Number(this.state.programDesignation.value)) || null,
          };

          if (this.state.paymentMethod === 'CC') {
            const ccFields = {
              tokenizedCreditCard: this.state.tokenizedCreditCard,
              ccLastFour: this.state.ccLastFour,
              ccType: this.state.ccType,
              expirationMonth: this.state.expirationMonth,
              expirationYear: this.state.expirationYear,
              tokenizer: this.state.usingTokenEx ? 'tokenex' : 'stripe',
            };

            donation = {
              ...donation,
              ...ccFields,
            };
          } else if (this.state.paymentMethod === 'ACH') {
            donation.accountNumber = this.state.accountNumber;
            donation.routingNumber = this.state.routingNumber;
          }
          this.setState({ isSubmittingForm: true });
          const createVirtualTerminalResponse = await api.createVirtualTerminalDonation(donation);
          if (createVirtualTerminalResponse.data.blockUntil) {
            // the user was flagged
            this.setState({
              blockUntil: createVirtualTerminalResponse.data.blockUntil,
            });
            // todo: send action to set blockUntil in user record
          }
          this.setState({ isSubmittingForm: false });
          if (createVirtualTerminalResponse.success) {
            isSuccessful = true;
            response = createVirtualTerminalResponse.data;
          } else {
            response =
              'Unfortunately, we ran into a problem while processing this request - please contact the system administrator';
          }
        } catch (exception) {
          response = exception.errors;
          this.setState({ isSubmittingForm: false });
        }
        if (isSuccessful) {
          this.props.virtualTerminalDonationSuccess(response);
          this.handleModalOpen();
        } else {
          this.props.virtualTerminalDonationFailure(response);
          this.setAlert(response);
          this.resetPaymentForm();

          if (this.state.paymentMethod === constants.PAYMENT_METHODS.ACH && this.state.achEnabled) {
            this.setState({ isValidated: true, isAccountNumberValid: false });
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      this.setState({ campaign: null });
      // TODO: handle these unterminated promises
      this.getCampaigns();
      this.getProcessors();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.apiAbortController.abort();
  }

  setAlert(alertMessage, showAlert = true) {
    if (this._isMounted) {
      this.setState({ alertMessage, showAlert });
    }
  }

  // TODO: use new formatter utilities for formatting select options
  formatCampaigns() {
    const { campaigns } = this.props;
    let formattedCampaigns = [];
    if (campaigns && campaigns.length) {
      formattedCampaigns = campaigns.map((campaign) =>
        (({ id, name, internal_name: internalName }) => ({
          value: id,
          label: name || internalName,
        }))(campaign),
      );
    }
    return formattedCampaigns;
  }

  formatCurrencies() {
    return localization.CURRENCIES.map((currency) =>
      (() => ({
        label: currency,
        value: currency,
      }))(currency),
    );
  }

  async getCampaigns() {
    const organizationId = this.props.selectedOrganization.id;
    try {
      const campaignsResponse = await api.getCampaigns(organizationId, true);
      if (campaignsResponse.success) {
        const campaigns = campaignsResponse.data;
        if (campaigns && Array.isArray(campaigns)) {
          this.props.setCampaigns(campaigns);
        }
      }
    } catch (exception) {
      this.setAlert(`Error: ${exception.errors}\nThere was an error retrieving the campaigns for this organization.`);
    }
  }

  getValidationStateColor() {
    let backgroundColor = '#fff';
    if (!this.isExpirationDateValid()) {
      backgroundColor = 'pink';
    }
    return backgroundColor;
  }

  handleCampaignChange = async (selectedCampaign) => {
    const {
      selectedOrganization: { id: selectedOrganizationId },
      isProgramDesignationEnabled,
      isVTCustomQuestionsEnabled,
    } = this.props;
    const [campaignDetails] = this.props.campaigns.filter((campaign) => campaign.id === selectedCampaign.value);

    let options = {};

    if (isProgramDesignationEnabled) {
      const programDesignationsData = await api.getVTProgramDesignations({
        campaignId: selectedCampaign.value,
        organizationId: selectedOrganizationId,
      });

      //filters out programDesignationsData to return array of active designations
      const programDesignations = programDesignationsData?.data?.filter?.((it) => Boolean(it.is_active));
      options.programDesignations = formatters.formatSelectOptions(programDesignations);
    }

    //QUESTION: why do we stop at 10?
    if (isVTCustomQuestionsEnabled) {
      const customQuestions = await api.getVTCustomQuestions({
        campaignId: selectedCampaign.value,
        organizationId: selectedOrganizationId,
      });
      options.customQuestions = customQuestions?.data?.slice(0, 10);
    }

    const frequencies = await api.getVTDonationFrequencies({
      organizationId: selectedOrganizationId,
      campaignId: selectedCampaign.value,
    });
    const selectedFrequency =
      frequencies?.data?.find((frequency) => Boolean(frequency.default)) || frequencies?.data?.[0];
    this.setState({
      ...options,
      campaignFrequencies: frequencies?.data || [],
      selectedFrequency,
      answers: [],
      campaign: selectedCampaign,
      contactEmail: campaignDetails.contact_email,
    });
  };

  handleProgramDesignationChange = (selectedPD) => {
    this.setState({ programDesignation: selectedPD });
  };

  handleCurrencyChange(currencyType) {
    if (currencyType.value !== 'USD') {
      this.setState({
        paymentMethod: constants.PAYMENT_METHODS.CC,
        accountNumber: '',
        routingNumber: '',
        achEnabled: false,
      });
    } else {
      this.shouldUseAccountRouting(this.state.processors);
    }
    this.setState({ currencyType }, this.shouldUseTokenEx);
  }

  handleAchTermsChange(checked) {
    this.setState({ achTerms: checked });
  }

  handleAchTokenize({ token: accountNumber }) {
    this.setState({ accountNumber }, () => {
      this.submitForm();
    });
  }

  handleAchValidate({ isAccountNumberValid }) {
    if (isAccountNumberValid !== undefined) {
      this.setState({
        isAccountNumberValid,
      });
    }
  }

  handleTokenize({ tokenizedCreditCard, ccLastFour, ccType }) {
    this.setState({ tokenizedCreditCard, ccLastFour, ccType }, () => {
      this.submitForm();
    });
  }

  handleChangeSubmitStatus(status) {
    this.setState({ isSubmittingForm: status });
  }

  handleCardValidate({ isCardValid }) {
    this.setState({
      isCardValid,
    });
  }

  handleCardExpiryChange({ expirationMonth, expirationYear }) {
    const toUpdate = {};
    if (expirationMonth) {
      toUpdate.expirationMonth = expirationMonth;
    }
    if (expirationYear) {
      toUpdate.expirationYear = expirationYear;
    }
    this.setState(toUpdate);
  }

  handleCardExpiryValidate({ isExpiryDateValid }) {
    this.setState({ isExpiryDateValid });
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleModalClose() {
    this.setState({ showDonationSuccessfulModal: false }, () => {
      this.props.clearVirtualTerminalDonationResponse();
      this.resetForm();
    });
  }

  handleModalOpen() {
    this.setState({ showDonationSuccessfulModal: true });
  }

  handleBillingStateChange(billingState) {
    this.setState({ billingState });
  }

  registerFormSubmitHandler(formSubmitHandler) {
    this.formSubmitHandler = formSubmitHandler;
  }

  registerFormResetHandler(formResetHandler) {
    this.formResetHandler = formResetHandler;
  }

  async handleSubmitButton(event) {
    event.preventDefault();
    if (this.isFormValid()) {
      // When user submits form, we first tokenize the credit card
      // Only after getting the token, do we submit the data to the backend
      this.formSubmitHandler();
    }
  }

  initializeAutocomplete() {
    // Initialize Google Autocomplete
    /* global google */
    const el = document.getElementById('virtualTerminal__billingAddress');
    if (!el) {
      return;
    }
    this.autocomplete = new google.maps.places.Autocomplete(el);

    const handlePlaceSelect = () => {
      const addressObject = this.autocomplete.getPlace();
      const addressComponents = addressObject ? addressObject.address_components : [];

      // Check if address is valid
      if (addressComponents && addressComponents.length) {
        // Format address
        const address = {};
        addressComponents.forEach((addressComponent) => {
          if (addressComponent.types && addressComponent.types.length) {
            switch (addressComponent.types[0]) {
              case 'street_number':
                address.streetNumber = addressComponent.long_name;
                break;
              case 'route':
                address.streetName = addressComponent.long_name;
                break;
              case 'subpremise':
                address.unitNumber = addressComponent.long_name;
                break;
              case 'locality':
              case 'postal_town': // For cities in the United Kingdom
                address.city = addressComponent.long_name;
                break;
              case 'administrative_area_level_1':
                address.state = {
                  label: addressComponent.long_name,
                  value: addressComponent.short_name,
                };
                break;
              case 'country':
                address.country = {
                  label: addressComponent.long_name,
                  value: addressComponent.short_name,
                };
                break;
              case 'postal_code':
              case 'postal_code_prefix': // For Canadian postal codes
                address.zip = addressComponent.long_name;
                break;
              default:
                break;
            }
          }
        });
        let streetAddress = addressObject.name;
        if (address.streetNumber && address.streetName) {
          streetAddress = `${address.streetNumber} ${address.streetName}`;
        }
        this.setState({
          billingAddress: streetAddress,
          billingAddress2: address.unitNumber || '',
          billingCity: address.city || '',
          billingState: address.state,
          billingZip: address.zip || '',
        });
      }
    };
    // Fire event when a suggested name is selected
    this.autocomplete.addListener('place_changed', handlePlaceSelect);
  }

  isExpirationDateValid() {
    if (!this.state.usingTokenEx) {
      return this.state.isExpiryDateValid;
    }
    let isValid = true;
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    if (this.state.expirationYear === currentYear && this.state.expirationMonth < currentMonth) {
      isValid = false;
    }
    return isValid;
  }

  controlValid(fieldName) {
    const field = this.state[fieldName];
    return field && this.state.isValidated ? 'success' : 'error';
  }

  isFormValid() {
    let formFilled = false;
    let ccFilled = false;
    let achFilled = false;
    let isValid = true;

    const { isProgramDesignationEnabled, isVTAutoGenerateEmailEnabled } = this.props;
    const {
      programDesignations,
      programDesignation,
      campaign,
      billingAddress,
      amount,
      billingFirstName,
      billingLastName,
      currencyType,
      billingCity,
      firstName,
      lastName,
      billingCountry,
      billingState,
      usingTokenEx,
      isExpiryDateValid,
      expirationMonth,
      expirationYear,
      isCardValid,
      email,
      billingZip,
      customQuestions,
      answers,
    } = this.state;

    if (
      campaign &&
      billingAddress &&
      amount > 0 &&
      billingFirstName &&
      billingLastName &&
      currencyType &&
      currencyType.value &&
      billingCity &&
      firstName &&
      lastName &&
      billingCountry &&
      billingCountry.code &&
      billingState &&
      billingState.value &&
      billingZip
    ) {
      formFilled = true;
    }

    if (isProgramDesignationEnabled && programDesignations.length > 1 && !programDesignation) {
      isValid = false;
    }
    if (customQuestions.length > 0 && !!customQuestions.find((ques) => ques.is_required)) {
      const questions = customQuestions.filter((que) => que.is_required);
      const answ = questions.map((que) =>
        answers.filter((ans) => !!ans.answer).some((ans) => Number(ans.questionId) === que.id),
      );
      if (answ.includes(false)) {
        isValid = false;
      }
    }
    if (!isVTAutoGenerateEmailEnabled) {
      if (!validators.isValidEmail(email)) {
        isValid = false;
      }
    }

    if (
      this.state.paymentMethod === 'CC' &&
      ((!usingTokenEx && isExpiryDateValid) || (expirationMonth && expirationYear)) &&
      this.isExpirationDateValid() &&
      isCardValid
    ) {
      ccFilled = true;
    } else if (
      this.state.paymentMethod === 'ACH' &&
      validators.isValidRoutingNumber(this.state.routingNumber) &&
      this.state.isAccountNumberValid &&
      this.state.achTerms
    ) {
      achFilled = true;
    }
    this.setState((state) => ({ ...state, isValidated: true }));
    return isValid && formFilled && (ccFilled || achFilled);
  }

  loadGooglePlacesAutocompleteApiScript() {
    const googlePlacesAutocompleteApiScript = document.createElement('script');
    googlePlacesAutocompleteApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY}&libraries=places`;
    googlePlacesAutocompleteApiScript.id = 'googlePlacesAutocomplete';
    googlePlacesAutocompleteApiScript.async = true;
    googlePlacesAutocompleteApiScript.onload = () => this.initializeAutocomplete();
    document.body.appendChild(googlePlacesAutocompleteApiScript);
  }

  onChangePhoneNumber(e) {
    const phone = e.target.value;
    let formattedPhoneNumber;
    if (phone) {
      formattedPhoneNumber = phone.replace(/\D/g, '');
    }
    this.setState({ phone: formattedPhoneNumber });
  }

  onDateChange(selected) {
    this.setState({ startDate: selected });
  }

  handleSupporterModal = () => {
    this.setState({ isSupporterSearch: false, supporterSearchData: [] });
  };

  handleSupporterSearch = async (e) => {
    e.preventDefault();
    const searchObj = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      zip: e.target.zip.value,
      organizationId: this.props.selectedOrganization.id,
    };
    try {
      const supporterSearch = await api.getVTSupporterSearch(searchObj);
      if (supporterSearch.success) {
        this.setState({ supporterSearchData: supporterSearch.data });
      }
    } catch (err) {
      this.setAlert(err.message);
    }
  };

  handleRadioChange = (e) => {
    this.setState({
      selectedId: Number(e.target.value),
    });
  };

  handleSupporterSearchSubmit = (e) => {
    e.preventDefault();

    const { selectedId, supporterSearchData, sameAsPD } = this.state;

    if (selectedId) {
      const supporterData = supporterSearchData.filter((data) => data.id === selectedId);
      const {
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        address1: billingAddress,
        address2: billingAddress2,
        state: billingStateValue,
        city: billingCity,
        phone,
        postal_code: billingZip,
      } = supporterData[0];

      const billingState = localization?.STATES?.find?.((state) => state.value === billingStateValue) || null;
      this.setState(
        {
          firstName: firstName || '',
          lastName: lastName || '',
          billingAddress: billingAddress || '',
          billingAddress2: billingAddress2 || '',
          billingState,
          billingCity: billingCity || '',
          billingZip: billingZip || '',
          phone: phone || '',
          email: email || '',
          isSupporterSearch: false,
          supporterSearchData: [],
          selectedId: null,
        },
        () => {
          if (sameAsPD) {
            this.handleAddressChange();
          }
        },
      );
    }
  };

  handleAddressChange = () => {
    const { firstName, lastName } = this.state;
    this.setState({
      billingFirstName: firstName || '',
      billingLastName: lastName || '',
    });
  };

  renderSupporterSearch = () => {
    const { supporterSearchData, isSupporterSearch, selectedId } = this.state;
    const columns = [
      {
        width: 50,
        Cell: (row) => (
          <input
            data-testid="vt_supporter_search_radio_input"
            className="radio-input"
            type="radio"
            name={row.row.id}
            value={row.row.id}
            checked={selectedId === row.row.id}
            onChange={this.handleRadioChange}
          />
        ),
      },
      {
        Header: 'Row #',
        Cell: (row) => <div>{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
        width: 90,
        resizable: false,
      },
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email_address',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Zip',
        accessor: 'postal_code',
      },
    ];
    return (
      <Modal
        data-testid="vt_supporter_search_modal"
        show={isSupporterSearch}
        onHide={this.handleSupporterModal}
        className="supporter-search-modal"
      >
        <Modal.Header data-testid="vt_supporter_search_modal_header" closeButton>
          <Modal.Title data-testid="vt_supporter_search_modal_title" componentClass={'h3'}>
            Supporter Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body data-testid="vt_supporter_search_modal_body">
          <form onSubmit={this.handleSupporterSearch}>
            <Row>
              <Col xs={12}>
                <span data-testid="vt_first_name">First Name</span>
                <FormGroup data-testid="vt_first_name_form_group">
                  <FormControl data-testid="vt_first_name_form_control" name="firstName" type="text" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <span data-testid="vt_last_name">Last Name</span>
                <FormGroup data-testid="vt_last_name_form_group">
                  <FormControl data-testid="vt_first_name_form_control" name="lastName" type="text" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <span data-testid="vt_email">Email Address</span>
                <FormGroup data-testid="vt_email_form_group">
                  <FormControl data-testid="vt_email_form_control" name="email" type="text" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <span data-testid="vt_zip">Zip</span>
                <FormGroup data-testid="vt_zip_form_group">
                  <FormControl data-testid="vt_zip_form_control" name="zip" type="text" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <ClassyButton data-testid="vt_search_button" type="submit" title="Search" />
              </Col>
            </Row>
          </form>
          <ClassyTable
            data-testid="vt_supporter_search_table"
            showWhenEmpty
            data={supporterSearchData}
            columns={columns || []}
            defaultPageSize={15}
            pageSizeOptions={[15, 25, 50, 100]}
          />
          {supporterSearchData && !supporterSearchData.length ? <span>No data found.</span> : null}
          <Row>
            <Col xs={12}>
              <ClassyButton
                data-testid="vt_supporter_search_submit_button"
                disabled={!selectedId}
                title="Submit"
                onClick={this.handleSupporterSearchSubmit}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  renderDonationSuccessfulModal() {
    let modal = null;
    const {
      isVTAutoGenerateEmailEnabled,
      virtualTerminalDonationResponse: { confirmationNumber, email },
    } = this.props;
    if (confirmationNumber) {
      const currencySign = localization.getCurrencySign(this.state.currencyType.value);
      const displayAmount = parseFloat(this.state.amount).toFixed(2);
      const donationFrequency = this.state.selectedFrequency ? `${this.state.selectedFrequency.name} donation` : '';

      modal = (
        <Modal
          data-testid="vt_donation_successful_modal"
          show={this.state.showDonationSuccessfulModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header data-testid="vt_donation_successful_modal_header" closeButton>
            <Modal.Title data-testid="vt_donation_successful_modal_title" componentClass={'h3'}>
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body data-testid="vt_donation_successful_modal_body">
            <Row>
              <Col xs={12}>
                {`Thank you for your donation! ${
                  (!isVTAutoGenerateEmailEnabled && 'A confirmation email has been sent.') || ''
                }`}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12} md={4}>
                Email:
              </Col>
              <Col xs={12} md={8}>
                {email}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12} md={4}>
                Amount:
              </Col>
              <Col xs={12} md={8}>
                {currencySign}
                {displayAmount}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12} md={4}>
                Frequency:
              </Col>
              <Col xs={12} md={8}>
                {donationFrequency}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12} md={4}>
                Confirmation Number:
              </Col>
              <Col xs={12} md={8}>
                {confirmationNumber}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12} md={4}>
                Receiving Organization:
              </Col>
              <Col xs={12} md={8}>
                {this.props.selectedOrganization.name}
              </Col>
            </Row>
            <Row className="classy-modal__row-top">
              <Col xs={12}>Your Comment:</Col>
              <Col xs={12}>{this.state.comment}</Col>
            </Row>
          </Modal.Body>
          {this.state.contactEmail && (
            <Modal.Footer
              data-testid="vt_donation_successful_modal_footer"
              bsClass="classy-modal__footer classy-modal__footer-left classy-modal__footer--small"
            >
              <Row>
                <Col xs={12}>
                  <span>
                    <i className="fa fa-life-ring" /> Need help or have questions about your donation? contact:{' '}
                    <a href={`mailto:${this.state.contactEmail}`}>{this.state.contactEmail}</a>
                  </span>
                </Col>
              </Row>
            </Modal.Footer>
          )}
        </Modal>
      );
    }
    return modal;
  }

  renderThrobber() {
    const { loadingVirtualTerminal } = this.props;

    return <Throbber loading={loadingVirtualTerminal} />;
  }

  renderCard() {
    const { isValidated, isExpiryDateValid, usingTokenEx, isCardValid, expirationMonth, expirationYear } = this.state;

    const organizationId = this.props.selectedOrganization.id;

    if (usingTokenEx) {
      return (
        <TokenExCard
          isCardValid={isCardValid}
          expirationMonth={`${expirationMonth}`}
          expirationYear={`${expirationYear}`}
          handleCardValidate={this.handleCardValidate}
          getValidationStateColor={this.getValidationStateColor}
          handleCardExpiryChange={this.handleCardExpiryChange}
          changeSubmitStatus={this.handleChangeSubmitStatus}
          registerFormSubmitHandler={this.registerFormSubmitHandler}
          registerFormResetHandler={this.registerFormResetHandler}
          handleTokenize={this.handleTokenize}
          organizationId={organizationId}
        />
      );
    }
    return (
      <StripeCard
        isValidated={isValidated}
        isCardValid={isCardValid}
        isExpiryDateValid={isExpiryDateValid}
        registerFormSubmitHandler={this.registerFormSubmitHandler}
        registerFormResetHandler={this.registerFormResetHandler}
        changeSubmitStatus={this.handleChangeSubmitStatus}
        handleTokenize={this.handleTokenize}
        handleCardExpiryChange={this.handleCardExpiryChange}
        handleCardValidate={this.handleCardValidate}
        handleCardExpiryValidate={this.handleCardExpiryValidate}
        handleError={this.setAlert}
      />
    );
  }

  renderACH() {
    const { isAccountNumberValid, routingNumber, achTerms, isValidated } = this.state;
    const organizationId = this.props.selectedOrganization.id;
    return (
      <TokenexAch
        isValidated={isValidated}
        handleTokenize={this.handleAchTokenize}
        handleAchValidate={this.handleAchValidate}
        handleRoutingNumberChange={this.handleInputChange}
        handleAchTermsChange={this.handleAchTermsChange}
        registerFormSubmitHandler={this.registerFormSubmitHandler}
        registerFormResetHandler={this.registerFormResetHandler}
        changeSubmitStatus={this.handleChangeSubmitStatus}
        isAccountNumberValid={isAccountNumberValid}
        organizationId={organizationId}
        routingNumber={routingNumber}
        achTerms={achTerms}
      />
    );
  }

  resetForm() {
    this.resetPaymentForm();
    this.setState((state) => ({
      ...this.baseState,
      optInWording: state.optInWording,
    }));
    // TODO: resolve this promise
    this.getProcessors();
  }

  resetPaymentForm() {
    if (this.formResetHandler) {
      this.formResetHandler();
    }
    this.setState({
      accountNumber: '',
      ccLastFour: '',
      ccType: '',
      expirationMonth: '',
      expirationYear: '',
      isValidated: false,
      routingNumber: '',
      tokenizedCreditCard: '',
    });
  }

  handleQuestionChange = (e, type, id, isPhone) => {
    const { answers } = this.state;
    let formattedPhoneNumber;
    if (isPhone) {
      const phone = e.target.value;
      formattedPhoneNumber = phone.replace(/\D/g, '');
    }
    const questionId = type === 'enum' ? id : e.target.name;
    const answer =
      (type === 'boolean' && e.target.checked) ||
      (type === 'enum' ? e.label : isPhone ? formattedPhoneNumber : e.target.value);

    const answerObj = [
      {
        questionId: Number(questionId),
        answer,
      },
    ];
    const isPresent = answers.length > 0 && !!answers.find((ans) => Number(ans.questionId) === Number(questionId));
    if (isPresent) {
      const newAnswer = answers.map(
        (ans) => answerObj.find((newAns) => Number(newAns.questionId) === Number(ans.questionId)) || ans,
      );
      return this.setState({
        answers: newAnswer,
      });
    }
    return this.setState({
      answers: [...answers, ...answerObj],
    });
  };

  handleFrequencySelected = (frequency) => {
    this.setState({
      selectedFrequency: frequency,
    });
  };

  render() {
    const {
      amount,
      anonymous,
      achEnabled,
      billingAddress,
      billingAddress2,
      billingCountry,
      billingState,
      billingZip,
      billingFirstName,
      billingLastName,
      campaign,
      billingCity,
      comment,
      currencyType,
      email,
      firstName,
      lastName,
      mailingListOptIn,
      phone,
      isSubmittingForm,
      paymentMethod,
      programDesignation,
      programDesignations,
      customQuestions,
      answers,
      sameAsPD,
      campaignFrequencies,
      selectedFrequency,
      optInWording,
    } = this.state;

    const { isProgramDesignationEnabled, isVTSupporterSearchEnabled, isVTAutoGenerateEmailEnabled } = this.props;

    const campaigns = this.formatCampaigns();
    const commentPlaceholder =
      'Let us know why you donated, tell your story, or send words of encouragement. Your comment will appear publicly.';
    const phoneLength = localization.getPhoneNumberLengthByCountry(billingCountry?.code || '');
    const currencySign = localization.getCurrencySign(
      currencyType ? currencyType.value : '',
      'virtualTerminal__amount-currency-icon',
    );

    const { isValidated } = this.state;
    return (
      <div id="virtual-terminal" className="flexColumn centered">
        <ClassyAlert
          data-testid="vt_alert"
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => {
            this.setState({
              alertMessage: '',
              showAlert: false,
              isSubmittingForm: false,
            });
          }}
        />

        <h2 className="title-text">Virtual Terminal</h2>
        <div className="virtualTerminal__container">
          <Form data-testid="vt_form">
            <Row className="margin-bottom-large">
              <Col xs={12}>
                <span>
                  Campaign<sup className="required-marker">*</sup>
                </span>

                <RequiredField
                  data-testid="vt_campaign_dropdown_required_field"
                  id="campaign-dropdown"
                  isValidated={isValidated}
                  useFeedback={false}
                  message={VALIDATION_LANG.campaign}
                  hasError={this.state.isValidated && !this.state.campaign}
                >
                  <Select
                    data-testid="vt_control_valid_select"
                    className="controlValid"
                    menuPortalTarget={document.body}
                    value={campaign}
                    onChange={this.handleCampaignChange}
                    options={campaigns}
                  />
                </RequiredField>
              </Col>
            </Row>
            {!!campaignFrequencies?.length && !!selectedFrequency && (
              <CampaignFrequency
                data-testid="vt_campaign_frequency"
                frequencies={campaignFrequencies}
                selectedFrequency={selectedFrequency}
                onFrequencySelected={this.handleFrequencySelected}
              />
            )}
            {isProgramDesignationEnabled && programDesignations.length > 1 && (
              <Row className="margin-bottom-large">
                <Col xs={12}>
                  <span>
                    Program Designations<sup className="required-marker">*</sup>
                  </span>
                  <RequiredField
                    data-testid="vt_program_designation_required_field"
                    id="programDesignation"
                    isValidated={isValidated}
                    message={VALIDATION_LANG.programDesignation}
                    hasError={this.state.isValidated && !programDesignation}
                  >
                    <Select
                      data-testid="vt_program_designation_select"
                      menuPortalTarget={document.body}
                      value={programDesignation}
                      onChange={this.handleProgramDesignationChange}
                      options={programDesignations}
                    />
                  </RequiredField>
                </Col>
              </Row>
            )}
            <Row className="margin-bottom-large">
              <Col xs={6}>
                <span>
                  Amount<sup className="required-marker">*</sup>
                </span>
                <FormGroup data-testid="vt_currency_type_form_group" controlId="currencyType">
                  <FormControl
                    data-testid="vt_currency_type_form_control"
                    componentClass="react-select"
                    name="currencyType"
                  >
                    <Select
                      data-testid="vt_currency_change_select"
                      menuPortalTarget={document.body}
                      value={currencyType}
                      onChange={this.handleCurrencyChange}
                      options={this.currencies}
                    />
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <span>&nbsp;</span>
                {currencySign}
                <RequiredField
                  data-testid="vt_amount_required_field"
                  id="amount"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.amount}
                  hasError={this.state.isValidated && !(this.state.amount > 0)}
                >
                  <FormControl
                    data-testid="vt_amount_form_control"
                    name="amount"
                    type="text"
                    value={amount}
                    placeholder="35.00"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
          </Form>

          <Form data-testid="vt_supporter_search_personal_details_form">
            <div className="supporter-search">
              <h3>Personal Details</h3>
              {isVTSupporterSearchEnabled && (
                <ClassyButton
                  data-testid="vt_supporter_search_button"
                  title="Supporter Search"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isSupporterSearch: true });
                  }}
                />
              )}
            </div>
            <Row>
              <Col xs={6}>
                <span>
                  First Name<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_first_name_required_field"
                  id="virtualTerminal__firstName"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.firstName}
                  hasError={isValidated && !validators.isValidName(firstName)}
                >
                  <FormControl
                    data-testid="vt_first_name_form_control"
                    name="firstName"
                    type="text"
                    value={firstName}
                    placeholder="Jane"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
              <Col xs={6}>
                <span>
                  Last Name<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_last_name_required_field"
                  id="virtualTerminal__lastName"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.lastName}
                  hasError={isValidated && !validators.isValidName(lastName)}
                >
                  <FormControl
                    data-testid="vt_last_name_form_control"
                    name="lastName"
                    type="text"
                    value={lastName}
                    placeholder="Smith"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox
                  data-testid="vt_anonymous_checkbox"
                  id="virtualTerminal__anonymous"
                  checked={anonymous}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({ anonymous: value });
                  }}
                >
                  Make donation anonymous.
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>Email {!isVTAutoGenerateEmailEnabled && <sup className="required-marker">*</sup>} </span>
                <RequiredField
                  data-testid="vt_email_required_field"
                  id="virtualTerminal__email"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.emailAddress}
                  hasError={isValidated && (!validators.isValidEmail(email) || email === '')}
                >
                  <FormControl
                    data-testid="vt_email_form_control"
                    name="email"
                    type="text"
                    value={email}
                    placeholder="janesmith@gmail.com"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
                {!!optInWording && (
                  <FormGroup data-testid="vt_mailing_list_form_group" id="virtualTerminal__mailingListOptIn">
                    <span className="text-muted">Your receipt will be emailed here</span>
                    <Checkbox
                      data-testid="vt_mailing_list_checkbox"
                      checked={mailingListOptIn}
                      onChange={(e) => {
                        const value = e.target.checked;
                        this.setState({ mailingListOptIn: value });
                      }}
                    >
                      {optInWording}
                    </Checkbox>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>Phone Number</span>
                <FormGroup
                  data-testid="vt_phone_form_group"
                  controlId="virtualTerminal__phone"
                  validationState={validators.getValidStatePhoneNumber(phone, billingCountry?.code)}
                >
                  <FormControl
                    data-testid="vt_phone_form_control"
                    maxLength={phoneLength}
                    name="phone"
                    type="text"
                    placeholder="619-123-4567"
                    value={phone || ''}
                    onChange={this.onChangePhoneNumber}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>Leave a comment</span>
                <FormControl
                  data-testid="vt_comment_form_control"
                  componentClass="textarea"
                  name="comment"
                  type="text"
                  placeholder={commentPlaceholder}
                  value={comment}
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
          </Form>

          {customQuestions.length > 0 && (
            <Form data-testid="vt_custom_questions_form">
              {customQuestions.map((customQuestion) => (
                <Row key={customQuestion.id}>
                  <Col xs={12}>
                    {customQuestion.type === 'boolean' && (
                      <Row>
                        <Col xs={12}>
                          Waiver
                          <sup className="required-marker">{customQuestion.is_required ? '*' : null}</sup>
                        </Col>
                      </Row>
                    )}
                    <span>
                      {customQuestion.label}
                      {customQuestion.type !== 'boolean' && (
                        <sup className="required-marker">{customQuestion.is_required ? '*' : null}</sup>
                      )}
                    </span>
                    <FormGroup
                      data-testid="vt_validation_form_group"
                      validationState={
                        ['cellphone', 'homephone'].includes(customQuestion.tag)
                          ? validators.getValidStatePhoneNumber(
                              get(
                                answers.find((ans) => ans.questionId === customQuestion.id),
                                'answer',
                              ),
                              billingCountry?.code,
                            )
                          : null
                      }
                    >
                      <DynamicInput
                        data-testid="vt_question_change_input"
                        handleChange={this.handleQuestionChange}
                        type={customQuestion.type}
                        phoneLength={phoneLength}
                        tag={customQuestion.tag}
                        questionId={customQuestion.id}
                        options={customQuestion.enum_options}
                        value={get(
                          answers.find((ans) => ans.questionId === customQuestion.id),
                          'answer',
                        )}
                        label={customQuestion.label}
                      />
                      <FormControl.Feedback />
                    </FormGroup>
                  </Col>
                </Row>
              ))}
            </Form>
          )}

          <Form data-testid="vt_payment_details_form">
            <h3>Payment Details</h3>
            <Row>
              <Col xs={12}>
                <Checkbox
                  data-testid="vt_payment_details_checkbox"
                  id="virtualTerminal_sameAsPD"
                  checked={sameAsPD}
                  onChange={(e) => {
                    const value = e.target.checked;
                    this.setState({ sameAsPD: value });
                    if (value) {
                      this.handleAddressChange();
                    }
                  }}
                >
                  Same as personal Details.
                </Checkbox>
              </Col>
            </Row>
            <Row className="margin-bottom-large">
              <Col xs={6}>
                <span>
                  Billing First Name<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_first_name_required_field"
                  id="virtualTerminal__billingFirstName"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.billingFirstName}
                  hasError={isValidated && !validators.isValidName(billingFirstName)}
                >
                  <FormControl
                    data-testid="vt_billing_first_name_form_control"
                    name="billingFirstName"
                    type="text"
                    value={billingFirstName}
                    placeholder="Jane"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
              <Col xs={6}>
                <span>
                  Billing Last Name<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_last_name_required_field"
                  id="virtualTerminal__billingLastName"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.billingLastName}
                  hasError={isValidated && !validators.isValidName(billingLastName)}
                >
                  <FormControl
                    data-testid="vt_billing_last_name_form_control"
                    name="billingLastName"
                    type="text"
                    value={billingLastName}
                    placeholder="Smith"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
            <Row className="margin-bottom-large">
              <Col xs={12}>
                <span>
                  Country: <span className="stroked">{billingCountry?.name || ''}</span>
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>
                  Address<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_address_required_field"
                  id="billingAddress"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.address}
                  hasError={isValidated && !validators.isValidName(billingAddress)}
                >
                  <FormControl
                    // TODO: Maybe re-enable google autocomletion by uncommenting this?
                    // but shouldn't it be a ref?
                    // id='virtualTerminal__billingAddress'
                    data-testid="vt_billing_address_form_control"
                    name="billingAddress"
                    type="text"
                    value={billingAddress}
                    placeholder="123 Maple Dr"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>Address 2</span>
                <FormGroup
                  data-testid="vt_billing_address2_form_group"
                  controlId="virtualTerminal__billingAddress2"
                  validationState={validators.getValidStateName(billingAddress2)}
                >
                  <FormControl
                    data-testid="vt_billing_address_2_form_control"
                    name="billingAddress2"
                    type="text"
                    value={billingAddress2}
                    onChange={this.handleInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <span>
                  City<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_city_required_field"
                  id="virtualTerminal__billingCity"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.city}
                  hasError={isValidated && !validators.isValidName(billingCity)}
                >
                  <FormControl
                    data-testid="vt_billing_city_form_control"
                    name="billingCity"
                    type="text"
                    value={billingCity}
                    placeholder="San Diego"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <span>
                  State
                  <sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_state_required_field"
                  id="billingState"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.state}
                  useFeedback={false}
                  hasError={isValidated && !validators.isValidName(billingState?.value)}
                >
                  <Select
                    data-testid="vt_billing_state_select"
                    menuPortalTarget={document.body}
                    value={
                      typeof billingState === 'string'
                        ? localization.STATES.filter((state) => state.value === billingState)[0]
                        : billingState
                    }
                    onChange={this.handleBillingStateChange}
                    options={localization.STATES}
                  />
                </RequiredField>
              </Col>
              <Col xs={6}>
                <span>
                  Zip<sup className="required-marker">*</sup>
                </span>
                <RequiredField
                  data-testid="vt_billing_zip_required_field"
                  id="virtualTerminal__billingZip"
                  isValidated={isValidated}
                  message={VALIDATION_LANG.zip}
                  hasError={isValidated && !billingZip}
                >
                  <FormControl
                    data-testid="vt_billing_zip_form_control"
                    name="billingZip"
                    type="text"
                    value={billingZip}
                    placeholder="Zip"
                    onChange={this.handleInputChange}
                  />
                </RequiredField>
              </Col>
            </Row>
            {achEnabled && (
              <Row>
                <Col xs={12}>
                  <ClassyRadio
                    data-testid="vt_payment_method_classy_radio"
                    name="paymentMethod"
                    defaultChecked={paymentMethod}
                    onChange={(value) => {
                      this.setState({ paymentMethod: value });
                    }}
                    options={[
                      {
                        label: 'Credit Card',
                        value: constants.PAYMENT_METHODS.CC,
                      },
                      {
                        label: 'Bank Account',
                        value: constants.PAYMENT_METHODS.ACH,
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {paymentMethod === constants.PAYMENT_METHODS.ACH && achEnabled ? this.renderACH() : this.renderCard()}
          </Form>

          <Row className="margin-bottom-large">
            <Col xs={12} className="text-center">
              <ClassyButton
                data-testid="vt_submit_button"
                id="virtualTerminal__submitButton"
                title="Submit Gift"
                onClick={this.handleSubmitButton}
                disabled={isSubmittingForm}
              />
            </Col>
          </Row>
        </div>
        {this.renderDonationSuccessfulModal()}
        {this.renderThrobber()}
        {this.renderSupporterSearch()}
      </div>
    );
  }
}

VirtualTerminal.propTypes = {
  campaigns: PropTypes.array,
  clearVirtualTerminalDonationResponse: PropTypes.func.isRequired,
  loadingVirtualTerminal: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object,
  setCampaigns: PropTypes.func.isRequired,
  setLoadingVirtualTerminal: PropTypes.func.isRequired,
  user: PropTypes.object,
  virtualTerminalDonationResponse: PropTypes.object.isRequired,
  virtualTerminalDonationFailure: PropTypes.func.isRequired,
  virtualTerminalDonationSuccess: PropTypes.func.isRequired,
  isProgramDesignationEnabled: PropTypes.bool,
  isVTCustomQuestionsEnabled: PropTypes.bool,
  isVTSupporterSearchEnabled: PropTypes.bool,
  isVTAutoGenerateEmailEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { selectedOrganization, user } = state.login;
  const { campaigns } = state.offlineDonations;
  const { loadingVirtualTerminal, virtualTerminalDonationResponse, virtualTerminalDonationErrors } =
    state.virtualTerminal;
  return {
    campaigns,
    loadingVirtualTerminal,
    selectedOrganization,
    user,
    virtualTerminalDonationResponse,
    virtualTerminalDonationErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { setCampaigns } = OfflineDonationActions;
  const {
    setLoadingVirtualTerminal,
    virtualTerminalDonationFailure,
    virtualTerminalDonationSuccess,
    clearVirtualTerminalDonationResponse,
  } = VirtualTerminalActions;
  return {
    clearVirtualTerminalDonationResponse: () => dispatch(clearVirtualTerminalDonationResponse()),
    setCampaigns: (campaigns) => dispatch(setCampaigns(campaigns)),
    setLoadingVirtualTerminal: () => dispatch(setLoadingVirtualTerminal()),
    virtualTerminalDonationFailure: (virtualTerminalDonationErrors) => {
      dispatch(virtualTerminalDonationFailure(virtualTerminalDonationErrors));
    },
    virtualTerminalDonationSuccess: (virtualTerminalDonationResponse) => {
      dispatch(virtualTerminalDonationSuccess(virtualTerminalDonationResponse));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualTerminal);
