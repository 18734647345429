//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { ControlLabel, FormControl, FormGroup, Form, HelpBlock } from 'react-bootstrap';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import constants from '../../Helpers/constants';
import OrganizationIdInput from '../OrganizationIdInput/OrganizationIdInput';
import api from '../../Services/Api';
import Throbber from '../Throbber/Throbber';
import papaParse from 'papaparse';
import './style.scss';
import history from '../../Router/history';
import { attendeeColumns } from './AttendeeColumns';
import { teamsColumns } from './TeamColumns';

let teamsTableDataStorage: Array<any> = [];
let attendeeTableDataStorage: Array<any> = [];
let parsedAttendeeFile: Array<any> = [];
let parsedTeamFile: Array<any> = [];

const matchVals = (arr1, arr2) => {
  const arr1Sorted = arr1.sort();
  const arr2Sorted = arr2.sort();
  return arr1.length === arr2.length && arr1Sorted.every((val, index) => val === arr2Sorted[index]);
};

const OrganizationStep = ({
  setServicesOrganizationId,
  setOrganizationId,
  campaigns,
  selectedCampaign,
  setSelectedCampaign,
}) => {
  const [organizationIdError] = useState<string | undefined>(undefined);
  const [, setOrganizationIdLoading] = useState();

  const campaignOptions = useMemo(
    () =>
      campaigns.map((it) => ({
        value: it.id,
        label: it.name || it.internal_name,
      })),
    [campaigns],
  );

  const selectedCampaignOption = useMemo(
    () => campaignOptions.find((it) => it.value === selectedCampaign?.id),
    [campaignOptions, selectedCampaign],
  );

  const handleSelectCampaign = useCallback(
    (selected) => {
      teamsTableDataStorage = [];
      attendeeTableDataStorage = [];
      setSelectedCampaign(campaigns.find((it) => it.id === selected.value));
    },
    [setSelectedCampaign, campaigns],
  );

  return (
    <>
      <OrganizationIdInput
        organizationIdError={organizationIdError}
        setServicesOrganizationId={setServicesOrganizationId}
        setOrganizationId={setOrganizationId}
        setOrganizationIdLoading={setOrganizationIdLoading}
      />
      <Select
        menuPortalTarget={document.body}
        value={selectedCampaignOption}
        onChange={handleSelectCampaign}
        options={campaignOptions}
      />
    </>
  );
};

const TeamImportStep = (teamProps) => {
  const { setHasTeamsFile, setTeamsFile, selectedCampaign, servicesOrganizationId } = teamProps;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [teamsTableData, setTeamsTableData] = useState([]);
  const [teamsTableColumns] = useState(teamsColumns);
  const onHide = () => {
    setAlertMessage('');
    setShowAlert(false);
  };

  useEffect(() => {
    let isMounted = true;

    if (teamsTableDataStorage.length > 0 && isMounted) {
      setTeamsTableData(teamsTableDataStorage);
    } else {
      const fetchAllCampaignTeams = async () => {
        const fetchedTeams = await api.getCampaignFundraisingTeams({
          campaignId: selectedCampaign.id,
          servicesOrganizationId: servicesOrganizationId,
        });

        let teamsData = [];
        fetchedTeams?.data?.forEach((el) => {
          if (el.campaign_id === selectedCampaign.id) {
            teamsData.push({
              id: el.id,
              name: el.name,
              updated_at: el.updated_at,
              description: el.description,
              city: el.city,
              state: el.state,
              country: el.country,
              postal_code: el.postal_code,
              campaign_id: el.campaign_id,
              parent_id: el.parent_id,
              raw_goal: el.raw_goal,
              raw_currency_code: el.raw_currency_code,
              team_lead_id: el.team_lead_id,
              team_lead_supporter_id: el.team_lead_supporter_id,
              thank_you_text: el.thank_you_text,
              designation_id: el.designation_id,
            });
          }
        });
        teamsTableDataStorage = teamsData;
        setTeamsTableData(teamsTableDataStorage);
      };

      if (selectedCampaign && isMounted) {
        fetchAllCampaignTeams();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [servicesOrganizationId, selectedCampaign, setTeamsTableData]);

  const handleTeamFileChange = useCallback(
    (e) => {
      setAlertMessage('');
      setShowAlert(false);

      const { files } = e.target;

      if (!files || !files[0]) {
        return;
      }
      const uploadedFile = files[0];
      try {
        if (uploadedFile) {
          papaParse.parse(uploadedFile, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              const expectedHeader = [
                'name',
                'description',
                'raw_currency_code',
                'raw_goal',
                'city',
                'state',
                'country',
                'postal_code',
                'thank_you_text',
                'url',
                'designation_id',
              ];
              const fileHeader = results.meta.fields;
              const parsedTemplate: Array<any> = results.data;

              const parsedTemplateConverted = parsedTemplate.map((obj) => {
                if (obj.hasOwnProperty('postal_code')) {
                  obj.postal_code = String(obj.postal_code);
                }
                return obj;
              });

              const requiredColumns = [
                'description',
                'name',
                'raw_currency_code',
                'raw_goal',
                'city',
                'state',
                'country',
                'postal_code',
                'thank_you_text',
                'designation_id',
              ];

              const missingColumnValues = requiredColumns.filter((el) => !fileHeader?.includes(el));

              if (parsedTemplate.length < 1) {
                setAlertMessage('Uploaded file is empty. Please add at least one row, and try uploading again.');
                setShowAlert(true);
              } else if (!matchVals(expectedHeader, fileHeader)) {
                setAlertMessage('Please use the correct headers.');
                setShowAlert(true);
              } else if (
                parsedTemplateConverted.some((row) =>
                  ['name', 'description', 'url'].some((columnName) => /^[1-9][0-9]*$/.test(row[columnName])),
                )
              ) {
                setAlertMessage('Please use correct values for each column. Your files column values should be text.');
                setShowAlert(true);
              } else if (
                parsedTemplateConverted.some((row) =>
                  ['postal_code'].some((columnName) => !/^[0-9]+$/.test(row[columnName])),
                )
              ) {
                setAlertMessage('Please use correct values for each column. Your postal code should be a number.');
                setShowAlert(true);
              } else if (
                parsedTemplateConverted.some((row) => ['raw_goal'].some((columnName) => !/^\d+$/.test(row[columnName])))
              ) {
                setAlertMessage(
                  'Please use correct values for each column. Your files column values should be numbers.',
                );
                setShowAlert(true);
              } else if (missingColumnValues.length > 0) {
                setAlertMessage(
                  'Please add values to all required columns. The following columns must have a value:  description, name, raw_currency_code, raw_goal, city, state, country, thank_you_text, designation_id',
                );
                setShowAlert(true);
              } else {
                teamsTableDataStorage = [...teamsTableData, ...parsedTemplateConverted];
                parsedTeamFile = parsedTemplateConverted;
                setTeamsTableData(teamsTableDataStorage);
                setTeamsFile(files[0]);
                setHasTeamsFile(true);
              }
            },
          });
        }
      } catch (exception) {}
    },
    [setHasTeamsFile, setTeamsFile, teamsTableData],
  );

  return (
    <div>
      <ClassyAlert show={showAlert} alertMessage={alertMessage} onHide={onHide} />

      <p>
        <strong>Teams for the campaign</strong>
      </p>

      <ClassyTable
        showWhenEmpty
        columns={teamsTableColumns}
        data={teamsTableData || []}
        defaultPageSize={constants.PAGINATION.SIZE_DEFAULT}
        pageSizeOptions={constants.PAGINATION.SIZE_OPTIONS}
        resizable={false}
      />

      <Form noValidate validated="false">
        <FormGroup className="user-file">
          <ControlLabel>Team file (CSV)</ControlLabel>
          <input name="teamFile" type="file" required accept=".csv" onChange={handleTeamFileChange} />
          <FormControl.Feedback />
          <HelpBlock>
            A CSV is required for the Team File.
            <a href="/registration-import/team-file-template.csv" className="template-file" download>
              Link to template Team File.
            </a>
          </HelpBlock>
        </FormGroup>
      </Form>
    </div>
  );
};

const AttendeeImportStep = (attendeeProps) => {
  const { setAttendeeFile, selectedCampaign, servicesOrganizationId, organizationId } = attendeeProps;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [attendeeTableColumns] = useState(attendeeColumns);
  const onHide = () => {
    setAlertMessage('');
    setShowAlert(false);
  };

  const [attendeeTableData, setAttendeeTableData] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (attendeeTableDataStorage.length > 0) {
        setAttendeeTableData(attendeeTableDataStorage);
      } else {
        try {
          const fetchedAttendees = await api.getCampaignFundraisingAttendees({
            campaignId: selectedCampaign.id,
            servicesOrganizationId: servicesOrganizationId,
          });

          const fetchedQuestions = await api.getCampaignQuestions({
            campaignId: selectedCampaign.id,
            organizationId,
            servicesOrganizationId,
          });

          const attendeeData = fetchedAttendees.data.data.map((el) => ({
            attendee_id: el.attendee_id,
            campaign_id: el.campaign_id,
            updated_at: el.updated_at,
            member_id: el.member_id,
            fundraising_page_id: el.fundraising_page_id,
            transaction_id: el.transaction_id,
            transaction_item_id: el.transaction_item_id,
            transaction_payment_type: el.transaction_payment_type,
            transaction_last_four: el.transaction_last_four,
            product_name: el.product_name,
            first_name: el.first_name,
            last_name: el.last_name,
            email: el.email,
            status: el.status,
            cell: el.cell,
            city: el.city,
            country: el.country,
            blog: el.blog,
            address1: el.address1,
            address2: el.address2,
            gender: el.gender,
            company: el.company,
            tshirt_size: el.tshirt_size,
          }));
          const questionData = fetchedQuestions.data.map((el) => ({
            label: el.label,
            ticket_id: el.product_id,
          }));

          const fetchedAnswers = await Promise.all(
            attendeeData.map((el) => {
              return api.getQuestionAnswers({
                servicesOrganizationId,
                organizationId,
                registrationId: el.attendee_id,
              });
            }),
          );

          const questionAnswers = fetchedAnswers.map((el) => ({
            answer: el.answer,
          }));

          const consolidatedData = attendeeData.map((attendee, index) => ({
            ...attendee,
            ...questionData[index],
            ...questionAnswers,
          }));

          attendeeTableDataStorage = consolidatedData;

          setAttendeeTableData(attendeeTableDataStorage);
        } catch {}
      }
    };

    if (servicesOrganizationId && selectedCampaign) {
      fetchAllData();
    }
  }, [servicesOrganizationId, selectedCampaign, organizationId]);

  const handleAttendeeFileChange = useCallback(
    (e) => {
      const { files } = e.target;

      setAlertMessage('');
      setShowAlert(false);

      if (!files || !files[0]) {
        return;
      }
      const uploadedFile = files[0];
      setAttendeeFile(files[0]);
      try {
        if (uploadedFile) {
          papaParse.parse(uploadedFile, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              const expectedHeader = [
                'fundraising_page_id',
                'transaction_payment_type',
                'first_name',
                'last_name',
                'billing_first_name',
                'billing_last_name',
                'billing_email',
                'email',
                'label',
                'answer',
                'label2',
                'answer2',
                'label3',
                'answer3',
                'label4',
                'answer4',
                'label5',
                'answer5',
                'donation_share',
                'ticket_type',
              ];
              const fileHeader = results.meta.fields;
              const parsedTemplate: Array<any> = results.data;

              const requiredColumns = ['donation_share', 'first_name', 'last_name', 'answer', 'email', 'ticket_type'];
              const missingColumnValues = requiredColumns.filter((el) => !fileHeader?.includes(el));

              if (parsedTemplate.length < 1) {
                setAlertMessage('Uploaded file is empty. Please add at least one row, and try uploading again.');
                setShowAlert(true);
              } else if (!matchVals(expectedHeader, fileHeader)) {
                setAlertMessage('Please use the correct headers.');
                setShowAlert(true);
              } else if (
                parsedTemplate.some((row) =>
                  ['first_name', 'last_name', 'answer', 'email'].some((columnName) =>
                    //regex is checking to see if columns contain a number
                    /^[1-9][0-9]*$/.test(row[columnName]),
                  ),
                )
              ) {
                setAlertMessage('Please use correct values for each column. Your files column values should be text.');
                setShowAlert(true);
              } else if (
                parsedTemplate.some((row) =>
                  ['fundraising_page_id', 'donation_share'].some(
                    (columnName) => row[columnName] && !/^\d+$/.test(row[columnName]),
                  ),
                )
              ) {
                setAlertMessage(
                  "Please use correct values for each column. Your files 'fundraising_page_id' and 'donation_share' values should be numbers.",
                );
                setShowAlert(true);
              } else if (missingColumnValues.length > 0) {
                setAlertMessage(
                  'Please add values to all required columns. The following columns must have a value: donation_share, first_name, last_name, answer, email.',
                );
                setShowAlert(true);
              } else {
                attendeeTableDataStorage = [...attendeeTableData, ...parsedTemplate];
                parsedAttendeeFile = parsedTemplate;
                setAttendeeTableData(attendeeTableDataStorage);
              }
            },
          });
        }
      } catch (exception) {}
    },
    [setAttendeeFile, attendeeTableData],
  );

  return (
    <div>
      <ClassyAlert show={showAlert} alertMessage={alertMessage} onHide={onHide} />
      {
        <>
          <p>
            <strong>Attendees for the campaign</strong>
          </p>
          <ClassyTable
            showWhenEmpty
            columns={attendeeTableColumns}
            data={attendeeTableData || []}
            defaultPageSize={constants.PAGINATION.SIZE_DEFAULT}
            pageSizeOptions={constants.PAGINATION.SIZE_OPTIONS}
            resizable={false}
          />
        </>
      }
      <hr />
      <Form noValidate validated="false">
        <FormGroup className="user-file">
          <ControlLabel>Attendee file (CSV)</ControlLabel>
          <input name="attendeeFile" type="file" required accept=".csv" onChange={handleAttendeeFileChange} />
          <FormControl.Feedback />
          <HelpBlock>
            A CSV is required for the Attendee File.
            {
              <a href="/registration-import/attendee-file-template.csv" className="template-file" download>
                Link to template Attendee File.
              </a>
            }
          </HelpBlock>
        </FormGroup>
      </Form>
    </div>
  );
};

const LoadingStep = ({ setStepIndex }) => {
  useEffect(() => {
    setTimeout(() => {
      setStepIndex((index) => index + 1);
    }, 1000);
  });

  return <Throbber loading={true} />;
};

const CompletedStep = () => (
  <div className="completed_wrapper">
    <p className="text">Import completed successfully!</p>
  </div>
);

const STEPS = [OrganizationStep, TeamImportStep, LoadingStep, AttendeeImportStep, LoadingStep, CompletedStep];

const STEP_CONTENT = [
  {
    title: 'Select your Organization and Campaign',
    subtitle: 'Only Ticketed and Registration with Fundraiser campaigns will be shown',
  },
  {
    title: 'Import Teams into the campaign',
  },
  {
    title: 'Processing Team Import...',
  },
  {
    title: 'Import Attendees into the Campaign',
    subtitle: 'Make sure to include the required custom questions/team information if needed',
  },
  {
    title: 'Processing Attendee Import...',
  },
  {
    title: 'All done!',
  },
];

const stepMap = {
  OrganizationStep: 0,
  TeamImportStep: 1,
  TeamImportLoadingStep: 2,
  AttendeeImportStep: 3,
  AttendeeImportLoadingStep: 4,
  CompletedStep: 5,
};
const RegistrationImport = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [servicesOrganizationId, setServicesOrganizationId] = useState<string | undefined>(undefined);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [teamsFile, setTeamsFile] = useState([]);
  const [hasTeamsFile, setHasTeamsFile] = useState(false);
  const [attendeeFile, setAttendeeFile] = useState();
  const [teams] = useState([]);
  const [teamsTableData, setTeamsTableData] = useState([]);
  const [attendeeTableData, setAttendeeTableData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onHide = () => {
    setAlertMessage('');
    setShowAlert(false);
  };

  useEffect(() => {
    const fetchAllCampaigns = async () => {
      try {
        const fetchedCampaigns = await Promise.all([
          api.getCampaigns(servicesOrganizationId, false, {
            params: { filter: 'status=active,type=reg_w_fund' },
          }),
          api.getCampaigns(servicesOrganizationId, false, {
            params: { filter: 'status=active,type=ticketed' },
          }),
        ]);

        const campaignData = fetchedCampaigns.reduce((accumulator, val) => {
          return accumulator.concat(val.data);
        }, []);
        setCampaigns(campaignData);
      } catch (err) {}
    };

    if (!servicesOrganizationId && organizationId) {
      setAlertMessage('Invalid organization ID.');
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }

    if (servicesOrganizationId) {
      fetchAllCampaigns();
    }
  }, [organizationId, servicesOrganizationId]);

  const Step = STEPS[stepIndex];

  const handleNext = useCallback(() => {
    switch (stepIndex) {
      default:
        setStepIndex((current) => (current === stepMap.TeamImportLoadingStep ? current : current + 1));
        break;
    }
  }, [stepIndex]);

  const handlePrevious = useCallback(() => {
    switch (stepIndex) {
      case stepMap.AttendeeImportStep:
        setStepIndex(stepMap.TeamImportStep);
        break;
      case stepMap.OrganizationStep:
        history.push({
          pathname: '/registration-import',
        });
        break;
      default:
        setStepIndex((current) => current - 1);
        break;
    }
  }, [stepIndex]);

  const handleSubmit = useCallback(() => {
    const createCampaignTeams = async () => {
      const createTeamsRequests = [];
      const teamsRequest = {
        servicesOrganizationId,
        organizationId,
        campaignId: selectedCampaign.id,
      };
      parsedTeamFile.forEach((el) => {
        Object.keys(el).forEach((property) => {
          teamsRequest[property] = el[property];
        });
        createTeamsRequests.push(teamsRequest);
      });

      await api.postCampaignFundraisingTeams({ data: createTeamsRequests, ...teamsRequest });
    };

    const createCampaignAttendees = async () => {
      const createAttendeeRequests = [];
      const attendeeRequest = {
        servicesOrganizationId,
        organizationId,
        campaignId: selectedCampaign.id,
      };
      parsedAttendeeFile.forEach((el) => {
        Object.keys(el).forEach((property) => {
          attendeeRequest[property] = el[property];
        });

        createAttendeeRequests.push(attendeeRequest);
      });

      await api.postCampaignAttendees({ data: createAttendeeRequests, ...attendeeRequest });
    };

    if (servicesOrganizationId && selectedCampaign.id) {
      createCampaignTeams();
      createCampaignAttendees();
    }

    switch (stepIndex) {
      case stepMap.AttendeeImportStep:
        setStepIndex((current) => current + 1);
        break;
    }
  }, [selectedCampaign, servicesOrganizationId, stepIndex, organizationId]);

  const progress = (1 - stepIndex / (STEPS.length - 1)) * 100;

  return (
    <>
      <h2 className="title-text">Registration Import</h2>
      <div className="progress_bar_container">
        {!!STEP_CONTENT[stepIndex]?.title && <p className="title">{STEP_CONTENT[stepIndex]?.title}</p>}
        <div className="progress_bar">
          <span style={{ right: `${progress}%` }} />
        </div>
        {!!STEP_CONTENT[stepIndex]?.subtitle && <p className="subtitle">{STEP_CONTENT[stepIndex]?.subtitle}</p>}
      </div>
      <ClassyAlert show={showAlert} alertMessage={alertMessage} onHide={onHide} />
      <div className="form-wrapper">
        <Step
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          setOrganizationId={setOrganizationId}
          servicesOrganizationId={servicesOrganizationId}
          setServicesOrganizationId={setServicesOrganizationId}
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          teamsFile={teamsFile}
          setTeamsFile={setTeamsFile}
          setHasTeamsFile={setHasTeamsFile}
          attendeeFile={attendeeFile}
          setAttendeeFile={setAttendeeFile}
          organizationId={organizationId}
          teams={teams}
          attendeeTableData={attendeeTableData}
          setAttendeeTableData={setAttendeeTableData}
          teamsTableData={teamsTableData}
          setTeamsTableData={setTeamsTableData}
        />
      </div>
      {![stepMap.TeamImportLoadingStep, stepMap.AttendeeImportLoadingStep, stepMap.CompletedStep].includes(
        stepIndex,
      ) && (
        <div className="flexRow">
          {stepIndex === 1 && (
            <>
              <ClassyButton disabled={hasTeamsFile} title="Skip" onClick={() => setStepIndex(3)} />
            </>
          )}
          <ClassyButton title="Previous" onClick={handlePrevious} />
          {stepIndex < 3 && (
            <ClassyButton
              disabled={(stepIndex === 1 && teamsFile.length === 0) || !servicesOrganizationId || !organizationId}
              title="Next"
              onClick={handleNext}
            />
          )}
          {stepIndex === 3 && <ClassyButton disabled={false} title="Submit" onClick={handleSubmit} />}
        </div>
      )}
    </>
  );
};

const imports = { RegistrationImport, AttendeeImportStep, TeamImportStep };
export default imports;
