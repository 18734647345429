import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import papaParse from 'papaparse';
import { get } from 'lodash';
import { saveAs } from 'file-saver';

import AppActions from '../../../Redux/App.redux';
import history from '../../../Router/history';
import FileUploader from '../../FileUploader/FileUploader';
import ClassyTable from '../../ClassyTable/ClassyTable';
import Throbber from '../../Throbber/Throbber';
import Card from '../../Card/Card';
import constants from '../../../Helpers/constants';

import ClassyButton from '../../ClassyButton/ClassyButton';
import './style.scss';
import HelpComponent from '../HelpComponent';
import ErrorPopup from '../ErrorPopup';

export class Stories extends Component {
  componentWillUnmount() {
    const { clearStoryResponse } = this.props;
    clearStoryResponse();
  }

  filterStoriesData = (data) => {
    let filteredData = [];
    if (data && Array.isArray(data)) {
      filteredData = data.map((story, index) => ({
        index: index + 1,
        fundraisingPageId: story.fundraisingPageId,
        title: story.title,
        body: story.body,
        status: story.isSuccessful
          ? 'Success'
          : (Array.isArray(story.result) && story.result.join(', ')) || story.result,
      }));
    }
    return filteredData;
  };

  renderThrobber = () => {
    const { loading } = this.props;

    return <Throbber loading={loading} />;
  };

  renderTransactionReport = () => {
    const { storyResponse, selectedOrganization } = this.props;
    let report;

    if (storyResponse) {
      const { data } = storyResponse;
      const storyReportColumns = [
        {
          Header: 'Row #',
          Cell: (row) => <div id="rowIndex">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
          width: 75,
        },
        {
          Header: 'Fundraising Page Id',
          accessor: 'fundraisingPageId',
        },
        {
          Header: 'Title',
          accessor: 'title',
        },
        {
          Header: 'Body',
          accessor: 'body',
        },
        {
          Header: 'Status',
          accessor: 'isSuccessful',
          Cell: (row) => {
            return row.value ? (
              <i className="fa fa-check status-icon--success" />
            ) : get(row, 'original.isValid') && !get(row, 'row._original.result') ? (
              <i className="fa fa-exclamation-circle status-icon--success" title="Valid Record" />
            ) : (
              <div id="errorMessage">
                <ErrorPopup
                  error={
                    Array.isArray(get(row, 'row._original.result'))
                      ? get(row, 'row._original.result').join(', ')
                      : get(row, 'row._original.result')
                  }
                />
              </div>
            );
          },
        },
      ];

      if (data && Array.isArray(data)) {
        const failedUploads = data.filter((el) => !el.isSuccessful);
        const successfulUploads = data.filter((el) => el.isSuccessful);
        const validUploads = data.filter((el) => el.isValid);
        const inValidUploads = data.filter((el) => !el.isValid);

        const exportOrganizationName = constants.getChunkedOrganizationName(selectedOrganization.name);

        report = (
          <div>
            {data ? (
              <div>
                {inValidUploads.length ? (
                  <Card
                    messageTop={validUploads.length ? `${validUploads.length} record(s) valid` : null}
                    messageBottom={inValidUploads.length ? `${inValidUploads.length} record(s) invalid` : null}
                  />
                ) : (
                  <Card
                    messageTop={successfulUploads.length ? `${successfulUploads.length} record(s) processed` : null}
                    messageBottom={failedUploads.length ? `${failedUploads.length} record(s) failed` : null}
                  />
                )}
                <Prompt
                  when={!!data.length}
                  message="Are you sure you want to leave? Your results will not be retained."
                />
              </div>
            ) : null}
            {this.renderThrobber()}

            <ClassyTable
              data={data || []}
              exportFilename={`${exportOrganizationName}_Fundraising_Stories`}
              formattedExportData={this.filterStoriesData(data)}
              columns={storyReportColumns}
            />
          </div>
        );
      }
    }
    return report;
  };

  onDownloadTemplate = () => {
    const csv = papaParse.unparse([
      {
        fundraisingPageId: '',
        title: '',
        body: '',
      },
    ]);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'story.csv';
    saveAs(csvBlob, csvName);
  };

  render() {
    const { selectedOrganization, onStoryResponse } = this.props;

    return (
      <div id="stories">
        <HelpComponent title="Story" content="stories" fields="fundraisingPageId, body" />

        <div className="flexRow space-between">
          <h2 className="title-text">Stories</h2>
          <ClassyButton
            className="secondary-button fundraising-storys__table-header-button"
            title={'Download Template'}
            onClick={this.onDownloadTemplate}
          />
        </div>

        <FileUploader
          route={`fundraising/${selectedOrganization.id}/${process.env.REACT_APP_CLASSY_SERVICES_API_FUNDRAISING_STORIES}`}
          onParentComplete={onStoryResponse}
        />

        <ClassyButton title="Back" onClick={() => history.push('/fundraising/pages')} />

        {this.renderTransactionReport()}
      </div>
    );
  }
}

Stories.propTypes = {
  clearStoryResponse: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  storyResponse: PropTypes.object.isRequired,
  onStoryResponse: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loading, storyResponse } = state.app;
  const { selectedOrganization } = state.login;

  return {
    loading,
    storyResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { clearStoryResponse, onStoryResponse } = AppActions;

  return {
    clearStoryResponse: () => dispatch(clearStoryResponse()),
    onStoryResponse: (response) => dispatch(onStoryResponse(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stories);
