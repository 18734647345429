import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ClassyButton from '../../ClassyButton/ClassyButton';
import './styles.scss';

class ErrorPopup extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  renderModal() {
    const { error } = this.props;
    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <div style={{ wordBreak: 'break-word' }}>
            {error && (
              <div>
                <h1>Error:</h1>
                <hr />
                <p>{error}</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ClassyButton title={'Close'} onClick={this.handleClose} />
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div onClick={this.handleShow} className="help-container__link">
          <i className="fa fa-times status-icon--error" />
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

ErrorPopup.propTypes = {
  error: PropTypes.any,
};

export default ErrorPopup;
