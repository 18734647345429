import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Gallery from 'react-fine-uploader';
import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import { get } from 'lodash';

import history from '../../Router/history';
import AppActions from '../../Redux/App.redux';
import LoginActions from '../../Redux/Login.redux';
import api from '../../Services/Api';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import './FileUploader.scss';
import 'react-fine-uploader/gallery/gallery.css';
import constants from '../../Helpers/constants';

const { ONE_FILE_ERROR, UNAUTHORIZED_ERROR, SERVER_ERROR, FILE_PROCESSING_MSG, INVALID_TYPE_ERROR } = constants;

export class FileUploader extends Component {
  constructor(props) {
    super(props);
    const { clearLoading, logout, onParentComplete, route, setLoading } = this.props;
    this.state = {
      alertMessage: '',
      showAlert: false,
      uploadPending: false,
    };

    this.uploader = new FineUploaderTraditional({
      options: {
        chunking: {
          enabled: false,
        },
        request: {
          endpoint: `${process.env.REACT_APP_CLASSY_SERVICES_API_URL}${route}`,
          inputName: 'importedDataFile',
          requireSuccessJson: false,
          customHeaders: {
            [api.xsrfTokenHeader]: api.api?.defaults?.headers?.common?.[api.xsrfTokenHeader],
          },
        },
        cors: {
          expected: true,
          sendCredentials: true,
        },
        retry: {
          enableAuto: false,
        },
        callbacks: {
          onComplete: (id, xhr, responseJSON) => {
            if (responseJSON && responseJSON.data === UNAUTHORIZED_ERROR) {
              logout();
            } else {
              if (responseJSON.status && responseJSON.status === 'error') {
                this.setAlert(responseJSON.message);
              }
              onParentComplete({
                ...responseJSON,
                data: get(responseJSON, 'data.results', responseJSON.data),
              });
              this.fileInfo = {
                ...responseJSON,
                data: get(responseJSON, 'data.results', responseJSON.data),
              };
              this.setState({
                file: {
                  ...responseJSON,
                  data: get(responseJSON, 'data.results', responseJSON.data),
                },
              });
              this.uploader.methods.setStatus(id, 'deleted');
            }
          },
          onCancel: () => {
            clearLoading();
            this.setState({ uploadPending: false });
          },
          onError: (id, name, message) => {
            if (message === ONE_FILE_ERROR) {
              this.setAlert(message);
            } else if (message === SERVER_ERROR) {
              this.setAlert(FILE_PROCESSING_MSG);
            } else if (message === INVALID_TYPE_ERROR) {
              this.setAlert(INVALID_TYPE_ERROR);
            }
          },
          onUpload: () => {
            setLoading();
            this.setState({ uploadPending: false });
          },
          onSubmitted: () => {
            this.setState({ uploadPending: true });
          },
          onValidate: () => {
            let isValid = true;
            if (this.state.uploadPending) {
              isValid = false;
              this.setAlert(ONE_FILE_ERROR);
            }
            return isValid;
          },
        },
        autoUpload: false,
        multiple: false,
        validation: {
          itemLimit: 1,
          allowedExtensions: ['csv'],
          acceptFiles: 'csv',
        },
        messages: {
          tooManyItemsError: ONE_FILE_ERROR,
          typeError: INVALID_TYPE_ERROR,
        },
      },
    });

    this.uploadFile = this.uploadFile.bind(this);
    this.setEndpoint = this.setEndpoint.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  componentDidMount() {
    const { clearLoading } = this.props;

    clearLoading();
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  setEndpoint() {
    const { route } = this.props;
    this.uploader.methods.setEndpoint(`${process.env.REACT_APP_CLASSY_SERVICES_API_URL}${route}`);
  }

  uploadFile() {
    this.uploader.methods.uploadStoredFiles();
  }

  render() {
    const { uploadPending } = this.state;
    this.setEndpoint();

    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <div id="gallery">
          <Gallery uploader={this.uploader} fileInput-children={<div />}>
            <FileInput uploader={this.uploader}>
              <div className="file-uploader__browse-link-container">
                <i className="fa fa-folder-open" />
                <span className="file-uploader__browse-link-text">Browse Files</span>
              </div>
            </FileInput>
            <Dropzone uploader={this.uploader}>
              <i className="fa fa-cloud-upload file-uploader__dropzone-icon" />
              <span className="file-uploader__dropzone-text">Drag Files Here</span>
            </Dropzone>
          </Gallery>
        </div>
        <div className="file-uploader__upload-button-container">
          <ClassyButton title="Upload File" onClick={this.uploadFile} disabled={!uploadPending} />
        </div>
      </div>
    );
  }
}

FileUploader.propTypes = {
  clearLoading: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  onParentComplete: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  const { clearLoading, setLoading } = AppActions;
  const { logout } = LoginActions;

  return {
    clearLoading: () => dispatch(clearLoading()),
    logout: () => {
      dispatch(logout());
      history.push('/');
    },
    setLoading: () => dispatch(setLoading()),
  };
};

export default connect(null, mapDispatchToProps)(FileUploader);
